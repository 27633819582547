import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { success } from 'redux/ducks/Flash';
import { reset, update } from 'redux/ducks/User';
import { Validator } from 'redux/ducks/helpers';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import validator from 'validator';
import zipcodes from 'zipcodes';
import { optionsForEnum } from 'helpers/enums';
import { EXPECTED_HOURS_OPTIONS } from 'redux/ducks/Registration';
import { get } from 'lodash';
import { ORGANIZATION_TYPES } from 'helpers/enums';

export class EditProfileScene extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        ...props.user,
        currentPassword: '',
        password: '',
        passwordConfirmation: '',
      },
      errors: {},
    };

    this.validator = new Validator({
      firstName: { required: true },
      lastName: { required: true },
      companyName: { required: true },
      addressZip: { required: true, length: 5, ofType: 'number' },
      email: { required: true },
      currentPassword: { requiredAlongside: 'password' },
      password: { minLength: 8 },
      passwordConfirmation: { matches: 'password' },
    });

    this.onFileChange = this.onFileChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.updated) {
      this.props.success('Your Profile Has Been Successfully Updated');
      this.props.history.push('/dashboard');
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentDidMount() {
    const from = get(this.props, 'location.state.from', '');
    if (!from || from !== 'finances') return;

    setTimeout(() => {
      const el = document.getElementById('finances-email');
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, 500);
  }

  onFileChange(event) {
    const file = event.currentTarget.files[0];

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.setState({
        user: {
          ...this.state.user,
          profilePic: reader.result,
        },
      });
    });

    reader.readAsDataURL(file);
  }

  updateField = (field, value) => {
    this.setState((previousState) => ({
      ...previousState,
      user: {
        ...previousState.user,
        [field]: value,
      },
    }));
  };

  onFieldChange = (field, transformer) => (event) => {
    this.updateField(
      field,
      transformer ? transformer(event.currentTarget.value) : event.currentTarget.value
    );
  };

  onPhoneNumberChange(newNumber) {
    let user = { ...this.state.user };
    const phoneNo = newNumber.length === 1 && newNumber.at(0) !== '1' ? `1${newNumber}` : newNumber;
    user['phone'] = phoneNo;

    this.setState({ user: user });
  }

  onSubmit(e) {
    e.preventDefault();

    const errors = this.validator.validateAll(this.state.user);
    if (!validator.isMobilePhone(this.state.user['phone'])) {
      errors['phone'] = 'Phone number is not valid';
    }

    if (Object.keys(errors).length === 0) {
      this.props.update(this.state.user);
    } else {
      this.setState({ errors: errors });
    }
  }

  onZipCodeChange = (ev: SyntheticInputEvent<HTMLInputElement>) => {
    const location = zipcodes.lookup(ev.currentTarget.value);
    this.onFieldChange('addressZip')(ev);
    if (location?.city) {
      this.updateField('city', location.city);
    }
    if (location?.state) {
      this.updateField('state', location.state);
    }
  };

  render() {
    const { jobsListed, cancellationRate, responseRate } = this.state.user;
    return (
      <div className="container">
        <div className="editProfileScene col-12">
          <h2>edit profile</h2>
          <div className="row">
            <div className="col-md-3">
              <div
                style={{
                  backgroundImage: `url(${
                    this.state.user.profilePic || require('styles/GenericAdd.svg')
                  })`,
                }}
                className="profile-pic"
                alt="Profile"
              />
              <section>
                <input
                  onChange={this.onFileChange}
                  type="file"
                  name="profilePic"
                  className="custom-file-input"
                  accept="image/*"
                />
                <label className="custom-file-label" htmlFor="profilePic">
                  EDIT PHOTO
                </label>
              </section>
            </div>
            <div className="col-md-9">
              <table className="profile-stats">
                <tr>
                  <th>
                    Jobs Listed
                    <p>Total jobs listed on Go4.</p>
                  </th>
                  <td>{Number(jobsListed) || 0}</td>
                </tr>
                <tr>
                  <th>
                    Response Rate
                    <p>This rate measures how often your organization responds to applications.</p>
                  </th>
                  <td>{typeof responseRate === 'number' ? `${responseRate.toFixed(2)}%` : '-'}</td>
                </tr>
                <tr>
                  <th>
                    Cancellation Rate
                    <p>This rate measures how often a confirmed job is cancelled.</p>
                  </th>
                  <td>{typeof cancellationRate === 'number' ? `${cancellationRate.toFixed(2)}%` : '-'}</td>
                </tr>
              </table>
            </div>
          </div>
          <form id="profileForm" onSubmit={this.onSubmit}>
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                className={classnames('form-control', {
                  'is-invalid': this.state.errors.firstName || this.props.errors.first_name,
                })}
                name="firstName"
                defaultValue={this.state.user.firstName}
                onChange={this.onFieldChange('firstName')}
              />
              <div className="invalid-feedback">
                {this.state.errors.firstName}
                {this.props.errors.first_name}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                className={classnames('form-control', {
                  'is-invalid': this.state.errors.lastName || this.props.errors.last_name,
                })}
                name="lastName"
                defaultValue={this.state.user.lastName}
                onChange={this.onFieldChange('lastName')}
              />
              <div className="invalid-feedback">
                {this.state.errors.lastName}
                {this.props.errors.last_name}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="companyName">Organization Name</label>
              <input
                type="text"
                className={classnames('form-control', {
                  'is-invalid': this.state.errors.companyName || this.props.errors.company_name,
                })}
                name="companyName"
                defaultValue={this.state.user.companyName}
                onChange={this.onFieldChange('companyName')}
              />
              <div className="invalid-feedback">
                {this.state.errors.companyName}
                {this.props.errors.company_name}
              </div>
            </div>
            <div className="row">
              <div className="col form-group">
                <label htmlFor="phone">Phone Number</label>
                <PhoneInput
                  name="phone"
                  onlyCountries={['us']}
                  preferredCountries={['us']}
                  enableAreaCodes={false}
                  autoFormat={true}
                  inputStyle={{ width: '100%' }}
                  placeholder="Enter phone number"
                  value={this.state.user.phone || ''}
                  country="us"
                  onChange={this.onPhoneNumberChange.bind(this)}
                />
                <div className="invalid-feedback-displayed">
                  {this.state.errors.phone}
                  {this.props.errors.phone}
                </div>
              </div>
              <div className="col-md-6 form-group">
                <label htmlFor="addressZip">Zip Code</label>
                <input
                  type="text"
                  className={classnames('form-control', {
                    'is-invalid': this.state.errors.addressZip || this.props.errors.address_zip,
                  })}
                  name="addressZip"
                  defaultValue={this.state.user.addressZip}
                  onChange={this.onZipCodeChange}
                />
                <div className="invalid-feedback">
                  {this.state.errors.addressZip}
                  {this.props.errors.address_zip}
                </div>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="streetAddress">Street Address</label>
              <input
                type="text"
                className={classnames('form-control')}
                name="streetAddress"
                defaultValue={this.state.user.street}
                onChange={this.onFieldChange('street')}
              />
            </div>

            <div className="form-group">
              <label className="required" htmlFor="city">
                City
              </label>
              <input
                type="text"
                name={'city'}
                placeholder="Los Angeles"
                value={this.state.user.city}
                className={classnames('form-control')}
                onChange={this.onFieldChange('city')}
              />
            </div>

            <div className="form-group">
              <label className="required" htmlFor="state">
                State
              </label>
              <select
                name="state"
                className={classnames('form-control', 'custom-select')}
                value={this.state.user.state}
                onChange={this.onFieldChange('state')}
              >
                {this.props.states.map((state, index) => {
                  return (
                    <option key={index} value={state.abbreviation}>
                      {state.abbreviation}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="organization">Organization Type</label>
              <select
                className="form-control custom-select"
                name="organization"
                value={this.state.user.defaultEventSettingId}
                onChange={this.onFieldChange('defaultEventSettingId', parseInt)}
              >
                {optionsForEnum(ORGANIZATION_TYPES)}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="expected_yearly_staffed_hours">
                How many hours do you staff healthcare providers in a year?
              </label>
              <select
                name="expected_yearly_staffed_hours"
                className="form-control custom-select"
                value={this.state.user.expectedYearlyStaffedHours || ''}
                onChange={this.onFieldChange('expectedYearlyStaffedHours')}
              >
                <option disabled value=""></option>
                {EXPECTED_HOURS_OPTIONS.map((option, index) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <div className="divider" />

            <div className="form-group" id="finances-email">
              <label htmlFor="financeEmail">
                Finance Email
                <div className="financeEmailInfo">
                  Optional - Add comma-separated emails for additional people to receive Finance
                  Reports for this account.
                </div>
              </label>
              <input
                type="text"
                className={classnames('form-control', {
                  'is-invalid': this.state.errors.financeEmail || this.props.errors.finance_email,
                })}
                name="financeEmail"
                defaultValue={this.state.user.financeEmail}
                onChange={this.onFieldChange('financeEmail')}
              />
              <div className="invalid-feedback">
                {this.state.errors.financeEmail}
                {this.props.errors.finance_email}
              </div>
            </div>

            <div className="divider"></div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className={classnames('form-control', {
                  'is-invalid': this.state.errors.email || this.props.errors.email,
                })}
                name="email"
                onChange={this.onFieldChange('email')}
                defaultValue={this.state.user.email}
              />
              <div className="invalid-feedback">
                {this.state.errors.email}
                {this.props.errors.email}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="currentPassword">Password</label>
              <input
                type="password"
                className={classnames('form-control', {
                  'is-invalid':
                    this.state.errors.currentPassword || this.props.errors.current_password,
                })}
                placeholder="If you are updating your password, type old password here"
                name="currentPassword"
                defaultValue={this.state.user.currentPassword}
                onChange={this.onFieldChange('currentPassword')}
                data-lpignore="true"
                autoComplete="new-password"
              />
              <div className="invalid-feedback">
                {this.state.errors.currentPassword && (
                  <span>Required when updating to a new password</span>
                )}
                {this.props.errors.current_password && <span>Must match current password</span>}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="password">New Password</label>
              <input
                type="password"
                className={classnames('form-control', {
                  'is-invalid': this.state.errors.password || this.props.errors.password,
                })}
                placeholder="(must be at least 8 characters)"
                name="password"
                defaultValue={this.state.user.password}
                onChange={this.onFieldChange('password')}
                data-lpignore="true"
              />
              <div className="invalid-feedback">
                {this.state.errors.password}
                {this.props.errors.password}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="passwordConfirmation">Confirm Password</label>
              <input
                type="password"
                className={classnames('form-control', {
                  'is-invalid':
                    this.state.errors.passwordConfirmation ||
                    this.props.errors.password_confirmation,
                })}
                name="passwordConfirmation"
                defaultValue={this.state.user.passwordConfirmation}
                onChange={this.onFieldChange('passwordConfirmation')}
                data-lpignore="true"
              />
              <div className="invalid-feedback">
                {this.state.errors.passwordConfirmation}
                {this.props.errors.password_confirmation}
              </div>
            </div>
            <div className="controls">
              <Link className="btn btn-link link-button secondary" to="/dashboard">
                Cancel
              </Link>
              <button type="submit" className="btn btn-danger bold-button">
                Update Profile
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errors: state.user.errors,
    updated: state.user.updated,
    user: state.session.currentUser,
    states: state.enums.usStates,
    eventSettings: state.enums.eventSettings,
    professions: state.enums.professions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reset: () => dispatch(reset()),
    success: (message) => dispatch(success(message)),
    update: (user) => dispatch(update(user)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileScene);
