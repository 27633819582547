import React, { Component } from "react";
import { connect } from "react-redux";
import { fieldChange } from "redux/ducks/CreateEvent/Details";
import {
  initForm,
  clearForm,
  autoUpdateEvent,
  updateEvent,
  validateForm,
  cancelEvent,
  publishDraft,
  duplicateEvent,
} from "redux/ducks/CreateEvent";
import EventDetailsForm from "scenes/CreateEventScene/CreateEventStep1/EventDetailsForm";
import ShiftPanelList from "scenes/CreateEventScene/CreateEventStep2/ShiftPanelList";
import ButtonRow from "scenes/CreateEventScene/ButtonRow";
import CancelEventModal from "./CancelEventModal";
import EventBillingSummary from "scenes/CreateEventScene/CreateEventStep4/EventBillingSummary";
import EditEAPScene from "./EditEAPScene";
import { Route } from "react-router-dom";
import { isEqual } from "lodash";
import moment from "moment";

class EditEventWrapper extends Component {
  render() {
    return (
      <div>
        <Route
          path="/events/:eventId/shifts/:shiftId/emergency-action-plan/edit"
          component={EditEAPScene}
        />
        <Route
          path="/events/:eventId/edit"
          render={() => <EditEventScene {...this.props} />}
        />
      </div>
    );
  }
}

class EditEventScene extends Component {
  state = {
    showCancelEventModal: false,
    isAccepted: false,
    canSubmit: true,
  };

  toggleIsAccepted = () =>
    this.setState({ isAccepted: !this.state.isAccepted });
  toggleCancelEventModal = () =>
    this.setState({ showCancelEventModal: !this.state.showCancelEventModal });

  componentDidMount() {
    const { alreadyEditing, initialize, event } = this.props;
    this.setState({ canSubmit: this.canSubmit() });

    if (!!event && !alreadyEditing) {
      initialize(event);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const isAcceptedChanged = prevState.isAccepted !== this.state.isAccepted;

    if (this.isEditingDraft() && isAcceptedChanged) {
      this.setState({ canSubmit: this.canSubmit() }); return;
    }

    const { form } = this.props;
    const formChanged = !isEqual(prevProps.form, form);
    if (formChanged) {
      this.setState({ canSubmit: this.canSubmit() });
    }
  }

  isEditingDraft = () => {
    return this.props.form.details.isDraft;
  };

  onSaveAsDraft = () => {
    if (this.isEditingDraft()) {
      this.props.update(this.props.history);
    }
  };

  canSubmit = () => {
    const { form } = this.props;

    const formIsValid =
      form.shifts.valid && (detailsFormIsValid() || !form.details.invalid);
    if (this.isEditingDraft()) {
      return (
        formIsValid &&
        this.state.isAccepted &&
        this.props.hasAccount
      );
    } else {
      return formIsValid;
    }

    function detailsFormIsValid() {
      const detailsForm = document.querySelector("#detailsForm");
      return (
        detailsForm instanceof HTMLFormElement && detailsForm.checkValidity()
      );
    }
  };

  submitUpdates = () => {
    if (this.state.canSubmit) {
      if (this.isEditingDraft()) {
        this.props.publishDraft(this.props.history);
      } else {
        this.props.update(this.props.history);
      }
    } else {
      this.props.validateAll();
    }
  };

  onEditEap = () => {
    if (!this.state.canSubmit) return;

    if (this.isEditingDraft()) {
      this.props.publishDraft([]);
    } else {
      this.props.autoUpdate([]);
    }
  };

  onCancel = (cancelReason) => {
    this.props.cancel(this.props.history, cancelReason);
    this.toggleCancelEventModal();
  };

  onFieldChange = (field, value) => {
    this.props.onFieldChange(field, value);
    this.props.validateAll();
  };

  onClickDuplicate = () => {
    this.props.duplicateEvent(this.props.event, this.props.history);
  };

  render() {
    const buttonText = this.isEditingDraft() ? "publish event" : "update event";
    const { details } = this.props.form;
    const { shifts } = this.props;
    const canCancelEvent = !shifts.some(({ jobs }) => jobs?.some(({ currentState }) => ['checked_in', 'paid'].includes(currentState))) && moment().isBefore(moment(this.props.firstShiftStartTime));

    return (
      <div className="editEventScene">
        <h1>edit event details</h1>
        <p className="step-description">Add Information About Your Event</p>
        {canCancelEvent && <div className="cancel-panel">
          <button
            onClick={this.toggleCancelEventModal}
            type="button"
            className="btn btn-outline-danger btn-lg"
          >
            Delete this event
          </button>
          <div className="description">
            IMPORTANT: Deleting an event will delete the entire event, remove
            all shifts associated with the event, and remove any Staff you have
            approved to work the event.
          </div>
        </div>}
        <div className="duplicate-panel">
          <button
            onClick={this.onClickDuplicate}
            type="button"
            className="btn btn-outline-primary btn-lg"
          >
            Duplicate this event
          </button>
          <div className="description">
            Duplicating an event will copy all Event Information and Shift
            Details.
            <span>
              {" "}
              You will need to update the Shift Start Time and End Time.
            </span>
          </div>
        </div>
        <EventDetailsForm
          details={details}
          enums={this.props.enums}
          onFieldChange={this.onFieldChange}
        />
        <ShiftPanelList
          editMode
          onEditEap={this.onEditEap}
          eventId={this.props.event.id}
        />
        {this.isEditingDraft() && <h2>summary</h2>}
        {this.isEditingDraft() && (
          <EventBillingSummary
            shifts={shifts}
            payRate={details.payRate}
            noEditLink
            rateTypeId={details.rateTypeId}
            toggleCheck1={this.toggleIsAccepted}
            isAccepted={this.state.isAccepted}
            onPaymentTokenChange={this.onPaymentTokenChange}
          />
        )}
        <ButtonRow
          saveAndContinueButtonText={buttonText}
          saveAndContinue={this.submitUpdates}
          saveAsDraft={this.isEditingDraft() ? this.onSaveAsDraft : null}
          continueDisabled={!this.state.canSubmit}
          cancel={() => this.props.clearForm()}
        />
        <CancelEventModal
          isOpen={this.state.showCancelEventModal}
          toggle={this.toggleCancelEventModal}
          onCancel={this.onCancel}
          event={this.props.event}
          user={this.props.user}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let event = state.event.events.upcoming[ownProps.match.params.eventId];

  if (!event) {
    event = state.event.events.draft[ownProps.match.params.eventId];
  }

  const enums = state.enums;
  const form = state.createEvent;
  const firstShiftStartTime = new Date(Math.min(...form.shifts?.shifts?.map(({ startTime }) => new Date(startTime))));
  const shifts = form.shifts?.shifts?.filter(shift => !shift?.cancelled);

  const alreadyEditing =
    !!form.details.id &&
    form.details.id.toString() === ownProps.match.params.eventId;
  const hasAccount = !!state.payment.source.id;
  const user = state.session.currentUser;

  return {
    event,
    user,
    enums,
    form,
    shifts,
    alreadyEditing,
    hasAccount,
    firstShiftStartTime,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onFieldChange(name, newValue) {
      dispatch(fieldChange(name, newValue));
    },
    initialize(originalEvent) {
      dispatch(initForm(originalEvent));
    },
    clearForm() {
      dispatch(clearForm());
    },
    update(history) {
      dispatch(updateEvent({ history }));
    },
    autoUpdate() {
      dispatch(autoUpdateEvent());
    },
    validateAll() {
      dispatch(validateForm([1, 2]));
    },
    cancel(history, reason) {
      dispatch(cancelEvent({ history, reason }));
    },
    publishDraft(history) {
      dispatch(publishDraft({ history }));
    },
    duplicateEvent(event, history) {
      dispatch(duplicateEvent({ event, history }));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEventWrapper);
