import ApiService from './ApiService';

import { StripeSerializer } from 'services/serializers';

class PlaidService extends ApiService {
  constructor(session, userId) {
    super(session);

    this.userId = userId;
    this.url = `/users/${userId}/source.json`;
  }

  get() {
    return super.get(`/users/${this.userId}/source/plaid.json`,
      (response) => response);
  }

  createAccessToken(token) {
    return super.post(`/users/${this.userId}/source/plaid.json`, { token },
      (response) => response);
  }

  update(plaid) {
    return super.patch(`${this.url}`, { source: { plaid } }, () => true);
  }

  getPlaidLink() {
    return super.get(`/users/${this.userId}/source/plaid_link_token.json`,
      (response) => response);
  }
}

export default PlaidService;