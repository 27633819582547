import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';
import { isEmpty, uniq, get } from 'lodash';
import classnames from 'classnames';
import { SHIFT_CANCELLATION_REASONS, optionsForEnum } from 'helpers/enums';
import moment from 'moment-timezone';
import { formatCurrency, jobsInStateFromEvent } from 'helpers/dashboard';
import HorizontalRule from 'components/HorizontalRule';
import classNames from 'classnames';
import { formatTableDateTz, hour24ToMeridiem } from 'helpers/datetime';
import { getEstimatedFee } from 'helpers/getEstimatedFee';
import { getTimezone } from 'helpers/post';
import { rateTypeNameFinder } from 'helpers/rateTypeName';
import { isWeatherCancelReason } from 'helpers/dashboard';
import { computePenalty, totalHours } from 'helpers/cancellations';

const DeleteModal = ({
  onCancel,
  item,
  user,
  isOpen,
  isEvent,
  isLocation,
  cancelText,
  event,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [collapseEstimatedFee, setCollapseEstimatedFee] = useState(false);
  const [error, setError] = useState({
    cancelReason: '',
    otherReason: '',
  });
  const [cancelReason, setCancelReason] = useState('');
  const [otherReason, setOtherReason] = useState('');

  if (isEmpty(item)) return null;

  const onCancelModal = () => {
    if (onCancel) onCancel();
    toggle();
  };

  const toggleEstimatedFee = () => {
    setCollapseEstimatedFee(!collapseEstimatedFee);
  };

  const toggle = () => {
    if (props.toggle) {
      props.toggle();
      return;
    }
    setOpen(!open);
  };

  const onDelete = (reason) => {
    if (!!isEvent) props.onDelete({ ...item, reason });
    else props.onDelete(item.idx, reason);
  };

  const onDeleteLocation = (reason) => {
    if (!!props.fromEdit) {
      props.onDeleteLocation({
        locationId: props.location.id,
        locationIndex: props.location.idx,
        reason,
      });
    }
    props.onDeleteLocation(props.locationId, event, reason);
  };

  const sendWeatherCioEvent = () => {
    let confirmedAts = 0;
    if (!!isEvent) {
      Object.values(item.shifts.byId).forEach((shift) => {
        Object.values(shift.jobs.byId).forEach((job) => {
          if (job.currentState === 'confirmed') {
            confirmedAts++;
          }
        });
      });
      window._cio.track('weather_event_cancellations', {
        eo_id: user.id,
        eo_email: user.email,
        eo_first_name: user.firstName,
        eo_last_name: user.lastName,
        organization_name: user.companyName,
        event_name: item.title,
        event_start_date_time: moment(item.nextStartTime).format(),
        at_confirmed_count: confirmedAts,
      });
    } else {
      Object.values(item.jobs.byId).forEach((job) => {
        if (job.currentState === 'confirmed') {
          confirmedAts++;
        }
      });
      window._cio.track('weather_shift_cancellations', {
        eo_id: user.id,
        eo_email: user.email,
        eo_first_name: user.firstName,
        eo_last_name: user.lastName,
        organization_name: user.companyName,
        event_name: props.eventName,
        event_start_date_time: moment(item.startTime).format(),
        at_confirmed_count: confirmedAts,
      });
    }
  };

  const sendCancellationCioEvent = () => {
    const shiftsByLocation = {};
    const confirmedATIds = [];

    Object.values(item.shifts.byId).forEach((shift) => {
      const locationName = shift.eventLocation.name;
      const timeZone = getTimezone(shift.eventLocation);
      const formattedDate = moment(shift.startTime).tz(timeZone).format('MM/DD/YYYY');

      if (!shiftsByLocation[locationName]) {
        shiftsByLocation[locationName] = {
          dates: [],
        };
      }

      shiftsByLocation[locationName].dates.push(formattedDate);
    });

    let formattedEventDates;
    if (Object.keys(shiftsByLocation).length > 1) {
      formattedEventDates = Object.entries(shiftsByLocation)
        .map(([location, data]) => `${location}: ${data.dates.join(', ')}`)
        .join(', ');
    } else {
      const singleLocation = Object.values(shiftsByLocation)[0];
      formattedEventDates = singleLocation.dates.join(', ');
    }

    const venueNames = [
      ...new Set(Object.values(item.shifts.byId).map((shift) => shift.eventLocation.name)),
    ].join(', ');

    const venueAddresses = [
      ...new Set(
        Object.values(item.shifts.byId).map((shift) => {
          const { address } = shift.eventLocation;
          const street = address.streetAddress ? `${address.streetAddress}, ` : '';
          const city = address.city || '';
          const state = address.state || '';
          const zipCode = address.zipCode ? ` ${address.zipCode}` : '';
          return `${street}${city}, ${state}${zipCode}`.trim();
        })
      ),
    ].join(', ');

    Object.values(item.shifts.byId).forEach((shift) => {
      Object.values(shift.jobs.byId).forEach((job) => {
        if (job.currentState === 'confirmed') {
          confirmedATIds.push(job.userId);
        }
      });
    });

    window._cio.track('event_cancellation', {
      eo_id: user.id,
      eo_tier: user.tier,
      eo_email: user.email,
      organization_name: user.companyName,
      event_id: item.id,
      event_name: item.title,
      event_start_date_time: moment(item.nextStartTime).format(),
      cancellation_date: moment().format(),
      cancellation_reason: cancelReason,
      other_cancellation_reason: otherReason,
      formatted_event_dates: formattedEventDates,
      venue_name: venueNames,
      venue_address: venueAddresses,
      confirmed_ats: uniq(confirmedATIds).join(', '),
    });
  };

  const onClick = () => {
    if (isEmpty(cancelReason)) {
      setError({ ...error, cancelReason: 'This field is required' });
      return;
    }

    if (['Other'].includes(cancelReason) && isEmpty(otherReason)) {
      setError({ ...error, otherReason: 'This field is required' });
      return;
    }

    if (!!isLocation) {
      onDeleteLocation([cancelReason, otherReason].filter(Boolean).join(` - `));
      toggle();
      return;
    }

    if (isWeatherCancelReason(cancelReason) && window._cio) sendWeatherCioEvent();
    if (!!isEvent && window._cio) sendCancellationCioEvent();

    onDelete([cancelReason, otherReason].filter(Boolean).join(` - `));
    toggle();
  };

  const onChange = (e) => {
    setCancelReason(e.target.value);
    setOtherReason('');
    setError({
      cancelReason: '',
      otherReason: '',
    });
  };

  const onChangeOtherReason = (e) => {
    setOtherReason(e.target.value);
    setError({
      cancelReason: '',
      otherReason: '',
    });
  };

  const hasConfirmedATInTimeFrame = () => {
    const tz = getTimezone(item);
    if (!!isEvent || !!isLocation) {
      const activeShiftsInNext73Hours = props.activeShifts.filter((shift) =>
        moment(shift.startTime).isBefore(moment().add(73, 'hours'))
      );
      const hasConfirmed = activeShiftsInNext73Hours.some((shift) =>
        Object.values(shift.jobs.byId).some((job) =>
          ['confirmed', 'checked_in'].includes(job.currentState)
        )
      );
      return hasConfirmed;
    } else {
      const diffInHours = moment.tz(item.startTime, tz).diff(moment(), 'hours');
      return diffInHours <= 73;
    }
  };

  const moreThan18HoursLeft = () => {
    const tz = getTimezone(item);
    const diffInHours = !!isEvent
      ? moment(item.nextStartTime).diff(moment(), 'hours')
      : moment.tz(item.startTime, tz).diff(moment(), 'hours');
    return diffInHours >= 18;
  };

  const cancellationReasons = moreThan18HoursLeft()
    ? SHIFT_CANCELLATION_REASONS.filter((reason) => !isWeatherCancelReason(reason.id))
    : SHIFT_CANCELLATION_REASONS;

  const isModalOpen = typeof isOpen === 'boolean' ? isOpen : open;

  const deleteDisabled =
    isEmpty(cancelReason) || (['Other'].includes(cancelReason) && isEmpty(otherReason));

  const computeCancellationFee = (job) => {
    const { startTime } = job;
    const tz = getTimezone(job.shiftLocation);
    const rateType = rateTypeNameFinder(props.rateTypes, job.rateTypeId);
    const cancellationPenalty = computePenalty(job, tz, rateType);
    const { penaltyAmount } = cancellationPenalty;
    const totalCancellationFee =
      rateType === 'hr' ? get(job, 'payRate', 0) * totalHours(job) : get(job, 'payRate', 0);
    const fee = getEstimatedFee(startTime, 0, props.isAmex) / 100;

    if (penaltyAmount === 0 && user.tier !== 'Tier 1') {
      return parseFloat(fee * totalCancellationFee);
    }

    return parseFloat((fee * penaltyAmount + penaltyAmount).toFixed(2));
  };

  const activeShifts = !!isEvent || !!isLocation ? props.activeShifts : [item]

  const confirmedJobs = jobsInStateFromEvent(
    { shifts: activeShifts },
    ['confirmed']
  )
    .map((job) => {
      const tz = getTimezone(job.shiftLocation);
      return {
        ...job,
        startTime: moment.tz(get(job, 'startTime', ''), tz).format('MM/DD/YYYY HH:mm'),
        endTime: moment.tz(get(job, 'endTime', ''), tz).format('MM/DD/YYYY HH:mm'),
        startDate: moment.tz(get(job, 'startTime', ''), tz).format('MM/DD/YYYY'),
        endDate: moment.tz(get(job, 'endTime', ''), tz).format('MM/DD/YYYY'),
        start: moment.tz(get(job, 'startTime', ''), tz).format('HH:mm'),
        end: moment.tz(get(job, 'endTime', ''), tz).format('HH:mm'),
      };
    })
    .filter((job) => {
      return parseFloat(computeCancellationFee(job)) > 0
    });

  const confirmedATs = confirmedJobs.length;
  const confirmedAtsWithCancellationFee = confirmedJobs.filter(
    (j) => !!j.showCancellationPolicy
  ).length;

  const totalEstimatedFee = confirmedJobs
    .filter((j) => !!j.showCancellationPolicy)
    .reduce((acc, job) => acc + parseFloat(computeCancellationFee(job)), 0);

  const alreadyFilledPositions = jobsInStateFromEvent(
    { shifts: activeShifts },
    ['confirmed', 'paid', 'checked_in']
  ).length;

  return (
    <div>
      {props.children && (
        <button className="modal-button plain-button" onClick={toggle}>
          {props.children}
        </button>
      )}
      <div className="v2-confirmation-modal" style={{ position: 'absolute' }}>
        <Modal isOpen={isModalOpen} toggle={toggle} className="v2-confirmation-modal-content">
          <ModalHeader className="header" toggle={toggle}>
            {props.title || 'Are you sure?'}
          </ModalHeader>
          <ModalBody>
            <p>{props.body}</p>

            <div className="form-group pl-0 my-2">
              <label>
                Reason<span className="text-danger">*</span>
              </label>
              <select
                className={`form-control rounded-select custom-select ${cancelReason ? '' : 'text-secondary'
                  }`}
                name="reason"
                value={cancelReason}
                onChange={onChange}
                required
              >
                {optionsForEnum(cancellationReasons, 'Select a reason')}
              </select>
              {error.cancelReason && (
                <div className="text-danger mt-1 w-100">{error.cancelReason}</div>
              )}
              {cancelReason === 'Other' && (
                <div className="form-group pl-0 mt-2 mb-0">
                  <div className="mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="otherReason"
                      value={otherReason}
                      onChange={onChangeOtherReason}
                      required
                    />
                  </div>

                  {error.otherReason && (
                    <div className="text-danger mt-1 w-100">{error.otherReason}</div>
                  )}
                </div>
              )}
              {cancelReason === 'Incorrectly Posted' && (
                <div>
                  <p className="mt-2">
                    Instead of deleting this{' '}
                    {!!isEvent ? 'job' : !!isLocation ? 'location' : 'shift'}, learn how to edit it{' '}
                    <a
                      href="https://support.go4.io/hc/en-us/articles/13179441878285-How-to-Edit-an-Event-Job"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                    .
                  </p>
                </div>
              )}
              {cancelReason === 'No Applicants' && (
                <div>
                  <p className="mt-2">
                    For future job postings, we suggest posting at minimum 21 days before the start
                    date and at minimum use the state’s average pay rate.
                  </p>
                </div>
              )}
            </div>

            {!!alreadyFilledPositions && cancelReason && !isWeatherCancelReason(cancelReason) &&
              (user.tier !== 'Tier 1' || hasConfirmedATInTimeFrame()) &&
              confirmedATs > 0 &&
              (confirmedJobs.some((j) => !!j.showCancellationPolicy) ? (
                <React.Fragment>
                  <HorizontalRule />
                  <p>
                    <p className="warning-text">Warning:</p>{' '}
                    {`${confirmedAtsWithCancellationFee} athletic trainer${confirmedAtsWithCancellationFee === 1 ? ' is ' : 's have'
                      }
                    confirmed to work this job. If this job is canceled, you will be charged per the`}{' '}
                    <a
                      href="https://www.go4.io/go4-marketplace-standards/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Go4 Cancellation Policy
                    </a>
                    .
                  </p>
                  <div className="estimated-fee">
                    <div onClick={toggleEstimatedFee} className="estimated-jobs">
                      <p className={classNames(`m-0`)}>
                        {collapseEstimatedFee && <i className="material-icons">expand_more</i>}
                        {!collapseEstimatedFee && <i className="material-icons">chevron_right</i>}
                        View Estimated Fee
                      </p>
                    </div>
                    <Collapse
                      isOpen={collapseEstimatedFee}
                      style={{
                        width: '100%',
                        textAlign: 'left',
                      }}
                    >
                      <React.Fragment>
                        {confirmedJobs
                          .filter((j) => !!j.showCancellationPolicy)
                          .map((job) => {
                            if (computeCancellationFee(job) == 0) return null;

                            const tz = getTimezone(job.shiftLocation);
                            const rateType = rateTypeNameFinder(props.rateTypes, job.rateTypeId);
                            const { penaltyReason } = computePenalty(job, tz, rateType)
                            return (
                              <div className="confirmed-at" key={job.id}>
                                <p className="at-name">{job.user.name}</p>
                                <p className="job-date">
                                  {`${formatTableDateTz(job.startTime, tz)} | ${hour24ToMeridiem(
                                    job.start,
                                    { space: false }
                                  )} - ${hour24ToMeridiem(job.end, { space: false })}`}
                                </p>
                                <p className="cancellation-fee">
                                  Cancellation Fee:{' '}
                                  {formatCurrency({
                                    currency: computeCancellationFee(job),
                                    dollarSign: true,
                                  })}
                                </p>
                                <p className="cancellation-reason">{penaltyReason}</p>
                              </div>
                            );
                          })}
                        {confirmedAtsWithCancellationFee > 1 && (
                          <p className="estimates-total-fees">
                            Estimated Total Fees:{' '}
                            {formatCurrency({
                              currency: totalEstimatedFee,
                              dollarSign: true,
                            })}
                          </p>
                        )}
                      </React.Fragment>
                    </Collapse>
                  </div>
                </React.Fragment>
              ) : (
                <p>
                  <p className="warning-text">Notice:</p>
                  {` Starting Jan. 1, 2025, canceling a confirmed athletic trainer will result in charges under Go4’s new Cancellation Policy. `}
                  <a
                    href="https://www.go4.io/go4-marketplace-standards/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read more here.
                  </a>
                </p>
              ))}
            {props.showNote && (
              <div>
                <p className="note-text">
                  <span className="note">Note:</span> In order to save your changes you need to
                  update the job.
                </p>
              </div>
            )}
          </ModalBody>
          <ModalFooter className="actions">
            <button className="btn confirm-btn" onClick={onCancelModal}>
              Go Back
            </button>
            <button
              onClick={onClick}
              className={classnames('btn btn-success', {
                disabled: deleteDisabled,
              })}
            >
              {cancelText || 'Delete'}
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default DeleteModal;
