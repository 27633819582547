import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GenerateEAP from './components/GenerateEAP';
import UploadedEAP from './components/UploadedEAP';
import ActionBar from '../../ActionBar';
import VenueLocation from 'scenes/CreateEventScene/CreateEventStep3/EAPForm/VenueLocation';
import { changeProfileField } from 'redux/ducks/Post/Profile';
import { switchLocation } from 'redux/ducks/Post/Navigation';
import { get, isEmpty } from 'lodash';
import PostDetailsPanel from './components/PostDetailsPanel';
import { initEAPForm, updateEap, fieldChange, fetchServices } from 'redux/ducks/Post/EAP/actions';
import { trackMixpanelPageView } from 'helpers/post';
import HorizontalRule from 'components/HorizontalRule';
import * as locationActions from 'redux/ducks/Post/Locations/actions';
import { googlePlaceSelected } from 'redux/ducks/CreateEvent/Shifts';
import { AddButton } from 'components/V2/Buttons';

class ReviewEAP extends Component {
  state = { wasValidated: false };
  validate = () => this.setState({ wasValidated: true });

  componentDidMount() {
    if (!this.props.editMode) trackMixpanelPageView('create-event/eap/review', this.props.user);

    if (
      !this.props.form.id ||
      !this.props.form.hospital.id ||
      !this.props.form.policeStation.id ||
      !this.props.form.fireDepartment.id
    )
      this.fetchServices();
  }

  pageIsValid = () => {
    const { form, authenticated } = this.props;
    const { uploadedPlanPreview, generateOrUpload } = form;
    if (isEmpty(form) || !authenticated) return false;
    if (generateOrUpload === 'generate' || (generateOrUpload === 'upload' && uploadedPlanPreview))
      return true;

    return form.emergencyContacts.every(
      ({ role, phoneNumber, name, _destroy }) =>
        (role && phoneNumber && name) || _destroy || !(role || phoneNumber || name)
    );
  };

  redirectTo = (path) => this.props.history.push(path);

  onSaveAndContinue = () => {
    if (!this.pageIsValid()) {
      this.validate();
      return;
    }

    const { baseUrl, navigation, locations, editMode } = this.props;
    const callback = () => {
      const nextLocationIndex = navigation.locationIdx + 1;
      if (locations.length === nextLocationIndex) {
        this.redirectTo(`${baseUrl}/post`);
        return;
      }

      this.props.switchLocation(nextLocationIndex);
      if (!get(locations, `[${nextLocationIndex}].id`, null) && !editMode) {
        this.redirectTo(`${baseUrl}/location-setup`);
        return;
      }

      this.redirectTo(`${baseUrl}/location`);
    };

    this.props.updateEap(callback);
  };

  handlePrint = () => {
    const { profile } = this.props;
    const originalTitle = document.title;
    const afterPrint = () => {
      document.title = originalTitle;
    };
    const beforePrint = () => {
      document.title = `${profile.title} Emergency Action Plan`;
    };
    window.addEventListener('afterprint', afterPrint);
    window.addEventListener('beforeprint', beforePrint);
    window.print();
    window.removeEventListener('afterprint', afterPrint);
    window.removeEventListener('beforeprint', beforePrint);
  };

  fetchServices = () => {
    const { form, currentForm } = this.props;
    if (isEmpty(form)) return;

    this.props.fetchServices({
      index: currentForm,
      coordinates: {
        lat: form.eventLocation?.latitude,
        lng: form.eventLocation?.longitude,
      },
    });
  };

  render() {
    const { form } = this.props;
    if (isEmpty(form)) return null;

    const { generateOrUpload, uploadedPlanPreview: file } = form;
    return (
      <div className="reviewEAP">
        <main className="printable-section">
          <header>
            <div className="screen-title">Emergency Action Plan</div>
          </header>
          <div className="reviewEAPContainer">
            <div className="title">
              <p className="gotham-bold-font">REVIEW AND FINALIZE EAP</p>

              <HorizontalRule dashed white />

              <p className="title-help-copy nimbus-regular-font">
                To facilitate a safe job, carefully review the generated EAP to make sure all
                details are accurate.
              </p>
            </div>
            <PostDetailsPanel
              details={this.props.profile}
              onFieldChange={this.props.changeProfileField}
            />

            <HorizontalRule margin="20px" />

            <VenueLocation
              eventLocation={form.eventLocation}
              onChangeLocation={this.props.onChangeLocation(this.props.shiftLocation.idx)}
              onGooglePlaceSelected={this.props.onGooglePlaceSelected(this.props.shiftLocation.idx)}
              baseUrl={this.props.baseUrl}
              history={this.props.history}
            />
            {generateOrUpload === 'generate' && (
              <GenerateEAP
                wasValidated={this.state.wasValidated}
                updateEap={this.props.updateEap}
                shiftLocation={this.props.shiftLocation}
              />
            )}
            {generateOrUpload === 'upload' && <UploadedEAP {...this.props} filePreview={file} />}
            <AddButton className="export-print-eap" onClick={this.handlePrint}>
              EXPORT/PRINT EAP
            </AddButton>
          </div>
          <ActionBar
            editMode={this.props.editMode}
            backTo={`${this.props.baseUrl}/eap/eap-type`}
            currentStep="eap/review"
            nextDisabled={() => !this.pageIsValid()}
            onSaveAndContinue={this.onSaveAndContinue}
          />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentForm } = state.post.eap;
  const form = get(state, `post.eap.forms[${currentForm}]`, {});
  const { locationIdx } = state.post.navigation;
  const shiftLocation = state.post.locations[locationIdx];

  return {
    form,
    shiftLocation,
    ...state.post,
    authenticated: state.session.authenticated,
    user: state.session.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeProfileField: (name, value) => dispatch(changeProfileField({ name, value })),
  switchLocation: (idx) => dispatch(switchLocation({ locationIdx: idx })),
  updateEap: (callback) => dispatch(updateEap({ callback })),
  initEAPForm: (eventId, eventLocationId) =>
    dispatch(initEAPForm({ eventId, eventLocationId, from: 'review-eap' })),
  fetchServices: (payload) => dispatch(fetchServices(payload)),
  onChangeLocation: (idx) => (args) =>
    dispatch(locationActions.changeLocationField({ idx, ...args })),
  onGooglePlaceSelected:
    (idx) =>
    ({ placeId }) => {
      dispatch(googlePlaceSelected(idx, placeId, 'review-eap'));
    },
  onEAPFieldChange: (name, value) => dispatch(fieldChange({ name, value })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviewEAP));
