import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const TermsAndConditionsModal = (props) => {
  const { isOpen, user } = props;

  const logout = () => {
    props.history.push('/logout');
  };

  const accept = () => {
    const updateUser = { ...user, termsAndConditions: true };
    props.updateUser(updateUser);
  };

  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden';
    else document.body.style = null;
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      className="termsAndConditionsModal"
      style={{ minWidth: '50vw' }}
      backdrop="static"
    >
      <ModalHeader>Terms & Conditions</ModalHeader>
      <ModalBody className={'mb-3 px-4 pt-3 bg-white'}>
        <main className="main-content">
          <p className="bold">Including Athletic Trainers, Registered Nurses, and Operators</p>
          <p className="headers">
            A. <span>About Go4, Inc:</span>
          </p>
          <p>
            Welcome to Go4, Inc. d/b/a Go4 (“Go4”, “us”, “we”, or “our”). Go4 is a technology
            company. Go4 does not provide the services of athletic trainers (singular, “AT” or
            plural, “ATs”) or registered nurses (singular “RN” or plural, “RNs”) but creates a
            platform on which ATs, RNs and persons requiring AT and/or RN services may coordinate
            needs for the provision of such services. Go4 is the owner and operator of Go4.io, and
            various Go4 websites (including Go4Ellis.com and Go4healthcare.com), the Go4 app, and
            the platform, software, and other support for our services (collectively referred to as
            our “Website”). Go4 is a Delaware corporation.
          </p>
          <p>
            Our mission is to streamline the ability of individuals, entities and organizations
            (“Operator Users” or “OUs”) to staff shifts or events (“Events”) with athletic trainers
            (“Athletic Trainers Users” or “ATUs”) and/or registered nurses (“Registered Nurse Users”
            or “RNUs”). We do this through the services offered on our Website and by the services
            otherwise offered by us (together with our Website, our “Services”).
          </p>
          <p className="bold">
            By accessing or using our Services, including viewing or using any feature available
            through our Website, you (“User”, “Users”, “you”, or “your”), expressly agree to be
            bound to and to abide by the Terms and Conditions of Use in this Agreement (“Terms”),
            our Privacy Policy (which also governs your use of the Website and the Services and
            informs you of our data collection, use and disclosure practices), and any other policy
            we may develop from time to time (collectively, “Policies”), which creates a legal and
            enforceable agreement. . By accessing or using our Services you also expressly agree
            that Go4 may communicate with you via text, email, phone calls, push notifications or
            any other direct means of communication concerning your use of Go4 Services.
          </p>
          <p className="headers">
            By accessing our website and/or using our Services, You agree to be bound by these
            Terms, whether or not you register for and/or create a User profile (a “Profile”) with
            us or obtain, transmit, post, send, receive, link, email, or otherwise communicate to us
            or other users (“Post”) any text, notifications, data, descriptions, links, software,
            music, sound, photographs, images, video, messages, information, or any other material
            or input (“Content”) in connection with our Services.{' '}
            <span>
              By accessing our Website or using our Services, you are entering into an agreement to
              abide by these Policies and the Terms.
            </span>
          </p>
          <p className="bold">
            If you do not agree to be bound to or to abide by these Terms and our other Policies, do
            not access or browse or use our Website or use our Services.
          </p>
          <p>
            As set forth in more detail in this agreement, our Services create only a venue to
            connect Operator Users with Athletic Trainers Users and/or Registered Nurse Users. Users
            of our Services (including OUs, ATUs, and RNUs) are not our employees, independent
            contractors, members, partners, joint ventures, affiliates, principals, employers, or
            agents.
          </p>
          <p className="headers">
            B. <span>Our Terms of Use:</span>
          </p>
          <p className="headers">
            1. <span>Duration of License to Access Services.</span> Your use of and access to our
            Website and the Content thereon constitutes a personal, non-transferable,
            non-assignable, royalty-free, revocable, limited and temporary license (“License”) to
            use our Website subject to these Terms. Your limited License to use our Website is
            ongoing and continues until you cease using our Website, as determined by us in our sole
            discretion, or until your License, access to, or ability to use our Website is
            terminated or restricted by us in our sole discretion.
          </p>
          <p className="headers">
            2. <span>Service Conditions, Promises, Representations And Warranties For Users.</span>
          </p>
          <p className="headers">
            A.{' '}
            <span>
              Service Conditions, Promises, Representations And Warranties For Athletic Trainer
              Users (“ATU”)
            </span>
            .
          </p>
          <p>
            1. This Section 2(A), collectively, constitutes our ATU Service Conditions, Promises,
            Representations and Warranties (“ATU Service Conditions”). The ATU Service Conditions,
            the RNU Service Conditions (defined in Section 2(B) below) and the OU Service Conditions
            (defined in Section 3 below) are collectively referred to as the “Service Conditions”.
          </p>
          <p>
            2. By use of Services, you, the ATU, agree, covenant, represent and warrant at all times
            that:
          </p>
          <p>
            i. you are a currently licensed athletic trainer in the jurisdiction in which you
            practice or intend to practice and have no restriction or limitation on your license,
          </p>
          <p>ii. you are an Athletic Trainer offering services in the United States of America,</p>
          <p>
            iii. you possess all national and/or state certifications or licenses as may be required
            by applicable state and federal laws and regulations and maintain current professional
            liability insurance with minimum coverage amounts of $1,000,000 per occurrence
            /$3,000,000 in the aggregate, for exposures related to your performance of services as
            an AT, which names Go4 as an additional insured
          </p>
          <p>
            iv. you operate under valid orders of a licensed physician or other healthcare
            professional, as required by applicable state laws or regulations related to Athletic
            Trainer practice,
          </p>
          <p>
            v. you maintain child abuse and elder abuse clearances and all other clearances that may
            be required of professionals who have interaction with children or the elderly by the
            state and/or city, municipality, township, school district or school in which you may
            provide or perform services to or for any User.
          </p>
          <p>vi. you have reached the age of eighteen (18) years of age or older;</p>
          <p>
            vii. you have the right, authority and capacity to agree to and abide by these Terms;
          </p>
          <p>
            viii. you are currently not restricted from using our Services or otherwise prohibited
            from having a Profile;
          </p>
          <p>ix. you are not using our Services to compete with us, as solely determined by us;</p>
          <p>x. you agree to maintain only one Profile with us at any given time;</p>
          <p>
            xi. you will not violate any other agreement to which you are a party by agreeing to
            these Terms, our Services and/or providing services to Operator Users;
          </p>
          <p>
            xii. you will not violate any of our rights or those of a third party, including our or
            any third party’s intellectual property rights such as copyright, trade secret or
            trademark rights;
          </p>
          <p>
            xiii. you understand that it is your sole responsibility to acquire any necessary
            permits, authorizations, consents (including, but not limited to, consents to treatment
            where required) and other permissions required for you to work and treat patients in the
            United States and any other jurisdiction in which you provide services as an ATU and
            that you have obtained and shall maintain such permits, authorizations and permissions
            as current;
          </p>
          <p>
            xiv. you have not: (a) been the subject of a complaint, restraining order or any other
            legal action involving violence, abuse, neglect, fraud, larceny, or any offense that
            involves endangering the safety of others, including but not limited to sexual abuse,
            child abuse, elder abuse or domestic violence offenses; (b) been convicted of a crime of
            any nature, including any felony or misdemeanor of any kind, including without
            limitation any sexual, child abuse, elder abuse or domestic violence offenses; or (c)
            been and/or are not currently required to register as a sex offender in any jurisdiction
            or with any government entity;
          </p>
          <p>
            xv. you are not currently out on bail or on your own recognizance pending trial,
            relating to any felony or misdemeanor charges of any kind, including without limitation
            sexual, child abuse, elder abuse or domestic violence offenses;
          </p>
          <p>
            xvi. you agree to comply with all applicable federal and state laws with regard to the
            Services and inform your supervising physician of any engagements you may have with any
            User; and
          </p>
          <p>
            xvii. you have not been solicited by, or directed to, any particular Operator User by
            Go4.
          </p>
          <p>
            3. By using the Services, you further understand and agree that we may revise,
            supplement or amend the Service Conditions from time to time and require new conditions
            and certifications and that you will abide by such revised Service Conditions or
            discontinue using the Services. By your use of our Services after any amendment or
            change to the ATU Service Conditions you agree to such changes or amendments. In
            addition, you understand and agree that you must immediately discontinue use of the
            Services if at any time you no longer meet the ATU Service Conditions, including,
            without limitation, if at any time any of the above ATU Service Conditions
            representations and warranties is no longer true, particularly that of maintaining
            current licensure as an AT.
          </p>
          <p>
            4. By using the Services, you further understand and agree that: (i) we make no
            representation on your behalf that any of the Service Conditions responsibility and
            warranties are true; (ii) although we reserve the right to do so in our sole discretion,
            we do not routinely verify that any or all of the Service Conditions are met by any
            Users (including you); (iii) we are not responsible for assuring that the Service
            Conditions are met by any Users (including you); and (iv) we are not responsible, and
            will have no liability, for any action or failure to suspend, terminate or prevent the
            use of the Services by Users (including you), who do not meet the Service Conditions.
          </p>
          <p>
            5. You are solely responsible for obtaining the appropriate criminal records checks and
            references and you agree to make your own evaluations, decisions and assessments about
            whether to accept any engagements offered by other Users or otherwise interact with
            other Users. We expressly disclaim, and you expressly release us from, and agree to
            indemnify, defend, and hold us harmless from any and all liability whatsoever for any
            controversies, claims, costs, expenses, suits, injuries, losses, harm and/or damages
            arising, directly or indirectly, from and/or in any way related to: (i) any inaccuracy,
            untimeliness or incompleteness of a User’s (including you), representations regarding,
            or compliance with, the Service Conditions; (ii) any inaccuracy, untimeliness or
            incompleteness of information obtained or accessed by or through our Services; (iii) any
            misstatements and/or misrepresentations made by any Users (including you); and/or (iv)
            any engagement that you accept from any Users.
          </p>
          <p>
            6. If you are hired to work any shift via the Website, and you decommit or callout from
            that shift (“Callout”) within seven (7) days of the posted start time of the shift, such
            Callouts may negatively impact your Profile on the Website and may make it harder for
            you to find work on the Website in the future.
          </p>
          <p>
            7. Although we have no obligation to verify the Service Conditions or conduct any other
            verifications, we reserve the right to do so in our sole discretion, and you hereby
            authorize us to take any and all actions that we deem appropriate, in our sole
            discretion, to verify the representations and warranties you make (including compliance
            with the Service Conditions) or the other information you provide, which verification
            may include, without limitation, conducting criminal records checks, sex offender
            registry checks, motor vehicle records checks, identification verifications, credit
            checks and/or using available public records. You consent to any collection, use or
            disclosure by us in order to accomplish such verification, and you agree that we may
            take such action as we deem appropriate in our sole discretion with respect to any
            information we learn or obtain about you in connection with any of the foregoing.
          </p>
          <p>
            8. In the event that you, as ATU, treat an injury at any event for which you perform
            services for the OU that generates an Electronic Health Record (“EHR”), you agree to
            attempt to: 1&#41; obtain a written acknowledgement and authorization from the injured
            person or, as appropriate, the parent (or other legal representative) of the injured
            minor confirming his , her or their status as such, which acknowledgement is required
            before Go4 can provide the injured person or parent or other legal representative, as
            applicable, with an electronic copy of the injured person’s or minor’s EHR; 2&#41;
            obtain from the injured person or, as appropriate, the parent or other legal
            representative written confirmation of their receipt of the notice of your privacy
            practices; and 3&#41; to request from the injured person or, as appropriate, the parent
            or legal guardian a signed authorization to disclose the EHR to Go4 and authorizing Go4
            to maintain the EHR and to use data from the EHR for marketing, advertising, research,
            injury prevention, and other purposes permitted under HIPAA, as defined herein.
          </p>

          <p className="headers">
            3. <span>Service Conditions, Promises, Representations And Warranties For Users.</span>
          </p>
          <p className="headers">
            A.{' '}
            <span>
              Service Conditions, Promises, Representations and Warranties for Registered Nurse
              Users (“RNUs”).
            </span>
          </p>
          <p>
            1. This Section 2B, collectively, constitutes our RNU Service Conditions, Promises,
            Representations and Warranties (“RNU Service Conditions”).
          </p>
          <p>
            2. By use of Services, you, the RNU, agree, covenant, represent and warrant at all times
            that:
          </p>
          <p>
            i. you are a currently licensed Registered Nurse in the jurisdiction in which you
            practice or intend to practice and have no restriction or limitation on your license,
          </p>
          <p>
            ii. you are a Registered Nurse qualified to offer services in the United States of
            America;
          </p>
          <p>
            iii. you possess all national and/or state certifications or licenses as may be required
            by applicable state and federal laws and regulations and maintain current professional
            liability insurance with minimum coverage amounts of $1,000,000 per occurrence per
            individual instance/$3,000,000 per occurrence aggregate, which names Go4 as an
            additional insured;
          </p>
          <p>
            iv. you provide only those registered nursing services for which you are qualified and
            trained and are within the scope of the Nurse Practices Act applicable to the state
            where you are engaged to provide services to or for any User;
          </p>
          <p>
            v. you maintain child abuse and elder abuse clearances and all other clearances that may
            be required of professionals who have interaction with children or the elderly by the
            state and/or city, municipality or township in which you may provide or perform services
            to or for any User;
          </p>
          <p>vi. you have reached the age of eighteen (18) years of age or older;</p>
          <p>
            vii. you have the right, authority and capacity to agree to and abide by these Terms;
          </p>
          <p>
            viii. you are currently not restricted from using our Services, providing Services to
            any User, or otherwise prohibited from having a Profile;
          </p>
          <p>ix. you are not using our Services to compete with us, as solely determined by us;</p>
          <p>
            x. you agree to maintain only one Profile with us at any given time, and that your
            Profile will provide and include only accurate information;
          </p>
          <p>
            xi. you will not violate any other agreement to which you are a party by agreeing to
            these Terms, using our Services and/or providing services to Operator Users;
          </p>
          <p>
            xii. you will not violate any of our rights or those of a third party, including our or
            any third party’s intellectual property rights such as copyright, trade secret or
            trademark rights;
          </p>
          <p>
            xiii. you understand that it is your sole responsibility to acquire any necessary
            permits, authorizations, consents (including, but not limited to, consents to treatment
            where required) and other permissions required for you to work and treat patients in the
            United States and any other jurisdiction in which you provide services as an RN and that
            you have obtained and shall maintain such permits, authorizations and permissions as
            current;
          </p>
          <p>
            xiv. you have not: (a) been the subject of a complaint, restraining order or any other
            legal action involving violence, abuse, neglect, fraud, larceny, or any offense that
            involves endangering the safety of others, including but not limited to sexual abuse,
            child abuse, elder abuse or domestic violence offenses; (b) been convicted of a crime of
            any nature, including any felony or misdemeanor of any kind, including without
            limitation any sexual, child abuse, elder abuse or domestic violence offenses; or (c)
            been and/or are not currently required to register as a sex offender in any jurisdiction
            or with any government entity;
          </p>
          <p>
            xv. you are not currently out on bail or on your own recognizance pending trial,
            relating to any felony or misdemeanor charges of any kind, including without limitation
            sexual, child abuse, elder abuse or domestic violence offenses;
          </p>
          <p>
            xvi. you agree to comply with all applicable federal and state laws with regard to the
            Services; and
          </p>
          <p>
            xvii. you have not been solicited by, or directed to, any particular Operator User by
            Go4.
          </p>
          <p>
            3. By using the Services, you further understand and agree that we may revise the
            Service Conditions from time to time and require new conditions and certifications and
            that you will abide by such revised Service Conditions or discontinue using the
            Services. By your use of our Services after any amendment or change to the RNU Service
            Conditions you agree to such changes or amendments. In addition, you understand and
            agree that you must immediately discontinue use of the Services if at any time you no
            longer meet the RNU Service Conditions, including, without limitation, if at any time
            any of the above RNU Service Conditions representations and warranties is no longer
            true, particularly that of maintaining appropriate licensure.
          </p>
          <p>
            4. By using the Services, you further understand and agree that: (i) we make no
            representation on your behalf that any of the Service Conditions responsibility and
            warranties are true; (ii) although we reserve the right to do so in our sole discretion,
            we do not verify that any or all of the Service Conditions are met by any Users
            (including you); (iii) we are not responsible for assuring that the Service Conditions
            are met by any Users (including you); and (iv) we are not responsible, and will have no
            liability, for any action or failure to suspend, terminate or prevent the use of the
            Services by Users (including you) who do not meet the Service Conditions.
          </p>
          <p>
            5. You are solely responsible for obtaining the appropriate criminal records checks and
            references and you agree to make your own evaluations, decisions and assessments about
            whether to accept any engagements offered by other Users or otherwise interact with
            other Users. We expressly disclaim, and you expressly release us from, and agree to
            indemnify, defend, and hold us harmless from any and all liability whatsoever for any
            controversies, claims, costs, expenses, suits, injuries, losses, harm and/or damages
            arising, directly or indirectly, from and/or in any way related to: (i) any inaccuracy,
            untimeliness or incompleteness of a User’s (including your) representations regarding,
            or compliance with, the Service Conditions; (ii) any inaccuracy, untimeliness or
            incompleteness of information obtained or accessed by or through our Services; (iii) any
            misstatements and/or misrepresentations made by any User (including you); and/or (iv)
            any engagement that you accept from any Users.
          </p>
          <p>
            6. If you are engaged to perform services to or for any User via the Website, and you
            Callout from that engagement within seven (7) days of the posted start time of the
            engagement, such Callouts may negatively impact your Profile on the Website and may make
            it harder for you to find work on the Website in the future.
          </p>
          <p>
            7. Although we have no obligation to verify the Service Conditions or conduct any other
            verifications, we reserve the right to do so in our sole discretion, and you hereby
            authorize us to take any and all actions that we deem appropriate, in our sole
            discretion, to verify the representations and warranties you make (including compliance
            with the Service Conditions) or the other information you provide, which verification
            may include, without limitation, conducting criminal records checks, sex offender
            registry checks, motor vehicle records checks, identification verifications, credit
            checks and/or using available public records. You consent to any collection, use or
            disclosure by us in order to accomplish such verification, and you agree that we may
            take such action as we deem appropriate in our sole discretion with respect to any
            information we learn or obtain about you in connection with any of the foregoing.{' '}
          </p>
          <p className="headers">
            8.{' '}
            <span>
              Go4 does not collect, maintain, disclose or access Protected Health Information
              (“PHI”), as that term is defined under the Health Insurance Portability and
              Accountability Act (“HIPAA”) in any format for you, the RNU, or the Operator. You
              and/or the OU will be responsible for complying with any obligation or requirement
              under the HIPAA Privacy and Security Rules to collect or maintain PHI or any medical
              information. Go4 is not a business associate (as that term is defined under HIPAA) of
              any RNU or OU.
            </span>
          </p>

          <p className="headers">
            4.{' '}
            <span>
              Service Conditions, Promises, Representations and Warranties for Operator Users (“OU
              Service Conditions”).
            </span>
          </p>
          <p>A. This Section 3, collectively, constitutes our “OU Service Conditions”.</p>
          <p>
            B. To be an Operator User (“OU”) and use Services, you, the OU, agree, covenant,
            represent, and warrant that at all times:
          </p>
          <p>
            1. you are an adult residing within the United States of America over the age of 18 or
            an organization or entity formed in the United States of America. You are authorized to
            conduct business and/or operate in the jurisdiction in which you request ATU or RNU
            services and you are authorized to retain the services of an Athletic Trainer or
            Registered Nurse;
          </p>
          <p>
            2. if you are seeking the services of an ATU and/or an RNU for a sporting or other event
            or occurrence, you represent that it is a legal activity within the jurisdiction where
            such sporting or other event or occurrence may take place and that you are authorized to
            hold such activity; and
          </p>
          <p>
            3. the activity for which you seek an ATU and/or RNU will comply with all federal,
            state, local and other, laws, ordinances, orders, license requirements, and regulations.
          </p>
          <p>
            C. By using the Services, you understand and agree that we may revise the OU Service
            Conditions from time to time and require new conditions and certifications and that you
            will abide by such revised Service Conditions or discontinue using the Services. By your
            use of our Services after any amendment or change to the OU Service Conditions you agree
            to such changes or amendments. In addition, you understand and agree that you must
            immediately discontinue use of the Services if at any time you no longer meet the OU
            Service Conditions, including, without limitation, if at any time any of the OU Service
            Conditions representations and warranties you have made are no longer true.
          </p>
          <p>
            D. By using the Services, you understand and agree that: (i) we make no representation
            on your behalf that the OU Service Conditions representations and warranties are true;
            (ii) although we reserve the right to do so in our sole discretion, we do not verify
            that any or all of the Service Conditions are met by any Users (including you); (iii) we
            are not responsible for assuring that the Service Conditions are met by any Users
            (including you); and (iv) we are not responsible, and will have no liability, for any
            action or failure to suspend, terminate or prevent the use of the Services by Users
            (including you) who do not meet the Service Conditions.
          </p>
          <p className="headers">
            E.{' '}
            <span>
              If you, the OU, are a covered entity as that term is defined under HIPAA, you
              acknowledge that:
            </span>
          </p>
          <p>1. Go4 will have no access to PHI, as that term is defined under HIPAA;</p>
          <p>
            2. Go4 will have no obligation to maintain, collect or disclose PHI as required under
            HIPAA; and
          </p>
          <p>3. Go4 is not your business associate, as that term is defined under HIPAA.</p>
          <p>
            F. You are solely responsible for reviewing and/or procuring where applicable, any
            relevant documents, including but not limited to, the resume, national and/or state
            certifications and licenses, professional liability insurance coverage documentation and
            child and elder abuse clearances and qualifications of User and consents, including, but
            not limited to, consents to treatment when required by state and/or federal law.
          </p>
          <p>
            1. You agree to make your own evaluations, assessments and decisions about whether to
            engage other Users to perform services or otherwise interact with other Users.
          </p>
          <p>
            2. Go4 expressly disclaims, and you expressly release Go4 from and agree to indemnify,
            defend, and hold Go4 harmless from any and all liability whatsoever for any
            controversies, claims, costs, expenses, suits, injuries, losses, harm and/or damages
            arising, directly or indirectly, from and/or in any way related to: (i) any inaccuracy,
            untimeliness or incompleteness of a User’s (including you) representations regarding, or
            compliance with, the Service Conditions; (ii) any inaccuracy, untimeliness or
            incompleteness of information obtained or accessed by or through our Services; (iii) any
            misstatements and/or misrepresentations made by any Users (including you); and/or (iv)
            any engagement of a User to provide or perform services.
          </p>
          <p>
            G. The Website will automatically generate an Emergency Action Plan (“EAP”) for your
            Event based on the accuracy of the information that you enter when posting your Event.{' '}
          </p>
          <p>
            1. You understand that you are solely responsible for entering accurate information when
            posting your Event and solely responsible for reviewing and approving the EAP for your
            Event.
          </p>
          <p>
            2. We expressly disclaim, and you expressly release us from, and indemnify, defend, and
            hold us harmless from any and all liability whatsoever for any controversies, claims,
            costs, expenses, suits, injuries, losses, harm and/or damages arising, directly or
            indirectly, from and/or in any way related to any inaccuracy, untimeliness or
            incompleteness in the posting of your Event.
          </p>
          <p>
            H. If an athletic trainer is confirmed for a position, cancellations by the OU over
            seventy-three (73) hours before the scheduled start date will incur the Go4 service fee
            for the canceled position. Cancellations made within twenty-five (25) to seventy-two
            (72) hours of the start date will result in a 25% charge of the shift cost.
            Cancellations made within twenty-four (24) hours of the start date will result in a 50%
            charge of the shift cost. All such cancellation charges shall be due immediately upon
            notice by the OU of such change and are in addition to all other applicable fees and
            charges which Go4 may require as set forth in these Terms.
          </p>
          <p>In the case of extenuating circumstances, Go4 will review cancelation appeals.</p>
          <p>
            1. Although we have no obligation to verify the Service Conditions or conduct any other
            verifications, we reserve the right to do so in our sole discretion, and you hereby
            authorize us to take any and all actions that we deem appropriate, in our sole
            discretion, to verify the representations and warranties you make (including compliance
            with the Service Conditions ) or the other information you provide, which verification
            may include, without limitation, conducting criminal records checks, sex offender
            registry checks, motor vehicle records checks, identification verifications, credit
            checks and/or using available public records. You consent to any collection, use or
            disclosure by us in order to accomplish such verification, and you agree that we may
            take such action as we deem appropriate in our sole discretion with respect to any
            information we learn or obtain about you in connection with any of the foregoing.
          </p>

          <p className="headers">
            5. <span>Profiles.</span>
          </p>
          <p>
            A. We require you to create a Profile in order to use our Services and to have a valid
            License, as that term is used in Section B.1 of this Agreement. In connection with any
            Profile, you agree (i) to provide true, accurate, current and complete information about
            yourself as prompted by our registration form, and (ii) to maintain and promptly update
            the information you provide to us in order to keep your Profile true, accurate, current
            and complete. If you use the Website, you are responsible for maintaining the
            confidentiality of your Profile, username and password and for restricting access to
            your computer, and you agree to accept responsibility for all activities that occur
            under your Profile, username and password. You may not assign or otherwise transfer your
            Profile or username to any other person or entity. You acknowledge that we are not
            responsible for third party access to your Profile that results from theft or
            misappropriation thereof. You agree that we have the right, in our sole discretion, to
            terminate, suspend, or restrict your Profile according to these Terms. You agree to
            immediately notify us of any unauthorized uses of your username and password and/or any
            other breaches of security. We do not assume any responsibility to notify your local law
            enforcement agency of any identity theft; that is your responsibility. You agree we will
            not be liable for any loss or damages caused by your failure to comply with your
            security obligations.
          </p>
          <p>
            B. You agree that we may collect and use information from you as described in our
            Privacy Policy. You also agree that we may use automated methods to analyze information
            about your User behavior, system settings, devices used, and User
          </p>

          <p className="headers">
            6. <span>Content.</span>
          </p>
          <p>
            A. By Go4. All Content on our Website or obtained from a Linked Site (defined below) is
            provided to you “AS IS”. Any statements made by us and available through our Services
            are opinions only. We expressly disclaim all liability related to the accuracy or
            reliability of any opinion, advice, or Content on our Website or reliance on any of the
            foregoing. The information regarding our Services published on our Website may include
            inaccuracies or typographical errors. We do not warrant or represent that the Content
            available through our Services is complete or up to date.
          </p>
          <p>
            B. Linked Sites. Our Services may link to other sites by allowing you to leave our
            Website to access third-party material or by bringing third-party material into this
            Website via ‘inverse’ hyperlinks and framing technology (a “Linked Site”). We have no
            discretion to alter, update, or control the Content on a Linked Site. Any such links to
            Linked Sites are provided to you only as a convenience, and the fact that we have
            provided a link to a Linked Site is not necessarily an endorsement or affiliation with
            respect to such Linked Site, its owners, or its providers.
          </p>
          <p>
            C. Transmitted by You. You, the User, are solely responsible for and retain all rights
            in the Content that you communicate to us or transmit to other Users and/or Post. You
            understand and agree that we can delete any Content in our sole discretion and exclusive
            judgment. You also agree that by sending Content to us or transmitting Content on our
            Services you automatically grant to us an irrevocable and perpetual non-exclusive
            royalty-free license to use, copy, and distribute such Content in any way and to prepare
            derivative or collaborative works of such Content of any kind, as well as authorize us
            to sublicense any of the aforementioned Content. You also represent and warrant that any
            Content provided by you to us will not infringe or violate the intellectual property
            rights or other rights of any third party. You agree to release us from and to
            indemnify, defend, and hold us harmless from any and all liability related to any
            Content provided by you to us. We do not knowingly collect either online or offline,
            personal information from persons under the age of thirteen. If you are under the age of
            eighteen, you may not create a Profile or otherwise use the Website or the Services.
          </p>
          <p>
            D. Posted by Users or Others. We do not endorse and are not responsible for (i) the
            Content provided by other Users, (ii) the accuracy or reliability of any opinion,
            advice, statement, or Content made through our Services or by communication with you,
            (iii) any Content provided on Linked Sites, or (iv) the capabilities or reliability of
            any product or service obtained from a Linked Site. It is your responsibility to
            evaluate the accuracy, completeness, or usefulness of any opinion, advice, or other
            Content available through our Website or Services. You agree to release us from any and
            all liability related to any Content posted by other Users.
          </p>
          <p>
            E. Intellectual Property. The trademarks, logos, and Website marks including the names
            ‘Go4’, ‘Go4, Inc.’, ‘Go4Ellis’, and ‘Go4Healthcare’, our logos, and other graphics used
            by Go4 (“Go4 Marks”) are property of Go4. You are prohibited from using any Go4 Marks
            for any purpose, including, but not limited to, use as keywords or metatags on other
            pages or Websites on the Internet without the written permission of Go4. All information
            and Content owned by us and located on our Website is protected by copyright and your
            access to such information on our Website is strictly permitted through the License
            granted to you under these Terms. You are prohibited from modifying, copying,
            distributing, transmitting, displaying, publishing, selling, licensing, creating
            derivative works, or using any Content available on or through our Website for
            commercial or public purposes. We respect the intellectual property rights of others,
            and we expect Users to do the same. If you believe that the Content and/or the materials
            on our Services violate the Digital Millennium Copyright Act of 1998 or are infringing
            upon another’s copyright, trademark or other intellectual property, you may send a
            written notice to us at contactus@go4.io and provide the following information: (1)
            physical or electronic signature of the person authorized to act on behalf of the
            copyright owner; (2) identification of the copyrighted work claimed to have been
            infringed; (3) a detailed description of the material that you claim is infringing the
            copyrighted work, together with information sufficient to enable us to locate it; (4)
            your name, mailing address, telephone number and e-mail address; (5) a statement by you
            that you believe in good faith that the disputed use is not authorized by the copyright
            owner, its agent or the law; and (6) a statement by you that the information in the
            notice is accurate and, under penalty of perjury, that you are the owner of the
            copyright allegedly infringed or are authorized to act on behalf of the owner of such
            copyright.
          </p>

          <p className="headers">
            7. <span>User Interaction.</span>
          </p>
          <p className="headers">
            A. OUR SERVICES CREATE ONLY A VENUE TO CONNECT OPERATOR USERS WITH ATUs AND RNUs.
            ALTHOUGH WE ANTICIPATE THAT USERS WILL PRIMARILY ENGAGE THE SERVICES OF ATUS AND/OR
            RNUS, WE MAKE NO REPRESENTATION OR WARRANTY REGARDING, AND HAVE NO CONTROL OVER, THE
            SERVICES REQUESTED OR PROVIDED BY USERS.{' '}
            <span>
              USERS ARE NOT EMPLOYEES, INDEPENDENT CONTRACTORS, PARTNERS, MEMBERS, EMPLOYERS,
              PRINCIPALS OR AGENTS OF GO4.
            </span>
          </p>
          <p>B. YOU UNDERSTAND AND AGREE THAT GO4:</p>
          <p>
            1. does not employ, recommend or endorse any Users and has no control over the services
            provided or requested, the manner of services rendered, supervision, acts or omissions
            of any other User in any way using or having used the Services, on or off our Website;
          </p>
          <p>
            2. does not directly or indirectly supervise, control or direct the conduct or
            performance of any User, including the tools or methods of any User;
          </p>
          <p>
            3. does not maintain, create or have access to PHI of any individual for which an RNU
            provides services to an OU or creates a record of any kind;
          </p>
          <p>
            4. to the extent that Go4 maintains PHI from ATUs as set forth in Section 2.A.h of the
            ATU Service Conditions, it will do so in compliance with HIPAA.
          </p>
          <p>
            5. makes no representation regarding the legal relationship between Users, including
            between OUs and RNs or ATUs, and is not responsible for any User’s compliance or
            non-compliance with applicable employment and other laws in connection with any
            service-based relationship established with another User (such as applicable insurance,
            workers’ compensation, payroll, tax, overtime, and minimum wage laws);
          </p>
          <p>
            6. makes no representations or warranties about the quality or legality of the services
            provided by any User or about interactions or dealings with other Users; and
          </p>
          <p>
            7. is not responsible for the performance or conduct of any User or other third parties
            in any way using or having used the Services, on or off the Website.
          </p>
          <p>
            C. Although we reserve the right to do so, Go4 makes no representation that it has
            screened or will screen Users or conduct any kind of identity or criminal records
            checks. As such, Users should exercise caution and perform their own screening before
            connecting with a User through the Services, meeting anyone, hiring anyone or accepting
            any engagement.
          </p>
          <p>
            1. Go4 expressly disclaims, and the User expressly releases us from and agrees to
            indemnify, defend, and hold Go4 harmless from any and all liability whatsoever for any
            controversies, claims, costs, expenses, suits, injuries, losses, harm and/or damages
            arising, directly or indirectly, from and/or in any way related to the Services or your
            interactions or dealings with other Users, including without limitation any acts and/or
            omissions of Users in any way using or having used the Services, on or off our Website.{' '}
          </p>
          <p>
            2. By using the Services, you acknowledge that you are solely responsible for any use of
            the services and the connections or engagements you make and that your USE OF OUR
            SERVICES IS AT YOUR SOLE RISK. YOU FURTHER acknowledge that go4 is not involved in the
            transactions between Users and has no control over and does not guarantee the quality,
            safety or legality of any such transaction.{' '}
          </p>

          <p className="headers">
            8. <span>Termination Restriction and Suspension of Profile or License.</span>
          </p>
          <p>A. Termination.</p>
          <p>
            1. By You. You have the right to terminate your License (see Section B.1) to use our
            Services and/or your Profile (see Section B.4) at any time and for any reason by
            notifying us in writing (email to contactus@go4.io is acceptable). Your termination of
            the License and removal of your Profile will be effective within a commercially
            reasonable time after we receive notification of your desire to terminate your Profile
            with us, and any Fees (defined below) owed are paid to us.
          </p>
          <p>
            2. By Go4. Go4 retains the right to terminate, restrict or suspend your Profile and/or
            your License to use or access our Services at any time in our absolute and sole
            discretion, without prior notice, for any reason or no reason. No User shall have only a
            Profile or only a License; if one is terminated the other is terminated. Should Go4
            elect to terminate, restrict or suspend your Profile and/or your License, your License
            and your Profile shall automatically and immediately be revoked.
          </p>
          <p>
            B. After Termination. Upon termination of your License or removal of your Profile for
            any reason, you agree not to browse, use, or otherwise access our Website in any way.
            You agree that we may take any measures we deem necessary to prevent you from using our
            Website, including by blocking your IP address. You agree that after termination of your
            License or removal of your Profile, we are not obligated to retain or provide to you any
            Content or personally identifying information which was collected by us, but we may
            elect to do so in our sole discretion. Termination of your License or removal of your
            Profile shall not affect any of our rights under Section 5(c) above with respect to your
            granting to us an irrevocable and perpetual non-exclusive royalty-free license to your
            Content.
          </p>

          <p className="headers">
            9. <span>Payment.</span>
          </p>
          <p>
            A. Go4 may charge a Fee for your continued use of our Services, and we may charge a fee
            to Users in connection with accessing our Services and/or posting inquiries or Profiles
            (“Fee(s)”). Go4’s Fees are set forth in our Fee Schedule, and we may change this fee
            structure fully or in part in the future upon reasonable notice to Users.
          </p>
          <p>
            B. Go4 currently charges the following separate Fees: (i) OU’s are charged a usage fee
            for each transaction between the OU and the ATU and/or the RNU; and (ii) ATU’s and RNU’s
            are charged a payment processing fee, which is calculated as a percentage of total ATU
            or RNU compensation for each transaction. All such Fees are listed in the then-current
            Fee Schedule and are in addition to all other fees and charges which may be required
            under these Terms.
          </p>
          <p>
            C. You agree to pay us the Fees then in effect for using our Services, and you authorize
            us to charge your chosen payment method in connection therewith. In connection with any
            Fees paid by you to us for Services, whether you pay any such funds to us via our
            Website or otherwise, you agree: (i) to only provide valid and current payment
            information, (ii) that we may use the tools, software or services of our payment
            processors to process transactions on our behalf; and (iii) that you will promptly pay
            all amounts due upon demand. We are not responsible or liable for any activities or
            conduct of a payment processor or any other third party involved in the processing of
            any payments made by you, and you agree to hold us harmless, and expressly release us,
            from any and all liability arising from the conduct of any such party. You understand
            and agree that we offer no refunds of Fees for any reason whatsoever, including, but not
            limited to, refunds for any fees or partial fees that are forfeited due to restriction,
            suspension or termination of a Profile or License or cancellation by a User of an
            engagement. However, notwithstanding the preceding sentence, we reserve the right to
            refund all or part of Fees paid to us, if we, in our sole discretion, determine that
            such action is necessary or desirable.
          </p>
          <p className="headers">
            10. <span>Use Restrictions.</span>
          </p>
          <p>
            A. Our Services are intended to be used only for lawful purposes by individuals seeking
            Users to staff an Event and individuals seeking to perform services for Users. You may
            not use (or plan, encourage or help others to use) our Services for any purpose or in
            any manner that is prohibited by these Terms or by applicable law. It is your
            responsibility to ensure that your use of our Services complies with these Terms.
          </p>
          <p>
            B. By using our Services, you agree at all times that you shall not: (a) copy,
            distribute, or modify any part of our Website without our prior written authorization;
            (b) act dishonestly or unprofessionally by engaging in unprofessional behavior or by
            posting inappropriate, inaccurate, or objectionable Content to our Services; (c)
            transmit any Content which contains software viruses, or other harmful computer code,
            files or programs; (d) Post Content that falsely states, impersonates or otherwise
            misrepresents your identity, including but not limited to the use of a pseudonym, or
            misrepresenting your current or previous positions and qualifications, or your
            affiliations with a person or entity, past or present; (e) make threats or use
            profanity; (f) harass, stalk or intimidate other Users; (g) manipulate or exclude
            identifiers in order to disguise the origin of any Content; (h) Post personally
            identifying information about yourself or others to us or on our Website (if you Post
            this information about you or others, it will be public information and we are not
            responsible for how others may use it); (i) disrupt the networks connected to our
            Services, including but not limited to, by: attempting to probe, scan or test the
            vulnerability of our Services, attempting to breach security or authentication measures
            without proper authorization, or attempting to interfere with our Services or a User, by
            means such as overloading, “flooding”, “mailbombing” or “crashing”; (j) circumvent,
            disable or otherwise interfere with security-related features of our Services or
            features that prevent or restrict use or copying of any Content or that enforce
            limitations on use of our Website; (k) collect Content or information from our Website,
            or otherwise access our Website, by using any automated means, including without
            limitation, “robots,” “spiders,” “scrapers” and “offline readers,” without our prior
            written approval, such approval we may revoke at any time; (l) collect or harvest any
            personally identifiable information from our Services; (m) use any communications
            systems provided by our Services to send unsolicited or unauthorized commercial
            communications, including without limitation by email, SMS, MMS, or any other means; (n)
            remove, alter or obscure any proprietary notice or identification, including copyright,
            trademark, patent or other notices, contained in or displayed on our Website; or (o) use
            our Services to violate any applicable laws, rules or regulations, or for any unlawful,
            harmful, irresponsible, or inappropriate purpose, or in any manner that breaches these
            Terms or is otherwise objectionable, as determined by us in our sole discretion.
          </p>

          <p className="headers">
            11. <span>Not Professional Advice.</span>
          </p>
          <p>
            None of the materials, Content, or information available on or through our Services
            constitutes employment, career, financial, legal, medical, tax or other professional
            advice. You are encouraged to obtain advice tailored to your particular situation from a
            qualified professional if you have any questions regarding any such matters.
          </p>

          <p className="headers">
            12. <span>Dispute Resolution.</span>
          </p>
          <p>
            A. In the event that any dispute arises between us, including but not limited to a
            dispute with respect to, or arising out of, these Terms or any of our Policies, which
            are incorporated by reference herein, such dispute shall be resolved by binding
            arbitration in accordance with the rules of the American Arbitration Association (“AAA
            Rules”), in Wilmington, Delaware, and such arbitration shall be before a single
            arbitrator selected in accordance with the AAA Rules.
          </p>
          <p>
            B. YOU AGREE THAT ANY CAUSE OF ACTION THAT YOU MAY HAVE ARISING OUT OF OR RELATED TO OUR
            SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES OR WITHIN
            SUCH SHORTER OR LONGER TIME REQUIRED BY ANY APPLICABLE STATUTE, REGULATION, OR OTHER
            LAW. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
          </p>
          <p>
            C. Each party shall pay its own proportionate share of arbitrator fees and expenses,
            including the fees and expenses of the arbitrator and the arbitration fees and expenses
            of the American Arbitration Association. Each party shall be responsible for its own
            attorneys’ fees, subject to fee shifting provisions of any applicable law.
          </p>
          <p>
            D. YOU AND GO4 AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN
            INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
            REPRESENTATIVE ACTION OR PROCEEDING. UNLESS BOTH YOU AND GO4 AGREE OTHERWISE, THE
            ARBITRATOR OR COURT MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON’S OR PARTY’S CLAIMS
            AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS
            PROCEEDING. ALSO, THE ARBITRATOR OR COURT MAY AWARD RELIEF (INCLUDING MONETARY,
            INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF
            AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY’S
            INDIVIDUAL CLAIM(S). ANY RELIEF AWARDED CANNOT AFFECT OTHER GO4 USERS.
          </p>
          <p>
            E. Notwithstanding the foregoing provisions of this Section 12, you agree that Go4 may
            file any action related to alleged violations of the Terms related to Go4’s intellectual
            property or any User’s competition with Go4 in a court of competent jurisdiction without
            first seeking relief through arbitration. By using our Services, you irrevocably agree
            and consent to be bound to personal jurisdiction of and venue selection in the State
            courts located in New Castle County, Delaware or in the U.S. District Court for the
            District of Delaware, as the case may be, whether either arbitration or litigation
            arises between us and you.
          </p>

          <p className="headers">
            13. <span>Disclaimer of Warranties.</span>
          </p>
          <p>
            A. The information and materials contained on our Services, including text, graphics,
            information, links or other items are provided “AS IS”, “AS AVAILABLE”. Further,
            opinions, advice, statements, offers, or other information or Content made available
            through the Services, but not directly by us, are those of their respective authors, and
            should not necessarily be relied upon. Such authors are solely responsible for such
            Content. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE DO NOT: (i) WARRANT THE
            ACCURACY, ADEQUACY OR COMPLETENESS OF THIS INFORMATION AND MATERIALS; (ii) ADOPT,
            ENDORSE OR ACCEPT RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY OPINION, ADVICE,
            OR STATEMENT MADE BY ANY PARTY; (iii) WARRANT THAT YOUR USE OF THE SERVICES WILL BE
            SECURE, FREE FROM COMPUTER VIRUSES, UNINTERRUPTED, ALWAYS AVAILABLE, ERROR-FREE OR WILL
            MEET YOUR REQUIREMENTS, OR THAT ANY DEFECTS IN THE SERVICES WILL BE CORRECTED; OR (iv)
            GIVE ANY WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT OF THIRD
            PARTY RIGHTS. TO THE EXTENT PERMITTED BY APPLICABLE LAW, WE EXPRESSLY EXCLUDE ALL
            CONDITIONS, WARRANTIES AND OTHER TERMS WHICH MIGHT OTHERWISE BE IMPLIED BY STATUTE,
            COMMON LAW OR THE LAW OF EQUITY AND DISCLAIM LIABILITY FOR ERRORS OR OMISSIONS IN THIS
            INFORMATION AND MATERIALS.
          </p>
          <p>
            B. IN ADDITION TO AND WITHOUT LIMITING THE FOREGOING, WE MAKE NO REPRESENTATION OR
            WARRANTIES OF ANY KIND WHETHER EXPRESS OR IMPLIED REGARDING THE SUITABILITY OF ANY USER
            OF OUR SERVICES TO PROVIDE WORK TO ANOTHER USER OR OF ANY USER PERFORMING WORK FOR
            ANOTHER USER.
          </p>

          <p className="headers">
            14. <span>Assumption of Risk.</span>
          </p>
          <p>
            Users assume all risk when using our Services, including but not limited to all of the
            risks associated with any online or offline interactions with Users of the Services. You
            agree to take all necessary and reasonable precautions.
          </p>

          <p className="headers">
            15. <span>Limitation of Liability.</span>
          </p>
          <p>
            A. In no event will we be liable for any direct, indirect, punitive, special,
            incidental, or consequential damages, losses or expenses arising out of or relating to
            the use or inability to use our Services, including without limitation damages related
            to any transaction conducted between Users, information received from our Services,
            removal of Content from Website, including Profile information, any email distributed to
            any User or any Linked Site or use thereof or inability to use by any party, or in
            connection with any termination of your Profile or ability to access our Services,
            failure of performance, error, omission, interruption, defect, delay in operation or
            transmission, computer virus or line or system failure, even if we, or our
            representatives, are advised of the possibility of such damages, losses or expenses.
            UNDER NO CIRCUMSTANCES WILL OUR AGGREGATE LIABILITY, IN ANY FORM OF ACTION WHATSOEVER IN
            CONNECTION WITH THESE TERMS OR THE USE OF THE SERVICES, EXCEED the lesser of (x) the
            amount of Fees paid by you during the 12-month period prior to the commencement of such
            action and (y) FIVE-hundred dollars ($500.00). You agree that without these limitations
            on our liability we would not be able to provide the Services to you and that these
            limitations shall apply even if it would cause your remedies under these terms to fail
            of their essential purpose.
          </p>
          <p>
            B. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WE BE LIABLE FOR
            ANY DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT, PUNITIVE, GENERAL, SPECIAL,
            COMPENSATORY, AND/OR CONSEQUENTIAL, ARISING OUT OF OR RELATING TO THE CONDUCT OF YOU OR
            ANYONE ELSE IN CONNECTION WITH THE USE OF THE SERVICES, INCLUDING WITHOUT LIMITATION,
            BODILY INJURY, EMOTIONAL DISTRESS, AND/OR ANY OTHER DAMAGES RESULTING FROM ANYONE’S
            RELIANCE ON INFORMATION OR OTHER CONTENT POSTED ON THE SERVICES, OR TRANSMITTED TO OR BY
            ANY USERS OR ANY OTHER INTERACTIONS WITH OTHER USERS OF OUR SERVICES, WHETHER ONLINE OR
            OFFLINE. THIS INCLUDES ANY CLAIMS, LOSSES OR DAMAGES ARISING FROM THE CONDUCT OF USERS
            WHO HAVE PROFILES UNDER FALSE PRETENSES OR WHO ATTEMPT TO, OR DO, DEFRAUD OR HARM YOU.
          </p>
          <p>
            C. In addition to the preceding paragraphs of this section and other provisions of these
            Terms, any advice that may be posted on our Services is for informational purposes only
            and is not intended to replace or substitute for any financial, medical, legal, tax or
            other professional advice. We make no representations or warranties, and expressly
            disclaim any and all liability, concerning any treatment, action by, or effect on any
            person following the information offered or provided within or through our Services. If
            you have specific concerns or a situation arises in which you require any such
            professional advice, you should consult with an appropriately trained and qualified
            professional.
          </p>

          <p className="headers">
            16. <span>Indemnification.</span>
          </p>
          <p>
            A. You agree to indemnify, defend, and hold harmless Go4 and our officers, directors,
            shareholders, members employees, managers, subsidiaries, other affiliates, successors,
            assignees, agents, advisors, representatives, advertisers, marketing partners,
            licensors, independent contractors, recruiters, corporate partners and resellers
            (“Indemnitees”) from and against any and all claims, losses, expenses and demands of
            liability, or including, without limitation, reasonable attorneys’ fees and costs
            incurred by us and our Indemnitees in connection with any claim, liability, loss,
            expense or demand (including but not limited to any claims by third parties and
            intellectual property claims) arising, directly or indirectly, out of or otherwise
            relating to (i) materials and Content you Post through our Services, (ii) your use of
            the Services (iii) your violation of these Terms, our Policies or any applicable law
            (iv) bodily injury, death of any person, or damage to real or tangible personal property
            resulting, in whole or in part, from your acts or omissions and (v) your breach of, or
            the inaccuracy of, your representations and warranties.
          </p>
          <p>
            B. You further agree that you will cooperate as reasonably required in the defense of
            such claims.
          </p>
          <p>
            C. We and our Indemnitees reserve the right, at our own expense, to assume the exclusive
            defense and control of any matter otherwise subject to indemnification by you, and you
            shall not, in any event, settle any claim or matter without our written consent. You
            further agree to hold us and our Indemnitees harmless from any claim arising from a
            third party’s use of information, Content, or materials of any kind that you Post to our
            Services.{' '}
          </p>

          <p className="headers">
            17. <span>Choice of Law.</span>
          </p>
          <p>
            These Terms, our Privacy Policy, and any other Polices and any and all matters arising
            out of or relating to them, and the Services described herein or provided by Go4,
            whether sounding in contract, tort, or statute, for all purposes shall be governed by
            and construed in accordance with the laws of the State of Delaware, without regard to
            conflicts of law principles.
          </p>

          <p className="headers">
            18. <span>Severability.</span>
          </p>
          <p>
            If any of these conditions shall be deemed invalid, void, or for any reason
            unenforceable, that condition shall be deemed severable and shall not affect the
            validity and enforceability of any remaining condition.
          </p>

          <p className="headers">
            19. <span>Compliance.</span>
          </p>
          <p>
            You represent and warrant that you shall comply with all applicable laws, statutes,
            ordinances, and regulations regarding use of our Website and Services.
          </p>

          <p className="headers">
            20. <span>Entire Agreement; Modification.</span>
          </p>
          <p>
            These Terms together with our Polices any other document referenced herein, constitute
            the entire understanding between Go4 and you with respect to the subject matter hereof.
            You agree that we may amend, modify, or alter these Terms or our Polices at any time in
            our sole discretion and that the publishing of the most recent Terms or Policies on our
            Website, together with your continued use of our Services after such publication shall
            constitute your acceptance of any such subsequent Terms or Policies.
          </p>

          <p className="headers">
            21. <span>Feedback.</span>
          </p>
          <p>
            Please send your comments, concerns, or questions to: contactus@go4.io. While we
            encourage you to provide feedback, comments and questions, it is possible that we may
            not be able to respond to all feedback we receive.
          </p>

          <p className="headers">
            22. <span>Electronic Communication.</span>
          </p>
          <p>
            Visiting{' '}
            <a href="https://www.go4.io/" target="_blank" rel="noopener noreferrer">
              www.go4.io
            </a>{' '}
            or sending emails to contactus@go4.io constitutes electronic communications. You consent
            to receive electronic communications and you agree that all notices, disclosures and
            other communications that we provide to you electronically, via email and on the
            Website, satisfy any legal requirement that such communications be in writing.
          </p>
        </main>
      </ModalBody>
      <ModalFooter className="actions">
        <button className={classNames('btn decline-button')} onClick={logout}>
          LOG OUT
        </button>
        <button className={classNames('btn accept-button')} onClick={accept}>
          ACCEPT
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default TermsAndConditionsModal;
