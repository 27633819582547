import React, { Component } from 'react';
import HorizontalRule from 'components/HorizontalRule';
import ActionBar from '../../ActionBar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { suppliesFieldChange } from 'redux/ducks/Post/Locations/actions';
import { saveAsDraft } from 'redux/ducks/Post/actions';
import { AVAILABLE_EQUIPMENTS, trackMixpanelPageView } from 'helpers/post';
import ConfirmationModal from 'components/V2/ConfirmationModal';
import ToolTip from 'scenes/V2/ToolTip';
import CustomCheckBox from 'components/V2/CustomInputs/CustomCheckBox';

class Supplies extends Component {
  constructor(props) {
    super(props);
    props.initialize('Supplies');
    this.state = { isModalOpen: false };
  }
  toggle = () => this.setState({ isModalOpen: !this.state.isModalOpen });

  componentDidMount() {
    if (!this.props.editMode)
      trackMixpanelPageView('create-event/details/supplies', this.props.user);
  }

  hasEquipment = (value) => {
    const { supplies } = this.props.shiftLocation;
    const equipments = supplies.equipments || [];

    return equipments.some((eq) => eq === value);
  };

  toggleEquipment = (eq) => (e) => {
    const { locationIdx } = this.props;
    const { equipments } = this.props.shiftLocation.supplies;
    const newEquipment = this.hasEquipment(eq)
      ? equipments.filter((e) => e !== eq)
      : [...equipments, eq];

    this.props.onSuppliesChange(locationIdx, 'equipments', newEquipment);
  };

  onChangeOtherEquipments = (e) => {
    const { locationIdx } = this.props;
    const { value } = e.target;
    const [, ...equipments] = this.props.shiftLocation.supplies.equipments;

    this.props.onSuppliesChange(locationIdx, 'equipments', [value, ...equipments]);
  };

  onSaveAndContinue = () => {
    const { equipments } = this.props.shiftLocation.supplies;
    if (equipments.length === 1 && equipments[0] === '' && !this.state.isModalOpen) {
      this.toggle();
      return;
    }

    const { baseUrl, post } = this.props;
    const callback = () => {
      this.props.history.push(`${baseUrl}/details/details`);
    };

    if (!this.props.authenticated) {
      callback();
      return;
    }

    this.props.saveAsDraft(post, callback);
  };

  onSaveModal = () => {
    this.toggle();
    this.onSaveAndContinue();
  };

  computeBackTo = () => {
    const { baseUrl, editMode } = this.props;
    if (editMode) return `${baseUrl}/rate`;
    return `${baseUrl}/schedule-summary`;
  };

  render() {
    const { supplies } = this.props.shiftLocation;

    return (
      <div className="supplies">
        <main>
          <header>
            <div className="screen-title">Supplies & Equipment</div>
          </header>
          <div className="detailsContainer">
            <p className="med-kit nimbus-regular-font">
              Athletic trainers are instructed to bring their own medical kit.
              <ToolTip
                id="medical-kit-icon"
                top="3px"
                bodyText="Medical kits can vary but often include first aid supplies, personal protective equipment (PPE), specialized medical equipment, and injury management tools."
              />
            </p>
            <HorizontalRule margin={10} />
            <p className="select-supplies-text gotham-bold-font">
              Select all supplies and equipment available at this location
            </p>
            <HorizontalRule margin={10} />
            <div className="equipment-grid">
              {AVAILABLE_EQUIPMENTS.map((equipment, index) => {
                const name = equipment.split(' ').join('_').toLowerCase();
                return (
                  <React.Fragment>
                    <CustomCheckBox
                      id={name}
                      name={name}
                      checked={this.hasEquipment(equipment)}
                      onClick={this.toggleEquipment(equipment)}
                      className="equipment-item nimbus-regular-font"
                    >
                      {equipment}
                    </CustomCheckBox>
                    <HorizontalRule margin={10} dashed />
                  </React.Fragment>
                );
              })}
            </div>
            <div className="form-group other-supplies nimbus-regular-font">
              <label htmlFor="other_equipments">
                Other equipment available at this location (separate by a comma).
              </label>
              <input
                type="text"
                className="form-control"
                aria-label="Text input with dropdown button"
                value={get(supplies, 'equipments[0]', '')}
                id="other_equipments"
                name="otherEquipments"
                onChange={this.onChangeOtherEquipments}
              />
            </div>
          </div>
          <ActionBar
            editMode={this.props.editMode}
            backTo={this.computeBackTo()}
            currentStep="details/supplies"
            onSaveAndContinue={this.onSaveAndContinue}
          />
        </main>
        <ConfirmationModal
          isOpen={this.state.isModalOpen}
          toggle={this.toggle}
          title="No Supplies Selected"
          body="Please confirm no supplies & equipment are provided at this location."
          onSave={this.onSaveModal}
          cancelText="Go Back"
          onCancel={this.toggle}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { post } = state;
  const { locationIdx } = post.navigation;
  const shiftLocation = post.locations[locationIdx];

  return {
    post,
    shiftLocation,
    locationIdx,
    authenticated: state.session.authenticated,
    user: state.session.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveAsDraft: (post, callback) => dispatch(saveAsDraft({ post, callback, from: 'supplies' })),
  onSuppliesChange: (locationIndex, name, value) =>
    dispatch(suppliesFieldChange({ locationIndex, name, value })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Supplies));
