export const NPS_PRODUCTION_IDS = new Set([
  23177, 24607, 28428, 28588, 17448, 31430, 35168, 17345, 28735, 28338, 35515, 34150, 34738, 35686,
  34058, 34449, 28402, 28469, 34881, 36430, 23938, 13521, 32664, 31472, 16889, 36767, 8422, 4759,
  3306, 29477, 25678, 34057, 32092, 11428, 6400, 17865, 30062, 28499, 29040, 29463, 9657, 9158,
  32984, 34904, 34998, 23256, 30463, 35415, 19176, 29371, 33469, 19963, 32048, 36948, 28809, 23031,
  34498, 33491, 31803, 14074, 22571, 22779, 36987, 16426, 35008, 10214, 35331, 34654, 17325, 35619,
  17387, 28965, 7266, 27914, 34496, 17228, 34358, 34926, 16580, 16407, 23620, 7011, 13394, 14430,
  17090, 23189, 23669, 25963, 30979, 19298, 23862, 18586, 19386, 32495, 31167, 36520, 17793, 33562,
  19369, 36746, 36905, 36918, 36981, 37034, 22766, 37013, 33520, 8501, 33479, 15892, 5277, 17332,
  36882, 7138, 18645, 10410, 8382, 33480, 36563, 19047, 17926, 30640, 36542, 35252, 17394, 37012,
  35019, 14104, 36772, 23191, 10261, 19641, 34968, 35064, 14324, 5210, 9599, 10159, 10416, 22606,
  23143, 23804, 27367, 33460, 33936, 20617, 18526, 23879, 8929, 17029, 29222, 29351, 34716, 22433,
  22956, 25736, 31329, 28647, 17097, 34910, 35851, 35708, 36962, 36966, 36994, 36998, 37017, 31294,
  37011, 29845, 32043, 34505, 3339, 35312, 28594, 36984, 24012, 15293, 14073, 30319, 23608, 7289,
  9636, 16704, 7429, 34550, 32763, 26864, 1721, 25431, 3018, 29659, 35652, 16944, 30258, 28995,
  14359, 33676, 34378, 29999, 25578, 34939, 18725, 8740, 31330, 34452, 29286, 34313, 34931, 24014,
  36055, 16752, 19604, 17406, 15103, 36785, 19355, 36819, 6350, 31838, 14810, 17615, 28215, 17449,
  28814, 12892, 17483, 35384, 22954, 35692, 18654, 17486, 28458, 30648, 36147, 34366, 35509, 16533,
  22695, 35759, 4600, 9125, 34568, 26314, 21894, 20200, 29318, 22422, 29803, 24426, 30589, 36251,
  22651, 35024, 25334, 24645, 28518, 29103, 17264, 30055, 30165, 36528, 29882, 36708, 34060, 34631,
  22727, 31161, 23391, 34908, 23950, 29800, 30776, 32181, 36656, 5996, 19072, 25121, 29108, 22842,
  28669, 29804, 23795, 36784, 5024, 5739, 13943, 16973, 22964, 17522, 10109, 34421, 23145, 29176,
  17262, 33478, 34176, 19067, 35958, 23148, 23281, 36752, 36909, 34319, 36326, 24105, 31044, 20154,
  19853, 18378, 36258, 14788, 36194, 33471, 36594, 36920, 17861, 1608, 25614, 30562, 18600, 7302,
  8477, 31619, 25958, 35107, 25049, 18040, 34822, 22700, 31120, 23273, 36547, 36626, 27396, 36757,
  36895, 31799, 6084, 36515, 29775, 35645, 36277, 25244, 34700, 36845, 22346, 18659, 5078, 5362,
  15047, 22884, 27094, 25252, 30066, 35548, 35750, 34791, 12541, 28840, 25929, 24669, 28320, 35385,
  30075, 5288, 36850, 36574, 32776, 36330, 28095, 35147, 24122, 35542, 32407, 32975, 34797, 31331,
  1717, 34202, 16940, 36527, 25047, 22714, 26559, 24344, 29170, 28999, 22058, 14749, 36532, 9472,
  4965, 13695, 8414, 32376, 19717, 29660, 7758, 5608, 13835, 19457, 27087, 31021, 18699, 23142,
  29039, 27656, 36362, 36459, 36677, 36400, 33474, 36143, 34485, 34694, 30980, 36732, 10128, 31135,
  4196, 16623, 20519, 22867, 17503, 33369, 19949, 16771, 29042, 30102, 36793, 36540, 36091, 35444,
  36339, 30205, 36777, 36783, 31391, 31199, 6329, 36688, 34893, 25862, 17095, 35038, 35264, 29918,
  22737, 14902, 24356, 17137, 32389, 34818, 36492, 36537, 31163, 36287, 35412, 36283, 22840, 18531,
  8691, 34668, 24410, 29095, 35557, 34787, 36371, 22675, 5823, 23164, 36566, 34468, 34678, 24705,
  22907, 36657, 36707, 9091, 4845, 2268, 13149, 34871, 34990, 17139, 294, 10412, 25092, 31376,
  16964, 36694, 23543, 36184, 36296, 29649, 22584, 6275, 5308, 31005, 33380, 9295, 36115, 9120,
  34806, 36556, 35919, 36199, 36331, 36466, 36671, 31208, 30056, 23865, 36672, 28344, 20176, 18403,
  35183, 34454, 17456, 3960, 30529, 36673, 29032, 25202, 20226, 21303, 36663, 36631, 29014, 31554,
  36123, 16179, 17497, 3381, 8461, 28975, 36458, 25214, 15211, 18059, 34448, 31029, 35328, 10315,
  26973, 30541, 8777, 30713, 17786, 36557, 24750, 30975, 36402, 15493, 34458, 30870, 31108, 18595,
  36595, 34451, 17353, 30321, 35809, 34823, 36482, 32162, 18579, 31094, 17075, 33526, 30913, 17507,
  23448, 28967, 28736, 5262, 12722, 25916, 36545, 36531, 36552, 18450, 36390, 33666, 34790, 36399,
  33113, 23551, 3027, 36412, 28666, 17315, 30094, 34351, 34674, 35203, 35940, 36232, 31477, 29029,
  27651, 36478, 36479, 27498, 24082, 25418, 16019, 34914, 22630, 30883, 24941, 29265, 36475, 35096,
  19040, 34915, 36053, 15182, 30096, 33344, 18460, 14567, 7225, 30626, 35910, 36276, 19237, 13547,
  24690, 22528, 36449, 24689, 18675, 29035, 33022, 30830, 19172, 28064, 31719, 22117, 30643, 13793,
  36436, 22292, 26564, 36324, 30005, 36003, 3473, 28981, 27140, 28515, 7452, 36136, 36110, 35396,
  28569, 26040, 36403, 30935, 29110, 35225, 34606, 36369, 34469, 34481, 33446, 4964, 10032, 18559,
  25520, 30408, 28348, 18260, 28880, 15619, 17821, 36268, 12811, 20221, 30499, 36281, 24589, 36377,
  36343, 36120, 24407, 28534, 28105, 35276, 31008, 36342, 17054, 26328, 33522, 23140, 33499, 24981,
  19361, 18885, 33472, 36094, 28584, 35974, 35987, 25021, 16938, 33357, 8451, 15022, 15688, 34953,
  31204, 30997, 36259, 31085, 30362, 14502, 30556, 36303, 6239, 20768, 29347, 25804, 26821, 9254,
  18375, 19501, 17611, 36270, 31015, 27818, 36029, 36267, 36306, 23720, 36219, 20170, 28714, 18256,
  26171, 31227, 30185, 30505, 5720, 36113, 36253, 36171, 36105, 35953, 34181, 36279, 36254, 16976,
  18907, 34488, 27090, 30730, 18937, 35879, 36212, 34545, 25885, 18175, 35856, 34899, 29943, 36203,
  35398, 22322, 27804, 23948, 34617, 29287, 34944, 9113, 7267, 17600, 35439, 26582, 29099, 32795,
  28860, 35916, 36119, 18649, 22909, 35908, 33445, 15066, 35379, 15583, 34037, 32415, 35129, 35176,
  35627, 10409, 28433, 34215, 35372, 36070, 35889, 22553, 25398, 13433, 34570, 29513, 36038, 22694,
  15212, 33498, 24786, 29474, 7143, 33475, 35854, 18362, 12972, 33481, 33976, 34288, 29092, 28741,
  34624, 9260, 35206, 19670, 25138, 33882, 29664, 35989, 35381, 28863, 29675, 34445, 10535, 27770,
  28818, 29101, 31669, 36066, 34769, 36039, 34901, 28046, 34776, 4483, 29995, 33490, 31697, 35066,
  35903, 35997, 30099, 29218, 20072, 23147, 26212, 34774, 34820, 30310, 35346, 10053, 32422, 18221,
  32775, 33751, 34666, 27497, 33939, 22692, 34987, 29308, 30105, 34789, 35778, 35149, 29527, 35063,
  35812, 35972, 9203, 13221, 28713, 35316, 34199, 34916, 28431, 28849, 32292, 35504, 35559, 9745,
  29506, 31139, 34567, 17620, 32035, 35939, 35901, 4682, 35753, 20678, 35102, 4581, 21784, 30031,
  35015, 25739, 35886, 29313, 29844, 15490, 16096, 28152, 35508, 34531, 20951, 20457, 5206, 26204,
  35902, 34855, 35442, 29707, 33205, 34166, 35451, 20036, 21359, 27028, 34827, 28700, 28716, 29174,
  35437, 35348, 17658, 32766, 34238, 35766, 17880, 22580, 29013, 17835, 35781, 34491, 28565, 28961,
  22910, 35651, 8838, 28658, 35882, 33746, 35848, 20892, 34527, 35742, 26862, 3824, 2225, 25884,
  35789, 1626, 29636, 35410, 32515, 34019, 35769, 34587, 17568, 33558, 6267, 26018, 30210, 35626,
  15273, 34359, 35674, 29285, 34154, 35811, 34992, 35752, 35773, 33462, 22437, 31671, 33588, 28520,
  33841, 29400, 35180, 35520, 35375, 35747, 35707, 35796, 24041, 15176, 29281, 26413, 23272, 28639,
  35170, 22943, 34283, 29662, 34656, 6528, 35125, 35288, 34600, 32942, 28434, 29172, 35528, 27540,
  29028, 19177, 34722, 15279, 28625, 29798, 34693, 29182, 34866, 35133, 29213, 17695, 32268, 31807,
  23288, 19518, 35179, 22743, 31487, 31571, 22612, 22972, 34819, 35649, 35681, 29445, 34478, 17689,
  22522, 29873, 28867, 14861, 7149, 29830, 29889, 35135, 35268, 35284, 35518, 30865, 28357, 34131,
  25346, 34534, 35705, 35516, 28640, 35172, 17596, 28465, 34697, 35454, 25436, 34474, 35269, 29124,
  34173, 28437, 2458, 16941, 4241, 20109, 28690, 29756, 35208, 35470, 33579, 14759, 33466, 35522,
  35293, 19624, 23227, 29112, 29941, 15299, 35278, 35216, 34848, 35514, 35076, 29663, 32072, 33789,
  19658, 34802, 27004, 26032, 30697, 35213, 18247, 28970, 13342, 29100, 28137, 34582, 35055, 34961,
  31492, 35533, 23321, 30251, 34473, 30670, 32107, 34773, 9127, 16772, 17170, 22824, 20347, 22861,
  25629, 32149, 33961, 34365, 34764, 28501, 34482, 32203, 30942, 17042, 29087, 17383, 25061, 26977,
  34763, 20622, 28719, 23441, 35414, 7300, 34260, 23377, 28035, 26976, 29183, 25857, 28261, 35037,
  23553, 22684, 17917, 23372, 7424, 34894, 35323, 35376, 35222, 4425, 31421, 34160, 35354, 28505,
  23118, 23739, 35295, 34844, 17395, 25451, 23671, 23681, 34686, 35192, 23753, 8607, 28187, 34785,
  35171, 34792, 26119, 35165, 19487, 32157, 35151, 35227, 8337, 34877, 29107, 32770, 23311, 34423,
  17493, 34748, 34909, 34965, 31877, 9096, 17501, 26482, 29729, 34713, 7790, 35169, 29085, 35035,
  35186, 34744, 29046, 34865, 35124, 34394, 17238, 6141, 34882, 23243, 34347, 34980, 16622, 25531,
  26975, 23187, 29002, 34928, 35121, 22069, 28124, 31747, 28848, 29098, 35173, 9185, 28673, 34799,
  34898, 34959, 35219, 4597, 35185, 34854, 35039, 35020, 25507, 35131, 29171, 35181, 34440, 18023,
  26612, 35003, 34594, 19373, 34752, 17654, 35030, 20123, 34583, 35085, 31210, 25562, 28467, 8621,
  35047, 34212, 29223, 32227, 34863, 34436, 34972, 34101, 5231, 17180, 34957, 29009, 31171, 34824,
  30735, 25853, 32936, 25687, 34363, 34864, 14635, 32703, 22581, 28621, 16778, 29718, 34885, 34874,
  26184, 34798, 34930, 14906, 34841, 32377, 34433, 16608, 34198, 34638, 32436, 16985, 33749, 19179,
  19033, 17078, 22419, 24882, 34543, 28799, 14336, 32041, 17182, 34304, 34829, 34750, 28419, 32260,
  34586, 5497, 32546, 28645, 34352, 34598, 20073, 28192, 34536, 21284, 34689, 16804, 33940, 34695,
  27605, 34133, 17720, 34048, 34480, 17222, 23876, 34475, 18732, 2432, 31708, 12568, 34503, 34526,
  31555, 26483, 23010, 6735, 17547, 25351, 34549, 34282, 34528, 17752, 34572, 34168, 34432, 19188,
  7196, 31195, 26985, 19048, 24647, 34056, 7872, 34520, 16157, 28406, 28427, 33962, 34028, 34343,
  22738, 23276, 27427, 33967, 17327, 33463, 34356, 22166, 31985, 33239, 19092, 22307, 28183, 20607,
  33914, 33839, 27724, 13490, 34093, 30144, 33275, 34175, 22715, 33930, 34146, 34254, 20229, 6791,
  14442, 15268, 22858, 28478, 17165, 34164, 21900, 27479, 8029, 33123, 16681, 33664, 33577, 33745,
  34355, 33879, 34054, 34128, 28243, 20828, 26546, 34303, 27077, 16460, 9562, 16600, 34117, 26840,
  33515, 21792, 4030, 27721, 33732, 27761, 3336, 34219, 18448, 26037, 27288, 27608, 33978, 3150,
  6881, 16196, 33768, 6950, 33508, 34138, 16706, 21671, 32585, 21264, 33983, 22430, 27311, 27793,
  34222, 26603, 34103, 22235, 3787, 28230, 22611, 34130, 15982, 16561, 32662, 16002, 33338, 27964,
  27513, 16677, 34099, 21459, 27885, 15976, 17882, 20282, 27712, 33870, 6330, 26796, 34080, 33904,
  33835, 16090, 27858, 14142, 34083, 34017, 33375, 19597, 19787, 25559, 24985, 26422, 27139, 19982,
  33161, 33952, 20534, 27541, 32397, 33866, 7029, 33998, 33845, 33559, 33875, 21838, 27363, 16284,
  32446, 33404, 15444, 20613, 33816, 27873, 32236, 33782, 8118, 31401, 27844, 33858, 26534, 15844,
  33370, 33826, 33871, 24059, 33906, 32003, 33900, 9301, 2457, 9229, 21301, 20350, 27631, 26111,
  33873, 33383, 4184, 32914, 33892, 27224, 30382, 33235, 17425, 32786, 33864, 14686, 33863, 27522,
  33840, 8324, 23680, 27518, 14900, 26407, 5826, 28353, 6849, 8751, 32802, 33809, 32857, 33346,
  10411, 33632, 26993, 33186, 33190, 32933, 33355, 19818, 33668, 16626, 25349, 12967, 33675, 7722,
  33652, 15001, 26704, 33492, 26670, 23747, 32378, 23326, 28752, 33359, 32818, 21922, 32206, 19756,
  2537, 33352, 10262, 33541, 22020, 33207, 19965, 15271, 3164, 33500, 33587, 33201, 28304, 15990,
  20953, 33198, 20152, 17737, 26861, 26815, 27188, 33335, 27013, 31953, 21312, 32929, 31286, 18556,
  29093, 29175, 6221, 20736, 33035, 33244, 15228, 15265, 31969, 21136, 33045, 13956, 25504, 17516,
  20986, 33039, 6146, 25858, 7070, 21300, 32970, 17485, 33148, 33071, 30250, 30277, 32439, 28907,
  26583, 29512, 27091, 33222, 28552, 31377, 20801, 32093, 32888, 20331, 32279, 33038, 31795, 13506,
  8196, 32949, 31612, 321, 8803, 25919, 18466, 33145, 31829, 33069, 9995, 26357, 30325, 31229,
  14984, 23655, 31313, 31864, 19882, 31170, 26174, 27154, 32324, 33094, 33024, 26819, 10370, 30274,
  13451, 32265, 18741, 31788, 8413, 25751, 16959, 10450, 22321, 26638, 32794, 31362, 32782, 14527,
  14435, 19016, 15914, 19607, 6301, 32784, 31935, 32388, 32961, 31849, 25698, 25938, 29816, 31927,
  29116, 32842, 24787, 28202, 31173, 23370, 27805, 32692, 32058, 19967, 31848, 17024, 29503, 32374,
  32679, 32811, 23959, 24120, 19831, 31797, 6159, 26290, 32570, 32563, 19783, 23659, 32774, 26642,
  6676, 21357, 13738, 22740, 31881, 32754, 6363, 22960, 28901, 32156, 20026, 32330, 32277, 26598,
  29006, 23891, 1541, 2460, 17460, 32550, 23176, 30399, 32421, 32652, 32308, 6338, 32105, 29711,
  4260, 20098, 22946, 25453, 26096, 31110, 29370, 15048, 12733, 22813, 32611, 22796, 27874, 28982,
  31213, 32658, 17312, 20276, 20788, 31672, 25386, 29214, 31793, 25586, 32539, 30575, 29310, 30994,
  10470, 7669, 32516, 15049, 25794, 23857, 20374, 29922, 31863, 17216, 23403, 25588, 32566, 31643,
  31750, 32552, 22918, 32524, 29072, 32523, 31186, 23275, 18265, 32143, 28290, 29012, 32158, 19652,
  32068, 17860, 14053, 25505, 25532, 32390, 29895, 28648, 29596, 30591, 27231, 15382, 4652, 18071,
  28911, 32412, 30033, 29521, 32323, 30477, 31778, 26229, 28737, 29840, 32091, 14643, 25148, 30751,
  32280, 26266, 31131, 32282, 8286, 24889, 32281, 32269, 32284, 32270, 32311, 15547, 22820, 18303,
  31464, 32278, 31503, 19145, 32200, 31873, 13301, 8381, 28206, 10017, 19190, 19354, 31118, 31389,
  32086, 19695, 6019, 31399, 15921, 14192, 31821, 19452, 23190, 14039, 7195, 31911, 18594, 8426,
  25740, 29860, 19074, 23628, 26105, 32075, 17236, 19359, 17376, 29064, 31973, 32036, 30172, 19902,
  31398, 22832, 32013, 25181, 31882, 31810, 31504, 20214, 31688, 31972, 17112, 31856, 10299, 31463,
  25317, 22808, 19440, 22395, 26692, 30843, 31826, 19244, 18036, 25889, 30837, 25408, 31723, 31328,
  22629, 31834, 30101, 4076, 31712, 23675, 28744, 31511, 19827, 8057, 31541, 31573, 31456, 15737,
  31088, 31714, 23257, 31729, 16436, 31140, 13415, 5285, 31409, 30780, 17790, 29000, 25355, 23960,
  14240, 19460, 18212, 25128, 28359, 24961, 24522, 31219, 25456, 29113, 24732, 15129, 29915, 30054,
  30807, 31272, 28722, 27499, 31216, 30862, 29119, 9530, 30383, 31282, 29661, 14456, 30292, 29604,
  31033, 30772, 31426, 30885, 28958, 29300, 8778, 28099, 30624, 14438, 31316, 30510, 23003, 27971,
  28892, 30572, 22985, 31288, 31152, 31298, 18357, 24766, 31280, 24875, 31337, 29763, 28294, 24856,
  25068, 30506, 31149, 30029, 26836, 31153, 30365, 30346, 18640, 29273, 30851, 31107, 30509,
]);