// Actions
// ========================================================
export const APPROVE_ATHLETIC_TRAINER = 'go4ellis/Job/APPROVE_ATHLETIC_TRAINER';
export const DECLINE_ATHLETIC_TRAINER = 'go4ellis/Job/DECLINE_ATHLETIC_TRAINER';

export const UPDATE_JOB = 'go4ellis/Job/UPDATE_JOB';
export const UPDATE_JOB_SUCCESS = 'go4ellis/Job/UPDATE_JOB_SUCCESS';
export const UPDATE_JOB_ERROR = 'go4ellis/Job/UPDATE_JOB_ERROR';

export const REMOVE_ATHLETIC_TRAINER = 'go4ellis/Job/REMOVE_ATHLETIC_TRAINER';
export const REMOVE_ATHLETIC_TRAINER_SUCCESS = 'go4ellis/Job/REMOVE_ATHLETIC_TRAINER_SUCCESS';
export const REMOVE_ATHLETIC_TRAINER_ERROR = 'go4ellis/Job/REMOVE_ATHLETIC_TRAINER_ERROR';

export const UPDATE_JOB_V2 = 'go4ellis/Job/UPDATE_JOB_V2';
export const UPDATE_JOB_V2_SUCCESS = 'go4ellis/Job/UPDATE_JOB_V2_SUCCESS';
export const UPDATE_JOB_V2_ERROR = 'go4ellis/Job/UPDATE_JOB_V2_ERROR';

export const APPROVE_JOB_MODIFICATION = 'go4ellis/Job/APPROVE_JOB_MODIFICATION';
export const APPROVE_JOB_MODIFICATION_SUCCESS = 'go4ellis/Job/APPROVE_JOB_MODIFICATION_SUCCESS';
export const APPROVE_JOB_MODIFICATION_ERROR = 'go4ellis/Job/APPROVE_JOB_MODIFICATION_ERROR';

export const DECLINE_JOB_MODIFICATION = 'go4ellis/Job/DECLINE_JOB_MODIFICATION';
export const DECLINE_JOB_MODIFICATION_SUCCESS = 'go4ellis/Job/DECLINE_JOB_MODIFICATION_SUCCESS';
export const DECLINE_JOB_MODIFICATION_ERROR = 'go4ellis/Job/DECLINE_JOB_MODIFICATION_ERROR';

export const GET_JOB = 'go4ellis/Job/GET_JOB';
export const GET_JOB_SUCCESS = 'go4ellis/Job/GET_JOB_SUCCESS';
export const GET_JOB_ERROR = 'go4ellis/Job/GET_JOB_ERROR';

// Reducer Functions
// ========================================================

function approveAthleticTrainerErrorReducer(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}

// Action Creators
// ========================================================
export function approveAthleticTrainer(job) {
  return { type: APPROVE_ATHLETIC_TRAINER, payload: job };
}

export function decline(id) {
  return { type: DECLINE_ATHLETIC_TRAINER, payload: { id } };
}

export function removeJob(id, cancelReason) {
  return { type: REMOVE_ATHLETIC_TRAINER, payload: { id, cancelReason } };
}

export function updateJob(job) {
  return { type: UPDATE_JOB, payload: job };
}

export function updateJobV2(payload) {
  return { type: UPDATE_JOB_V2, payload };
}

export function approveJobModification(job) {
  return { type: APPROVE_JOB_MODIFICATION, payload: job };
}

export function declineJobModification(job) {
  return { type: DECLINE_JOB_MODIFICATION, payload: job };
}

export function getJob(id) {
  return { type: GET_JOB, payload: id };
}

// ========================================================
// Reducer functions
const getJobSuccessReducer = (state, payload) => {
  return { ...state, job: payload };
};

// Reducer
// ========================================================
const DEFAULT_STATE = {};

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case UPDATE_JOB_ERROR:
      return approveAthleticTrainerErrorReducer(state, action);
    case GET_JOB_SUCCESS:
      return getJobSuccessReducer(state, action.payload);

    default:
      return state;
  }
}
