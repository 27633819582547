import React, { useEffect } from 'react'
import PlaidLink from "react-plaid-link";
import { connect } from "react-redux";
import { getPlaidLink } from "redux/ducks/Payment";

const Go4PlaidLink = ({ children, userId, ...props }) => {
  useEffect(() => {
    if (!props.token) props.getPlaidLink(userId);
  }, [])

  if (!props.token) return <>Loading...</>

  return <PlaidLink {...props}>{children}</PlaidLink>;
}

const mapStateToProps = (state, ownProps) => ({
  userId: state.session.currentUser.id,
  token: ownProps.token || state.payment.plaidLink
})

const mapDispatchToProps = dispatch => ({
  getPlaidLink: (id) => dispatch(getPlaidLink({ id })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Go4PlaidLink);

