import React, { Component } from 'react';
import { connect } from 'react-redux';
import { validateForm } from 'redux/ducks/Post';
import { switchLocation, switchStep } from 'redux/ducks/Post/Navigation';
import HorizontalRule from 'components/HorizontalRule';
import { addNewLocation, deleteLocation } from 'redux/ducks/Post/Locations/actions';
import ConfirmationModal from 'components/V2/ConfirmationModal';
import { formattedLocation } from 'helpers/post';
import { trackMixpanelPageView, isAmericanExpress } from 'helpers/post';
import { get, groupBy, sortBy } from 'lodash';
import { changeDetailsField } from 'redux/ducks/Post/Details';
import { AddButton, DeleteButton } from 'components/V2/Buttons';
import DeleteModal from 'components/V2/ConfirmationModal/DeleteModal';
import moment from 'moment';
import { MILITARY_FORMAT } from 'helpers/datetime';
import { shiftStatistics } from 'helpers/dashboard';
import { activeShifts } from 'helpers/dashboard';

class LocationScreen extends Component {
  constructor(props) {
    super(props);
    props.initialize('LocationsIntroScreen');
  }

  state = {
    locationsModal: false,
    deleteModal: false,
  };

  toggleDeleteModal = () =>
    this.setState((prevState) => ({ ...prevState, deleteModal: !this.state.deleteModal }));

  toggleLocationsModal = () => this.setState({ locationsModal: !this.state.locationsModal });

  componentDidMount() {
    const locationsModal = get(this.props, 'location.state.showModal', false);
    this.setState({ locationsModal });

    if (this.props.history.location.pathname.includes('create-event'))
      trackMixpanelPageView('create-event/all-locations', this.props.user);
  }

  switchLocation = (no) => {
    this.props.switchLocation(no - 1);
    this.props.switchStep('location');
  };

  onEditLocation = (idx) => () => {
    this.switchLocation(idx + 1);
    this.props.history.push(`${this.props.baseUrl}/location`);
  };

  onDeleteLocation = (location) => () => {
    this.props.onDeleteLocation({
      locationId: location.id,
      locationIndex: location.idx,
    });
  };

  setLocations = (value) => {
    this.props.onDetailsChange('locationsNo', Math.min(value, 50));
  };

  addLocation = (num) => () => {
    const newLocations = Number(this.props.details.locationsNo || 0) + num;
    this.setLocations(newLocations < 1 ? 1 : newLocations);
    this.props.addNewLocation();
  };

  locationShifts = (locationId) => {
    const shiftsGroupedByLocation = groupBy(this.props.shifts, 'locationId');
    const locationShifts = shiftsGroupedByLocation[locationId] || [];

    return locationShifts.filter((shift) => moment(shift.endTime) > moment());
  };

  render() {
    return (
      <div className="locations-screen">
        <div className="nav-placeholder" />
        <main className="intro">
          <header>
            <div className="screen-title">All Locations</div>
          </header>
          <div className="tournament-title gotham-bold-font">{this.props.profile.title}</div>
          <HorizontalRule margin={0} />
          {this.props.locations.map((location, idx) => (
            <React.Fragment key={idx}>
              <div className="location-item py-2">
                <span className="location-label mr-2 inline-block">LOCATION {idx + 1}</span>
                <span>{formattedLocation(location) || 'Not Started'}</span>
                <div className="actions">
                  <AddButton
                    className="gotham-regular-font edit-btn"
                    onClick={this.onEditLocation(location.idx)}
                  >
                    {location.formattedAddress ? 'Edit' : 'Start'}
                  </AddButton>
                  {this.props.location.pathname.includes('create-event') ? (
                    <DeleteButton
                      show={this.props.locations.length > 1}
                      onClick={this.toggleDeleteModal}
                      className="delete"
                    >
                      Delete
                    </DeleteButton>
                  ) : (
                    <DeleteModal
                      title="Cancel Location"
                      body="Canceling this location will remove all shifts and athletic trainers who applied or confirmed to work."
                      cancelText="Cancel Location"
                      isLocation={true}
                      user={this.props.user}
                      fromEdit={true}
                      onDeleteLocation={this.props.onDeleteLocation}
                      locationId={location.id}
                      event={this.props.event}
                      location={location}
                      activeShifts={activeShifts(this.locationShifts(location.id))}
                      item={
                        sortBy(this.locationShifts(location.id), (shift) =>
                          moment(shift.startTime, MILITARY_FORMAT)
                        )[0]
                      }
                      isAmex={isAmericanExpress(this.props.payment)}
                      rateTypes={this.props.rateTypes}
                      showNote={true}
                    >
                      <p className="delete-button delete" style={{ paddingBottom: 5 }}>
                        CANCEL
                      </p>
                    </DeleteModal>
                  )}

                  <ConfirmationModal
                    title="Delete Location"
                    body="Deleting this location will remove all shifts associated with it."
                    onSave={this.onDeleteLocation(location)}
                    onCancel={this.toggleDeleteModal}
                    confirmText="Delete"
                    cancelText="Go Back"
                    isOpen={this.state.deleteModal}
                    toggle={this.toggleDeleteModal}
                  />
                </div>
              </div>
              {idx !== this.props.locations.length && <HorizontalRule dashed margin={0} />}
            </React.Fragment>
          ))}
          <div>
            <AddButton className="gotham-regular-font add-location" onClick={this.addLocation(1)}>
              Add Location
            </AddButton>
          </div>
        </main>
        <ConfirmationModal
          isOpen={this.state.locationsModal}
          toggle={this.toggleLocationsModal}
          title="Edit Locations"
          body="To remove a location, delete it from this page."
          confirmText="Close"
          onSave={this.toggleLocationsModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.post,
  authenticated: state.session.authenticated,
  user: state.session.currentUser,
  payment: state.payment,
  rateTypes: state.enums.rateTypes,
});

const mapDispatchToProps = (dispatch) => ({
  validateForm: () => dispatch(validateForm('locations')),
  switchLocation: (idx) => dispatch(switchLocation({ locationIdx: idx })),
  switchStep: (step) => dispatch(switchStep(step)),
  onDeleteLocation: (payload) => dispatch(deleteLocation(payload)),
  addNewLocation: () => dispatch(addNewLocation()),
  onDetailsChange: (name, value) => dispatch(changeDetailsField({ name, value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationScreen);
