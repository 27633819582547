import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import {
  createPlaidAccessToken,
  retrieveSource,
  submit,
  updateField,
  updatePlaidToken,
  validateCheckField,
} from 'redux/ducks/Payment';
import NewCreditCardV2 from './NewCreditCardV2';
import * as Sentry from '@sentry/browser';
import HorizontalRule from 'components/HorizontalRule';
import classNames from 'classnames';
import ExistingBankAccount from './ExistingBankAccount';
import ExistingCreditCard from './ExistingCreditCard';
import { getErrorMessage } from 'helpers/payment';
import CustomRadioCheckBox from 'components/V2/CustomInputs/CustomRadioCheckBox';
import Go4PlaidLink from "components/Go4PlaidLink";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const plaid_env = process.env.REACT_APP_PLAID_ENV;

const BillingInfo = ({ payment, account, currentUser, ...props }) => {
  const [isCardChecked, setIsCardChecked] = useState(true);
  const [isBankChecked, setIsBankChecked] = useState(false);
  const [editPaymentMethod, setEditPaymentMethod] = useState(false);
  const [linkingBankAccount, setLinkingBankAccount] = useState(false);

  useEffect(() => {
    if (!account.id) {
      console.log('BillingInfo::props.dispatch(retrieveSource(props.userId));;');
      props.dispatch(retrieveSource(props.userId));
    }
  }, []);

  useEffect(() => {
    if (payment.object === 'card') {
      setIsCardChecked(true);
      setIsBankChecked(false);
    } else if (payment.object === 'bank_account') {
      setIsCardChecked(false);
      setIsBankChecked(true);
    }
  }, [payment.object]);

  useEffect(() => {
    if (payment.validation.check.valid) {
      props.dispatch(submit(currentUser.id, null, payment.check.plaid));
    }
  }, [payment.validation.check.valid]);

  const handleCardChange = () => {
    props.dispatch(updateField('object', 'card'));
  };

  const handleBankChange = () => {
    props.dispatch(updateField('object', 'bank_account'));
    if (!payment.publicToken && payment.source.object !== 'bank_account') {
      document.querySelector('.plaid-link-button').click();
    }
  };

  const onEditPaymentMethod = (e) => {
    e.preventDefault();
    setEditPaymentMethod(true);
  };

  const showCreditCard = () => {
    return editPaymentMethod;
  };

  const handleOnExit = (err, metadata) => {
    console.log('handleOnExit', err, metadata);
    // The user exited the Link flow.
    Sentry.withScope((scope) => {
      scope.setUser({
        id: currentUser.id,
        email: currentUser.email,
        first_name: currentUser.firstName,
        last_name: currentUser.lastName,
      });
      scope.setExtra('err', err);
      scope.setExtra('metadata', metadata);
      Sentry.captureMessage(
        `Plaid onExit() called with non-empty err for user_id=${currentUser.id}`
      );
    });
    if (err != null) {
      // The user encountered a Plaid API error prior to exiting.
      Sentry.withScope((scope) => {
        scope.setUser({
          id: currentUser.id,
          email: currentUser.email,
          first_name: currentUser.firstName,
          last_name: currentUser.lastName,
        });
        scope.setExtra('err', err);
        scope.setExtra('metadata', metadata);
        Sentry.captureMessage(
          `Plaid onExit() called with non-empty err for user_id=${currentUser.id}`
        );
      });
    }
    setLinkingBankAccount(false);
  };

  const handleOnSuccess = (public_token, metadata) => {
    if (metadata.account.verification_status === 'pending_manual_verification') {
      props.createPlaidAccessToken(public_token, metadata.account_id, currentUser.id);
      window.scroll(0, 0);
    } else {
      props.updatePlaidToken(public_token, metadata.account_id);
      props.dispatch(validateCheckField('token'));
    }

    setLinkingBankAccount(false);
  };

  const getPlaidLinkText = () => {
    if (linkingBankAccount) {
      return payment.publicToken ? 'Verifying micro-deposits...' : 'Linking Bank Account...';
    }

    if (payment.publicToken) {
      return 'Bank account added; you’ll need to verify the account with micro deposits.';
    }

    if (account.object === 'bank_account') {
      return 'Your bank account has been connected.';
    }

    return 'Use Plaid to Connect your Bank Account';
  };

  const getPlaidButtonText = () => {
    if (linkingBankAccount) {
      return payment.publicToken ? 'Verifying micro-deposits...' : 'Linking Bank Account...';
    }

    return 'Link Bank Account';
  };

  return (
    <div className="billing-info nimbus-regular-font">
      <div className="card-panel">
        <div className="billing-text">
          <h6 className="gotham-bold-font">VERIFY PAYMENT SOURCE</h6>
          <div className="payment-source-text">
            Go4 requires a verified payment source before listing a job to protect against fraud.
          </div>
        </div>
        <HorizontalRule dashed margin="10px" />
        <div className="card-panel-title gotham-bold-font">
          {!currentUser.lockPaymentMethod && 
            <CustomRadioCheckBox
              id="card"
              name="card"
              checked={isCardChecked}
              onChange={handleCardChange}
            >
              ADD A CREDIT OR DEBIT CARD
            </CustomRadioCheckBox>
          }
          {!currentUser.lockPaymentMethod &&
            <p>Visa, Mastercard, Discover, Amex</p>
          }
        </div>
        <Elements stripe={stripePromise}>
          <div className="card-info">
            <ExistingCreditCard account={account} />
            {!currentUser.lockPaymentMethod && isCardChecked && (!account.id || account.object !== 'card' || showCreditCard()) && (
              <NewCreditCardV2 onSavePayment={() => setEditPaymentMethod(false)} />
            )}
            {!currentUser.lockPaymentMethod && isCardChecked && account.id && account.object === 'card' && !showCreditCard() && (
              <button
                onClick={onEditPaymentMethod}
                className="btn btn-link add-card-btn gotham-regular-font"
                to="/edit-payment"
              >
                + Add a new credit or debit card
              </button>
            )}
            {!!payment.cardError && (
              <div className="flashMessage w-100 mt-3">
                <div className="message error">
                  <span className="message-text">{getErrorMessage(payment.cardError)}</span>
                </div>
              </div>
            )}
          </div>
        </Elements>
        {!currentUser.lockPaymentMethod && <HorizontalRule margin="10px" />}
        <div className="card-panel-title">
          {!currentUser.lockPaymentMethod &&
            <CustomRadioCheckBox
              id="bank_account"
              checked={isBankChecked}
              onChange={handleBankChange}
              labelClassName="gotham-bold-font"
            >
              ADD ACH / BANK ACCT
            </CustomRadioCheckBox>
          }
          {!currentUser.lockPaymentMethod &&
            <p>{getPlaidLinkText()}</p>
          }
          <ExistingBankAccount account={account} />
        </div>
        {!currentUser.lockPaymentMethod &&
          <Elements stripe={stripePromise}>
            {!payment.check.plaid.token && !payment.publicToken && (
              <Go4PlaidLink
                clientName="Go4Ellis"
                env={plaid_env}
                product={['auth']}
                selectAccount={true}
                user={{
                  legalName: currentUser.firstName + ' ' + currentUser.lastName,
                  emailAddress: currentUser.email,
                }}
                onExit={handleOnExit}
                onClick={(event) => event.preventDefault()}
                onSuccess={handleOnSuccess}
                style={{}}
                className={classNames('plaid-link-button gotham-bold-font', {
                  hidden: !isBankChecked,
                })}
              >
                {getPlaidButtonText()}
              </Go4PlaidLink>
            )}
          </Elements>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  post: state.post,
  currentUser: state.session.currentUser,
  payment: state.payment,
  authenticated: state.session.authenticated,
  account: state.payment.source,
  userName: state.session.currentUser.name,
  userId: state.session.currentUser.id,
});

export default connect(mapStateToProps, (dispatch) => ({
  dispatch,
  updatePlaidToken: (token, account_id) => dispatch(updatePlaidToken(token, account_id)),
  createPlaidAccessToken: (token, account_id, id) =>
    dispatch(createPlaidAccessToken(token, account_id, id)),
}))(BillingInfo);
