export default class CredentialSerializer {
  static deserialize(credential) {
    const result = {
      pages: [],
      type: credential.type,
      valid_through: credential.valid_through,
      states_valid: credential.states_valid,
      license_number: credential.license_number,
      role: credential.role,
      license_type: credential.license_type,
      certification: credential.certification,
      approved_on: credential.approved_on,
      end_date: credential.end_date,
      institution: credential.institution,
      specialty_type: credential.specialty_type,
      first_year_certified: credential.first_year_certified,
      apprenticeship_graduate: credential.apprenticeship_graduate,
    };

    for (const page of credential.pages) {
      result['pages'].push({
        pageNumber: page.page_number,
        url: page.url,
      });
    }

    const document = credential.document;
    if (document) {
      result.document = {
        id: document.id,
        url: document.url,
        fileName: document.filename,
        contentType: document.content_type,
      }
    }

    return result;
  }
}
