import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React, { Component, lazy } from 'react';

import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import ReactGA from 'react-ga';

import { connect } from 'react-redux';

import Sidebar from 'react-sidebar';

import { reloadSession } from 'redux/ducks/Session';

import Blog from 'scenes/Blog';
import CareersScene from 'scenes/CareersScene';
import Dashboard from 'scenes/Dashboard';
import MedicalRecordScene from 'scenes/MedicalRecordScene';
import MedicalRecordWaiverScene from 'scenes/MedicalRecordWaiverScene';
import EditPaymentScene from 'scenes/EditPaymentScene';
import EditProfileScene from 'scenes/EditProfileScene';
import EditEventScene from 'scenes/EditEventScene';
import EventDetailsScene from 'scenes/EventDetailsScene';
import EventDraftsScene from 'scenes/EventDraftsScene';
import FinancesScene from 'scenes/FinancesScene';
import ForgotPasswordScene from 'scenes/ForgotPasswordScene';
import HomeScene from 'scenes/HomeScene';
import HomeScene2 from 'scenes/HomeScene2';
import LoginScene from 'scenes/LoginScene';
import Nurses from 'scenes/Nurses';
import ParentLoginScene from 'scenes/ParentLoginScene';
import PatientLoginScene from 'scenes/PatientLoginScene';
import LogoutScene from 'scenes/LogoutScene';
import NotFound from 'scenes/NotFound';
import ResetPasswordScene from 'scenes/ResetPasswordScene';
import VerificationSuccessScene from 'scenes/VerificationSuccessScene';
import ChangePasswordScene from 'scenes/ChangePasswordScene';
import VerificationFailScene from 'scenes/VerificationFailScene';
import ConfirmationScene from 'scenes/ConfirmationScene';
import InvalidResetTokenScene from 'scenes/InvalidResetTokenScene';
import DeeplinkScene from 'scenes/DeeplinkScene';
import V2LoginScene from 'scenes/V2/LoginScene';
import V2ForgotPasswordScene from 'scenes/V2/ForgotPasswordScene';
import V2ResetPasswordScene from 'scenes/V2/ResetPasswordScene';
import ResendConfirmationScene from 'scenes/V2/ResendConfirmationScene';
import V2Footer from 'scenes/V2/Footer';

import FlashMessage from 'components/FlashMessage';
import MobileNavBar from 'components/MobileNavBar';
import NavBar from 'components/NavBar';
import PrivateRoute from 'components/PrivateRoute';
import ParentRoute from 'components/ParentRoute';
import PatientRoute from 'components/PatientRoute';

import withLogoCol from './WithLogoCol';
import ParentDashboard from 'scenes/ParentDashboard';

import 'react-datepicker/dist/react-datepicker.css';

import { NavBar as NavBarV2 } from 'components/V2/NavBar';
import SideNavBar from 'components/V2/SideNavBar/SideNavBar';
import { MobileNavBar as MobileNavBarV2 } from 'components/V2/MobileNavBar';
import { SideBarComponent as SideBarComponentV2 } from 'components/V2/SideBarComponent';
import V2CreateEventScene from 'scenes/V2/CreatePostScene';
import V2EditEventScene from 'scenes/V2/EditPostScene';
import classNames from 'classnames';
import SideBarComponent from 'components/SideBarComponent';
import { isContractEo } from 'helpers/contracts';
import AutoLogout from 'components/AutoLogout';
import InfoBanner from 'components/InfoBanner';
import { trackMixpanelPageView } from 'helpers/post';
import ConfirmationBanner from 'components/ConfirmationBanner';
import { preserveDeepLinks, setDeepLinks } from 'helpers/deeplinks';

const LogoParentLoginScene = withLogoCol(ParentLoginScene);
const LogoPatientLoginScene = withLogoCol(PatientLoginScene);
const LogoLoginScene = withLogoCol(LoginScene);
const LogoResetPasswordScene = withLogoCol(ResetPasswordScene);
const LogoForgotPasswordScene = withLogoCol(ForgotPasswordScene);
const EMRScene = lazy(() => import('scenes/EMRScene'));
const AthleticTrainers = lazy(() => import('scenes/AthleticTrainers'));
const RegisteredNurses = lazy(() => import('scenes/RegisteredNurses'));
const State = lazy(() => import('scenes/State'));
const IndustrialScene = lazy(() => import('scenes/IndustrialScene'));
const CovidScene = lazy(() => import('scenes/CovidScene'));
const JobsStateScene = lazy(() => import('scenes/JobsStateScene'));
const AboutUs = lazy(() => import('scenes/AboutUs'));
const CreateEventScene = lazy(() => import('scenes/CreateEventScene'));
const BigMoney = lazy(() => import('scenes/BigMoney'));
const Upcoming = lazy(() => import('components/V2/EventList/Upcoming'));
const UpcomingShifts = lazy(() => import('components/V2/EventList/UpcomingShifts'));
const Drafts = lazy(() => import('components/V2/EventList/Drafts'));
const UpcomingEventLocations = lazy(() =>
  import('components/V2/EventList/UpcomingEvent/UpcomingEvent')
);
const Finances = lazy(() => import('components/V2/EventList/Finances'));

export class App extends Component {
  state = {
    showLinks: false,
    collapsed: false,
    sidebarOpen: false,
  };

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
      sidebarOpen: !this.state.sidebarOpen,
    });
  };

  componentWillMount() {
    this.props.reloadSession();
  }

  componentDidMount() {
    if (this.props.location.pathname === '/v2/create-event/intro')
      trackMixpanelPageView('create-event/intro', this.props.user);

    this.props.history.listen((location, action) => {
      ReactGA.pageview(location.pathname);
    });

    setDeepLinks(this.props);
  }

  componentDidUpdate(prevProps) {
    preserveDeepLinks(this.props);
  }

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  getV2LogoUrl(user) {
    const ROLE_URLS = {
      Parent: '/parent/dashboard',
      Patient: '/patient/dashboard',
    };

    return ROLE_URLS[user.role] || '/dashboard';
  }

  authenticatedLinks() {
    if (this.props.user.role === 'Parent') {
      return [
        { url: '/parent/dashboard', text: 'Dashboard' },
        { url: '/logout', text: 'Log out' },
      ];
    }
    if (this.props.user.role === 'Patient') {
      return [
        { url: '/patient/dashboard', text: 'Dashboard' },
        { url: '/logout', text: 'Log out' },
      ];
    }
    if (!isContractEo(this.props.user.email)) {
      return [
        { url: '/dashboard', text: 'Dashboard' },
        { url: '/v2/upcoming-events', text: 'Upcoming Jobs', otherUrl: '/upcoming-event' },
        { url: '/v2/event-drafts', text: 'Drafts' },
        { url: '/v2/finances', text: 'Finances' },
        {
          url: '#',
          text: 'Account',
          simple: true,
          links: [
            { url: '/edit-profile', text: 'Edit profile' },
            { url: '/edit-payment', text: 'Edit payment information' },
            { url: '/logout', text: 'Log out' },
          ],
        },
        {
          external: true,
          url: 'https://support.go4.io/hc/en-us/categories/115000299368-Job-Posters/',
          text: 'Help',
        },
      ];
    }
    return [
      { url: '/dashboard', text: 'Dashboard' },
      {
        url: '#',
        text: 'Events',
        links: [
          { url: '/event-details', text: 'Upcoming Shifts' },
          { url: '/event-drafts', text: 'Drafts' },
        ],
      },
      { url: '/finances', text: 'Finances' },
      {
        external: true,
        url: 'https://support.go4.io/hc/en-us/categories/115000299368-Job-Posters/',
        text: 'Help',
      },
      {
        url: '#',
        text: this.props.user['name'],
        thumbnail: this.props.user['profilePic'] || require('styles/BlankAvatar.svg'),
        simple: true,
        links: [
          { url: '/edit-profile', text: 'Edit profile' },
          { url: '/edit-payment', text: 'Edit payment information' },
          { url: '/logout', text: 'Log out' },
        ],
      },
    ];
  }

  unauthenticatedLinks() {
    return [
      {
        url: '/sign-up/role',
        text: 'Sign Up',
        withBackGround: true,
      },
      { url: '/login', text: 'Log In' },
    ];
  }

  bgClass() {
    let result = '';

    const path = this.props.location.pathname;
    if (!this.props.authenticated) {
      if (
        !path.includes('/sign-up/account') &&
        !path.includes('/sign-up/profile') &&
        !path.includes('/sign-up/post-event/account') &&
        !path.includes('/sign-up/post-event/contact') &&
        !path.includes('/sign-up/post-event/profile') &&
        !path.includes('/sign-up/post-event/shift-details') &&
        !path.includes('/sign-up/post-event/password') &&
        !path.includes('/sign-up/contract') &&
        !path.includes('/sign-up/payments') &&
        !path.includes('/v2/create-event')
      ) {
        result = 'login';
      }

      if (
        path.includes('/home') ||
        path.includes('/uscs') ||
        path.includes('/bigmoney') ||
        path.includes('/v2/create-event') ||
        path.includes('/blog') ||
        path.includes('/nurses') ||
        path.includes('/athletic-trainer') ||
        path.includes('/nurse-jobs') ||
        path.includes('/careers') ||
        path.includes('/covid-testing') ||
        path.includes('/michigan') ||
        path.includes('/minnesota') ||
        path.includes('/washington') ||
        path.includes('/jobs') ||
        path.includes('/industrial') ||
        path.includes('/emr') ||
        (!this.props.authenticated && path === '/')
      ) {
        result = 'home';
      }

      if (path.includes('/about')) {
        result = 'about';
      }

      if (path.includes('/sign-up/role/athletic-trainer')) {
        result = 'login';
      }

      if (path === '/') {
        result = 'v2-login';
      }

      if (
        path.includes('/v2/login') ||
        path.includes('/v2/parent/login') ||
        path.includes('/v2/patient/login') ||
        path.includes('/v2/forgot-password') ||
        path.includes('/v2/reset-password') ||
        path.includes('/v2/resend-confirmation')
      ) {
        result = 'v2-login';
      }
    }

    if (path.includes('/deeplink')) {
      result = 'home';
    }

    return result;
  }

  v2Styles() {
    return !isContractEo(this.props.user.email) ? 'v2' : '';
  }

  render() {
    const { pathname } = this.props.location;
    const noNavBar = pathname.includes('/bigmoney');
    return (
      <Sidebar
        touch={false}
        sidebar={
          !isContractEo(this.props.user.email) ? (
            <SideBarComponentV2
              click={() => this.onSetSidebarOpen(false)}
              pathname={pathname}
            ></SideBarComponentV2>
          ) : (
            <SideBarComponent
              click={() => this.onSetSidebarOpen(false)}
              pathname={pathname}
            ></SideBarComponent>
          )
        }
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        styles={{
          sidebar: { background: '#464e55', position: 'fixed', zIndex: 999 },
          root: { overflow: 'initial' },
          content: { overflow: 'initial' },
          overlay: { zIndex: 99 },
        }}
      >
        <div
          className={classNames('app', this.bgClass(), {
            v2: this.v2Styles(),
          })}
        >
          {this.props.authenticated && <AutoLogout />}
          {this.props.authenticated && !this.props.isFirstEvent ? (
            !isContractEo(this.props.user.email) ? (
              <div className="navbars">
                <NavBarV2
                  authenticated={true}
                  logo={require('logo-v2.svg')}
                  logoWidth="100px"
                  links={this.authenticatedLinks()}
                  logoUrl={this.getV2LogoUrl(this.props.user)}
                  isCreateEvent={pathname.includes('/v2/create-event')}
                  isEditEvent={pathname.includes('/v2/events/')}
                  toggleCollapsed={() => this.onSetSidebarOpen(!this.state.sidebarOpen)}
                  pathname={pathname}
                />
                <MobileNavBarV2
                  links={this.authenticatedLinks()}
                  toggleCollapsed={() => this.onSetSidebarOpen(!this.state.sidebarOpen)}
                  logoUrl="/dashboard"
                  logo={require('logo-v2.svg')}
                  logoWidth="100px"
                  authenticated={true}
                  pathname={pathname}
                />
              </div>
            ) : (
              <div className="navbars">
                <NavBar
                  authenticated={true}
                  logo={require('logo-4.svg')}
                  logoWidth="45px"
                  links={this.authenticatedLinks()}
                  logoUrl={this.getV2LogoUrl(this.props.user)}
                />
                <MobileNavBar
                  links={this.authenticatedLinks()}
                  toggleCollapsed={() => this.onSetSidebarOpen(!this.state.sidebarOpen)}
                  logoUrl="/dashboard"
                />
              </div>
            )
          ) : pathname.includes('/bigmoney') ? null : (
            <div className="navbars">
              <NavBarV2
                authenticated={false}
                logo={require('logo-v2.svg')}
                links={this.unauthenticatedLinks()}
                externalUrl="https://go4.io"
                pathname={pathname}
              />
              <MobileNavBarV2
                links={this.unauthenticatedLinks()}
                toggleCollapsed={() => this.onSetSidebarOpen(!this.state.sidebarOpen)}
                logoUrl="/"
                logo={require('logo-v2.svg')}
                logoWidth="100px"
                authenticated={false}
                pathname={pathname}
              />
            </div>
          )}

          <Switch>
            <Route path="/v2/create-event" component={V2CreateEventScene} />
            <Route path="/v2/events/:eventId" component={V2EditEventScene} />

            <React.Fragment>
              <div
                style={{ display: 'flex' }}
                className={classNames('m-0', {
                  'container-fluid row set-width': isContractEo(this.props.user.email),
                })}
              >
                {!isContractEo(this.props.user.email) && this.props.authenticated && (
                  <SideNavBar
                    authenticated={true}
                    logo={require('logo-v2.svg')}
                    logoWidth="100px"
                    links={this.authenticatedLinks()}
                    logoUrl={this.getV2LogoUrl(this.props.user)}
                  />
                )}
                <div
                  className={classNames('route-content', {
                    'set-padding': isContractEo(this.props.user.email),
                  })}
                >
                  <FlashMessage withFixedHeader={!this.props.authenticated} noNavBar={noNavBar} />
                  <InfoBanner history={this.props.history} location={this.props.location} />
                  <ConfirmationBanner />
                  <Switch>
                    {(pathname === '/login' || pathname === '/login/') && (
                      <Redirect to="/v2/login" />
                    )}
                    {(pathname === '/forgot-password' || pathname === '/forgot-password/') && (
                      <Redirect to="/v2/forgot-password" />
                    )}
                    {(pathname === '/parent/login' || pathname === '/parent/login/') && (
                      <Redirect to="/v2/parent/login" />
                    )}
                    {(pathname === '/patient/login' || pathname === '/patient/login/') && (
                      <Redirect to="/v2/patient/login" />
                    )}
                    {(pathname === '/reset-password' || pathname === '/reset-password/') && (
                      <Redirect to="/v2/reset-password" />
                    )}

                    <PrivateRoute path="/dashboard" component={Dashboard} />
                    <PrivateRoute path="/event-details" component={EventDetailsScene} />
                    <PrivateRoute path="/create-event" component={CreateEventScene} />
                    <PrivateRoute path="/edit-profile" component={EditProfileScene} />
                    <PrivateRoute path="/edit-payment" component={EditPaymentScene} />
                    <PrivateRoute path="/events/:eventId" component={EditEventScene} />
                    <PrivateRoute path="/event-drafts" component={EventDraftsScene} />
                    <PrivateRoute path="/finances" component={FinancesScene} />
                    <ParentRoute path="/parent/dashboard" component={ParentDashboard} />
                    <ParentRoute
                      path="/parent/medical-records/:medicalRecordId"
                      component={MedicalRecordScene}
                    />
                    <ParentRoute
                      path="/parent/medical-record-waivers/:medicalRecordWaiverId"
                      component={MedicalRecordWaiverScene}
                    />
                    <PatientRoute path="/patient/dashboard" component={ParentDashboard} />
                    <PatientRoute
                      path="/patient/medical-records/:medicalRecordId"
                      component={MedicalRecordScene}
                    />
                    <PatientRoute
                      path="/patient/medical-record-waivers/:medicalRecordWaiverId"
                      component={MedicalRecordWaiverScene}
                    />
                    <Route path="/blog" component={Blog} />
                    <Route path="/bigmoney" component={BigMoney} />
                    <Route path="/nurses" component={Nurses} />
                    <Route path="/about-us" component={AboutUs} />
                    <Route path="/careers" component={CareersScene} />
                    <Route path="/home" component={HomeScene} />
                    <Route path="/uscs" render={() => <HomeScene2 noPopup isUSCS />} />
                    <Route path="/logout" component={LogoutScene} />
                    <Route path="/login" component={LogoLoginScene} />
                    <Route path="/parent/login" component={LogoParentLoginScene} />
                    <Route path="/patient/login" component={LogoPatientLoginScene} />
                    <Route path="/emr" component={EMRScene} />
                    <Route path="/forgot-password" component={LogoForgotPasswordScene} />
                    <Route path="/reset-password" component={LogoResetPasswordScene} />
                    <Route exact path="/industrial" component={IndustrialScene} />
                    <Route exact path="/covid-testing" component={CovidScene} />
                    <Route exact path="/athletic-trainer" component={AthleticTrainers} />
                    <Route exact path="/nurse-jobs" component={RegisteredNurses} />
                    <Route path="/athletic-trainer/jobs" component={JobsStateScene} />
                    <Redirect
                      from="/api/password/edit"
                      to={{
                        pathname: '/v2/forgot-password',
                        state: { redirected: true },
                      }}
                    />
                    {this.props.authenticated ? (
                      <PrivateRoute exact={true} path="/" component={Dashboard} />
                    ) : (
                      <Route exact path="/" component={V2LoginScene} />
                    )}
                    <Route path="/new-password" component={ChangePasswordScene} />
                    <Route path="/invalid-reset-token" component={InvalidResetTokenScene} />
                    <Route path="/confirmation" component={ConfirmationScene} />
                    <Route path="/verification/success" component={VerificationSuccessScene} />
                    <Route path="/verification/failure" component={VerificationFailScene} />
                    <Route path={['/michigan', '/minnesota', '/washington']} component={State} />
                    <Route path="/deeplink" component={DeeplinkScene} />
                    <Route
                      path="/deeplink-sign-up"
                      component={() => (
                        <DeeplinkScene
                          title="Your Go4 account has been created."
                          subtitle="Next step, text yourself or scan the QR code to download the app."
                          deeplink={`${process.env.REACT_APP_DEEPLINK_URL}/sign-up`}
                        />
                      )}
                    />
                    {/* V2 ROUTES  */}
                    <PrivateRoute path="/v2/event-drafts" component={Drafts} />
                    <PrivateRoute path="/v2/upcoming-events" component={Upcoming} />
                    <PrivateRoute
                      path="/v2/upcoming-event/:eventId/locations"
                      exact
                      component={UpcomingEventLocations}
                    />
                    <PrivateRoute
                      path="/v2/upcoming-event/:eventId/locations/:id"
                      component={UpcomingShifts}
                    />

                    <PrivateRoute path="/v2/finances" component={Finances} />

                    <Route path="/v2/login" component={V2LoginScene} />
                    <Route path="/v2/forgot-password" component={V2ForgotPasswordScene} />
                    <Route
                      path="/v2/parent/login"
                      render={(props) => <V2LoginScene role="Parent" {...props} />}
                    />
                    <Route
                      path="/v2/patient/login"
                      render={(props) => <V2LoginScene role="Patient" {...props} />}
                    />
                    <Route path="/v2/reset-password" component={V2ResetPasswordScene} />
                    <Route path="/v2/resend-confirmation" component={ResendConfirmationScene} />

                    <Route component={NotFound} />
                  </Switch>
                </div>
              </div>
            </React.Fragment>
          </Switch>

          <div className={'v2footers'}>
            <V2Footer />
          </div>
        </div>
      </Sidebar>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.session.authenticated,
    user: state.session.currentUser,
    isFirstEvent: state.post.profile.isFirstEvent,
    currentEventsList: Object.keys(state.event.events.upcoming),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reloadSession: () => dispatch(reloadSession()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
