import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { JobService } from 'services';
import { basicEpic, mappingEpic } from 'redux/ducks/helpers';
import * as actions from './index';

import { GET_UPCOMING_EVENTS, GET_UPCOMING_SHIFTS } from 'redux/ducks/Event';
import { ERROR_FLASH_MESSAGE } from 'redux/ducks/Flash';
import { SHOW_SPINNER, HIDE_SPINNER } from 'redux/ducks/LoadingSpinner';
import { V2JobService } from 'services/v2';
import { Observable } from 'rxjs';
import { GET_ATHLETIC_TRAINER } from 'redux/ducks/User';

// Epics
// ========================================================
export function approveAthleticTrainerEpic(action$, store) {
  return action$.pipe(
    ofType(actions.APPROVE_ATHLETIC_TRAINER),
    map((action) => {
      const id = action.payload.id;
      return {
        type: actions.UPDATE_JOB,
        payload: {
          id,
          state: 'eo_accepted_at',
        },
      };
    })
  );
}

export function declineAthleticTrainerEpic(action$, store) {
  return action$.pipe(
    ofType(actions.DECLINE_ATHLETIC_TRAINER),
    map((action) => {
      const id = action.payload.id;
      return {
        type: actions.UPDATE_JOB,
        payload: {
          id,
          state: 'declined',
        },
      };
    })
  );
}

export function updateJobErrorFlashEpic(action$, store) {
  return action$.pipe(
    ofType(actions.UPDATE_JOB_ERROR, actions.UPDATE_JOB_V2_ERROR),
    map((action) => ({
      type: ERROR_FLASH_MESSAGE,
      payload: 'An error occurred while updating a job.',
    }))
  );
}

export const updateJobErrorReloadEpic = mappingEpic(
  [actions.UPDATE_JOB_ERROR, actions.UPDATE_JOB_V2_ERROR],
  GET_UPCOMING_EVENTS
);

export const updateJobErrorReloadOnShiftsEpic = mappingEpic(
  [actions.UPDATE_JOB_ERROR, actions.UPDATE_JOB_V2_ERROR],
  GET_UPCOMING_SHIFTS
);

export const updateJobEpic = basicEpic(actions.UPDATE_JOB, (job, state) =>
  new JobService(state.session).update(job)
);

export const updateJobV2Epic = basicEpic(actions.UPDATE_JOB_V2, (payload, state) =>
  new V2JobService(state.session).update(payload)
);

export const removeAthleticTrainerEpic = basicEpic(
  actions.REMOVE_ATHLETIC_TRAINER,
  (payload, state) => new JobService(state.session).delete(payload)
);

export const approveModificationEpic = basicEpic(actions.APPROVE_JOB_MODIFICATION, (job, state) =>
  new JobService(state.session).approveModification(job)
);

export const declineModificationEpic = basicEpic(actions.DECLINE_JOB_MODIFICATION, (job, state) =>
  new JobService(state.session).declineModification(job)
);

export const getJobEpic = basicEpic(actions.GET_JOB, (jobId, state) =>
  new JobService(state.session).get(jobId)
);

export const getAthleticTrainerAfterJobEpic = ($action, store) =>
  $action.pipe(
    ofType(actions.GET_JOB_SUCCESS),
    switchMap((action) => {
      const job = action.payload;
      if (job.userId && job.currentState === 'pending')
        return Observable.of({
          type: GET_ATHLETIC_TRAINER,
          payload: job.userId,
        });

      return Observable.empty();
    })
  );

export const showSpinnerEpic = mappingEpic(
  [
    actions.APPROVE_ATHLETIC_TRAINER,
    actions.DECLINE_ATHLETIC_TRAINER,
    actions.UPDATE_JOB,
    actions.UPDATE_JOB_V2,
    actions.APPROVE_JOB_MODIFICATION,
    actions.DECLINE_JOB_MODIFICATION,
  ],
  SHOW_SPINNER
);

export const hideSpinnerEpic = mappingEpic(
  [
    actions.APPROVE_JOB_MODIFICATION_SUCCESS,
    actions.APPROVE_JOB_MODIFICATION_ERROR,
    actions.DECLINE_JOB_MODIFICATION_SUCCESS,
    actions.DECLINE_JOB_MODIFICATION_ERROR,
  ],
  HIDE_SPINNER
);
