import React, { Component } from 'react';
import EventList, { scenes } from '../EventList';
import { connect } from 'react-redux';
import {
  changeUpcomingEventsPage,
  getEventIndexPage,
  getUpcomingEvents,
  resetPagination,
  updateUpcomingFilters,
} from 'redux/ducks/Event';
import { UPCOMING_COLUMNS } from 'helpers/dashboard';
import { values, sortBy } from 'lodash';
import { trackMixpanelPageView } from 'helpers/post';
import { isEmpty } from 'lodash';
import { runPendingDeepLinks } from 'helpers/deeplinks';
import { withRouter } from 'react-router-dom';

class Upcoming extends Component {
  componentDidMount() {
    if (this.props.from !== 'dashboard') trackMixpanelPageView('/upcoming-events', this.props.user);
    if (isEmpty(this.props.events)) {
      this.props.getUpcomingEvents();
    }
    runPendingDeepLinks(this.props);
  }

  onChangePage = (data) => {
    const page = data.selected + 1;
    this.props.onChangePage(page);
  };

  onChangeSort = (key, value) => {
    this.props.updateUpcomingFilters('sort', { [key]: value });
    this.props.getUpcomingEvents();
  };

  reloadData = () => {
    this.props.resetPagination();
    this.props.getUpcomingEvents();
  };

  getSortedData = () => {
    const { events, sort } = this.props;
    const key = Object.keys(sort)[0];
    const value = sort[key];
    if (key === 'title') {
      if (value === 'asc') {
        return sortBy(events, (event) => event.title.toLowerCase());
      }
      return sortBy(events, (event) => event.title.toLowerCase()).reverse();
    }
    if (key === 'date') {
      if (value === 'asc') {
        return sortBy(events, 'nextStartTime');
      }
      return sortBy(events, 'nextStartTime').reverse();
    }
  };

  render() {
    const { sort, searchValue, pagination, events, from } = this.props;
    const data = values(events);
    const sortedData = this.getSortedData() || data;

    const key = Object.keys(sort)[0];
    const value = sort[key];

    if (from === 'dashboard') {
      const upcomingColumns = {
        ...UPCOMING_COLUMNS,
        jobName: {
          ...UPCOMING_COLUMNS.jobName,
          name: 'JOB NAME',
        },
      };
      return (
        <EventList
          title="Upcoming Jobs"
          onChangeSearch={(value) => this.props.updateUpcomingFilters('title', value)}
          searchValue={searchValue}
          data={sortedData}
          reloadData={this.reloadData}
          pagination={pagination}
          onChangePage={this.onChangePage}
          column={scenes.UPCOMING}
          columns={upcomingColumns}
          emptyMessage="dashboardUpcomingJobs"
          sort={{ key, value }}
          onChangeSort={this.onChangeSort}
          loaded={this.props.loaded}
        />
      );
    }

    return (
      <div className="dashboardV2 upcoming">
        <div className="header">
          <div className="col-md-12">
            <h1>Upcoming Jobs</h1>
          </div>
        </div>
        <div>
          <EventList
            title="Upcoming Jobs"
            onChangeSearch={(value) => this.props.updateUpcomingFilters('title', value)}
            searchValue={searchValue}
            data={sortedData}
            reloadData={this.reloadData}
            pagination={pagination}
            onChangePage={this.onChangePage}
            column={scenes.UPCOMING}
            columns={UPCOMING_COLUMNS}
            emptyMessage="There are no upcoming jobs to display."
            sort={{ key, value }}
            onChangeSort={this.onChangeSort}
            loaded={this.props.loaded}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  events: state.event.events.upcoming,
  searchValue: state.event.filters.upcoming.title,
  pagination: state.event.events.upcomingPagination,
  sort: state.event.filters.upcoming.sort,
  user: state.session.currentUser,
  loaded: state.event.loaded.upcoming,
});

const mapDispatchToProps = (dispatch) => ({
  getUpcomingEvents: (params) => dispatch(getUpcomingEvents(params)),
  updateUpcomingFilters: (filter, value) => dispatch(updateUpcomingFilters(filter, value)),
  onChangePage: (page) => dispatch(changeUpcomingEventsPage(page)),
  resetPagination: () => dispatch(resetPagination()),
  getEventIndexPage: (eventId) => dispatch(getEventIndexPage(eventId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Upcoming));
