import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActionBar from '../ActionBar';
import { addShift, removeMultipleShifts, removeShift } from 'redux/ducks/Post/Shifts/actions';
import moment from 'moment-timezone';
import AddShiftModal from '../AddShiftModal';
import { saveAsDraft, saveLocalForm } from 'redux/ducks/Post/actions';
import NonTournamentSummary from './components/NonTournamentSummary';
import { trackMixpanelPageView, getTimezone } from 'helpers/post';
import { get } from 'lodash';
import { switchLocation } from 'redux/ducks/Post/Navigation';
import { AddButton } from 'components/V2/Buttons';
import ToolTip from 'scenes/V2/ToolTip';

class ScheduleSummary extends Component {
  state = { addShiftModalOpen: false, lastAddedShiftIdx: null };
  toggleAddShiftModal = () => this.setState({ addShiftModalOpen: !this.state.addShiftModalOpen });

  componentDidMount() {
    const { locIdx, editMode } = this.props;
    if (!this.props.editMode)
      trackMixpanelPageView('create-event/schedule-summary', this.props.user);

    this.setState({ addShiftModalOpen: false });

    if (editMode && !!locIdx) this.props.switchLocation(locIdx);
  }

  componentDidUpdate(prevProps) {
    const { shiftIdx } = this.props;
    if (shiftIdx < 0 && prevProps.shiftIdx !== shiftIdx) return;

    setTimeout(() => {
      const el = document.getElementById(`s-${shiftIdx}`);
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, 500);
  }

  onAddShift = () => {
    const { id, idx, address, rateTypeId, payRate } = this.props.shiftLocation;
    const { lastShiftIdx } = this.props;
    this.setState({
      lastAddedShiftIdx: Number(lastShiftIdx) + 1,
      addShiftModalOpen: true,
    });
    this.props.addShift({ locationId: id || idx, zipCode: address.zipCode, rateTypeId, payRate });
  };

  render() {
    const { shifts, incompleteNewLocation, shiftLocation } = this.props;
    const { details } = this.props.post;
    const newShift = shifts.find((s) => s.idx === this.state.lastAddedShiftIdx);
    const editMode = this.props.editMode;
    const timezone = getTimezone(shiftLocation);

    return (
      <div className="schedule-summary">
        <NonTournamentSummary
          shifts={shifts}
          editMode={editMode && !incompleteNewLocation}
          rateTypes={this.props.rateTypes}
          onDeleteShift={this.props.onDeleteShift}
          history={this.props.history}
          baseUrl={this.props.baseUrl}
          details={details}
          timezone={timezone}
        />
        {editMode && !incompleteNewLocation && !shifts[0]?.isMultiDay && (
          <div className="row m-0 mt-3">
            <AddButton onClick={this.onAddShift}>ADD ANOTHER SHIFT</AddButton>
            <ToolTip
              id="add-another-shift"
              top="2px"
              bodyText="Select this option to add an additional time or date. With this option, ATs can apply to each individual shift. This option allows for an hourly rate or fixed pay rate."
            />
          </div>
        )}
        <AddShiftModal
          key={`create-shift`}
          shift={newShift}
          shiftCount={shifts.length}
          isOpen={this.state.addShiftModalOpen}
          toggle={this.toggleAddShiftModal}
          onCancel={() => this.props.onDeleteShift(newShift.idx)}
          timezone={timezone}
        />
      </div>
    );
  }
}

class ScheduleSummaryWithActions extends Component {
  constructor(props) {
    super(props);
    props.initialize('ScheduleSummary');
  }

  onSaveAndContinue = () => {
    const { post, editMode, baseUrl } = this.props;
    const callback = () => {
      if (editMode) this.props.history.push(`${baseUrl}/rate`);
      else this.props.history.push(`${baseUrl}/details/supplies`);
    };

    if (editMode) {
      this.props.saveAsDraft(post, callback);
      return;
    }

    callback();
  };

  computeBackTo = () => {
    const { baseUrl, editMode } = this.props;
    if (editMode) return `${baseUrl}/location`;
    return `${baseUrl}/rate`;
  };

  render() {
    return (
      <div className="schedule-summary-with-actions">
        <div className="nav-placeholder" />
        <main>
          <header>
            <div className="screen-title">Schedule Summary</div>
          </header>
          <ScheduleSummary {...this.props} />
          <ActionBar
            nextDisabled={() => false}
            editMode={this.props.editMode}
            backTo={this.computeBackTo()}
            currentStep="schedule-summary"
            onSaveAndContinue={this.onSaveAndContinue}
          />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { post } = state;
  const { locationIdx } = post.navigation;
  const shiftLocation = post.locations[locationIdx];
  const lastShiftIdx = get(post.shifts, `[${post.shifts.length - 1}].idx`);
  const shifts = post.shifts
    .filter((s) => !s.cancelled && [shiftLocation.idx, shiftLocation.id].includes(s.locationId))
    .sort((a, b) => moment(a.startDate).diff(moment(b.startDate)));

  const shiftId = get(ownProps, `location.state.shiftId`, 0);
  const locationId = state.post.shifts.find((s) => s.id === shiftId)?.locationId;
  const locIdx = state.post.locations.find((l) => l.id === locationId)?.idx;
  const shiftIdx = shifts.findIndex((s) => s.id === shiftId);

  return {
    locIdx,
    shiftIdx,
    post,
    shifts,
    shiftLocation,
    rateTypes: state.enums.rateTypes,
    lastShiftIdx,
    user: state.session.currentUser,
    eventName: state.post.profile.title,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onDeleteShift: (idx: number, reason) => dispatch(removeShift({ index: idx, reason })),
  addShift: (payload) => dispatch(addShift(payload)),
  saveAsDraft: (post, callback) => dispatch(saveAsDraft({ post, callback, from: 'summary' })),
  removeMultipleShifts: (indexes) => dispatch(removeMultipleShifts({ indexes })),
  switchLocation: (idx) => dispatch(switchLocation({ locationIdx: idx })),
  saveLocalForm: () => dispatch(saveLocalForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleSummaryWithActions);
