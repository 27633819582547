import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputOrFlatpickr from 'components/InputOrFlatpickr';
import { rateTypeNameFinder } from 'helpers/rateTypeName';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { updateJobV2 } from 'redux/ducks/Job';
import {
  computeEnd,
  computeEndDate,
  computeMultiDayEnd,
  computeMultiDayEndDate,
  computeMultiDayStart,
  computeMultiDayStartDate,
  computeStart,
  computeStartDate,
} from 'redux/ducks/Post/Shifts';
import { hour24ToMeridiem, meridiemTo24, MILITARY_FORMAT, shiftDateFormat } from 'helpers/datetime';
import {
  checkIsMultiDayShift,
  DATE_PICKER_OPTIONS,
  TIME_PICKER_OPTIONS,
  shiftDuration,
} from 'helpers/post';
import classNames from 'classnames';
import { addErrorsToJob } from 'redux/ducks/Post/Shifts/validators';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import CustomRadioCheckBox from '../CustomInputs/CustomRadioCheckBox';
import { get, sortBy, groupBy, map } from 'lodash';
import { shiftStatistics } from 'helpers/dashboard';
import LongShiftAlert from 'scenes/V2/LongShiftAlert';

class EditJobModal extends Component {
  state = {
    wasValidated: true,
    job: {
      errorMessages: {},
    },
  };

  componentDidMount() {
    let job = addErrorsToJob(this.props.job);

    this.setState((prevState) => ({
      ...prevState,
      job,
      newLocation: this.props.location,
    }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.job.payRate !== this.props.job.payRate) {
      let job = addErrorsToJob(this.props.job);
      this.setState((prevState) => ({
        ...prevState,
        job,
        newLocation: this.props.location,
      }));
    }
  }

  onConfirm = () => {
    let { job } = this.state;
    const { location } = this.props;
    const newShift = this.props.shifts.find(({ id }) => id.toString() === job.shiftId);
    this.props.updateJob(job, location, newShift);
    this.toggle();
  };

  toggle = () => {
    this.props.toggleEditJob();
  };

  renderEndDate = () => {
    const { job } = this.state;
    if (!this.isMultiDay(job)) return null;

    return (
      <div className="form-group col-md-6">
        <label htmlFor="endDate">End Date</label>
        <div className="input-group">
          <InputOrFlatpickr
            type="datetime"
            name="endDate"
            value={job.endDate}
            options={DATE_PICKER_OPTIONS}
            onChange={(value) => this.onChangeJob('endDate', value)}
            className={classNames('form-control modal-input nimbus-regular-font', {
              'is-invalid': this.showError('endDate'),
            })}
          />
          <div className="input-group-append">
            <span
              className="input-group-text rbr cursor-pointer"
              onClick={() => {
                const input = document.querySelector(`input[name="endDate"]`);
                if (input) input.focus();
              }}
            >
              <i className="far fa-calendar-alt"></i>
            </span>
          </div>
          <div className="invalid-feedback">{job.errorMessages['endDate']}</div>
        </div>
      </div>
    );
  };

  isMultiDay = (job) => {
    if (!job) return false;

    return checkIsMultiDayShift({
      startTime: moment(job?.startTime, MILITARY_FORMAT).format(),
      endTime: moment(job?.endTime, MILITARY_FORMAT).format(),
    });
  };

  onChangeJob = (name, value) => {
    let jobCopy = { ...this.state.job };
    const isMultiDay = this.isMultiDay(jobCopy);

    const processedValue = ['start', 'end'].includes(name) ? meridiemTo24(value) : value;

    const fieldComputations = {
      start: isMultiDay ? computeMultiDayStart : computeStart,
      end: isMultiDay ? computeMultiDayEnd : computeEnd,
      startDate: isMultiDay ? computeMultiDayStartDate : computeStartDate,
      endDate: isMultiDay ? computeMultiDayEndDate : computeEndDate,
    };

    if (fieldComputations[name]) {
      fieldComputations[name](jobCopy, processedValue);
    }

    jobCopy[name] = processedValue;

    if (name === 'isMultiDay') {
      jobCopy.endDate = value ? (jobCopy.rateTypeId = '1') : jobCopy.startDate;
      jobCopy.endTime = jobCopy.startDate + ' ' + jobCopy.end;
    }

    jobCopy = addErrorsToJob(jobCopy, this.props.job);

    this.setState((prev) => ({
      ...prev,
      job: {
        ...jobCopy,
        startTime: jobCopy.startDate + ' ' + jobCopy.start,
        endTime: jobCopy.endDate + ' ' + jobCopy.end,
      },
    }));
  };

  showError = (fieldName: string) => {
    const { job, wasValidated } = this.props;
    return wasValidated && !!job.errorMessages[fieldName];
  };

  formIsValid = () => {
    const { job, newLocation } = this.state;
    if (
      newLocation?.id !== this.props.shift.eventLocation.id &&
      (job.shiftId === this.props.job.shiftId || !job.shiftId)
    )
      return false;

    return Object.values(job.errorMessages).every(isEmpty);
  };

  onChangeLocation = (e) => {
    this.setState({
      ...this.state,
      newLocation: this.props.locations.find((l) => l.id.toString() === e.target.value),
      job: {
        ...this.state.job,
        shiftId: e.target.value === this.props.location.id.toString() ? this.props.shift.id : '',
      },
    });
  };

  onChangeJobShift = (e) => {
    if (this.state.newShift && this.state.newShift.id.toString() === e.target.value) return;

    this.onChangeJob('shiftId', e.target.value);
  };

  shiftDisabled = (shift) => {
    const statistics = shiftStatistics(Object.values(shift.jobs.byId));
    const { capacityFilled } = statistics;
    const shiftCapacity = get(shift, 'shiftCapacity', 1);

    return moment(shift.startTime).isBefore(moment()) || capacityFilled === shiftCapacity;
  };

  render() {
    const { user, locations } = this.props;
    const { job } = this.state;

    return (
      <React.Fragment>
        {this.props.children && (
          <button
            className={`modal-button plain-button action-button ${this.props.childTextColor}`}
            onClick={this.toggle}
          >
            {this.props.children}
          </button>
        )}
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.toggle}
          className="edit-job-modal"
          backdrop="static"
        >
          <ModalBody>
            <div className="modalTitle">
              <div className="screen-title">
                {`Edit Job / ${user.name}`}{' '}
                <span className="text-secondary hrs">
                  {shiftDuration({ ...job, isMultiDay: this.isMultiDay(job) })}h
                </span>
              </div>
              <p className="edit-job-tip">
                {'Changes will only apply to this athletic trainer’s shift.'}
              </p>
            </div>
            <div className="inputs">
              <div id="shiftModalForm">
                <div className="row">
                  <div
                    className={classNames('form-group', {
                      'col-md-7': !this.isMultiDay(job),
                      'col-md-6': this.isMultiDay(job),
                    })}
                  >
                    <label htmlFor="startDate">Start Date</label>
                    <div className="input-group">
                      <InputOrFlatpickr
                        type="datetime"
                        name="startDate"
                        value={job.startDate}
                        options={DATE_PICKER_OPTIONS}
                        onChange={(value) => this.onChangeJob('startDate', value)}
                        className={classNames('form-control modal-input date', {
                          'is-invalid': this.showError('startDate'),
                          'disabled': this.props.job.currentState === 'checked_in',
                        })}
                        disabled={this.props.job.currentState === 'checked_in'}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text rbr cursor-pointer"
                          onClick={() => {
                            const input = document.querySelector(`input[name="startDate"]`);
                            if (input) input.focus();
                          }}
                        >
                          <i className="far fa-calendar-alt"></i>
                        </span>
                      </div>
                      <div className="invalid-feedback">{job.errorMessages['startDate']}</div>
                    </div>
                  </div>
                  {this.renderEndDate()}
                </div>
                <div className="row">
                  <div className="form-group col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="start">Start Time</label>
                        <div className="input-group">
                          <InputOrFlatpickr
                            name="start"
                            value={hour24ToMeridiem(job.start)}
                            options={TIME_PICKER_OPTIONS}
                            type="datetime"
                            onValueUpdate={(value) => this.onChangeJob('start', value)}
                            className={classNames('form-control modal-input', {
                              'is-invalid': this.showError('start'),
                            })}
                          />
                          <div className="invalid-feedback">{job.errorMessages['start']}</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="end">End Time</label>
                        <div className="input-group">
                          <InputOrFlatpickr
                            name="end"
                            value={hour24ToMeridiem(job.end)}
                            options={TIME_PICKER_OPTIONS}
                            type="datetime"
                            onValueUpdate={(value) => this.onChangeJob('end', value)}
                            className={classNames('form-control modal-input', {
                              'is-invalid': this.showError('end'),
                            })}
                          />
                          <div className="invalid-feedback">{job.errorMessages['end']}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {Number(shiftDuration({ ...job, isMultiDay: this.isMultiDay(job) })) > 8 && (
                  <div className="row">
                    <div className="form-group col-md-12 mt-1">
                      <LongShiftAlert />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="form-group col-md-12">
                    <label htmlFor="rate_type">Rate Type</label>
                    <div className="d-flex flex-row">
                      <CustomRadioCheckBox
                        id="HourlyRateType"
                        name="rate_type"
                        value="0"
                        checked={String(job.rateTypeId) === '0'}
                        onChange={(e) => this.onChangeJob('rateTypeId', e.target.value)}
                        labelClassName={`font-weight-bold`}
                        disabled={this.isMultiDay(job)}
                        modalVerticalAlign
                      >
                        HOURLY RATE
                      </CustomRadioCheckBox>
                      <CustomRadioCheckBox
                        id="GameRateType"
                        name="rate_type"
                        value="1"
                        checked={String(job.rateTypeId) === '1'}
                        onChange={(e) => this.onChangeJob('rateTypeId', e.target.value)}
                        labelClassName="font-weight-bold"
                        modalVerticalAlign
                      >
                        FIXED RATE
                      </CustomRadioCheckBox>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7">
                    <label htmlFor="pay_rate">Rate</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                      </div>
                      <input
                        className={classNames('form-control', {
                          'is-invalid': this.showError('payRate'),
                        })}
                        type="number"
                        name="pay_rate"
                        min="1"
                        max="100000"
                        step="1"
                        aria-label="Amount (to the nearest dollar)"
                        value={job.payRate}
                        placeholder="0"
                        onChange={(e) => this.onChangeJob('payRate', e.target.value)}
                        required
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {rateTypeNameFinder(this.props.rateTypes, job.rateTypeId)}
                        </span>
                      </div>
                      <div className="invalid-feedback-displayed">
                        {job.errorMessages['payRate']}
                      </div>
                    </div>
                  </div>
                </div>
                {locations.length > 1 && (
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <label htmlFor="location">Location</label>
                      <select
                        className="form-control custom-select"
                        name="location"
                        value={this.state.newLocation?.id || ''}
                        onChange={this.onChangeLocation}
                      >
                        {locations.map((loc) => (
                          <option value={loc.id} key={loc.name}>
                            {loc.name} - {loc.address.city} {loc.address.state},{' '}
                            {loc.address.zipCode}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
                {this.state.newLocation &&
                  this.state.newLocation.id !== this.props.shift.eventLocation?.id && (
                    <div className="row">
                      <div className="col-md-12">
                        <label htmlFor="location">Shift Date</label>
                        <select
                          className="form-control custom-select"
                          name="shiftId"
                          value={this.state.job.shiftId}
                          onChange={this.onChangeJobShift}
                        >
                          <option value="" disabled>
                            Choose Shift
                          </option>
                          {this.props.shifts
                            .filter(
                              (shift) => shift.eventLocation.id === this.state.newLocation?.id
                            )
                            .map((shift) => (
                              <option
                                value={shift.id}
                                key={shift.id}
                                disabled={this.shiftDisabled(shift)}
                              >
                                {shiftDateFormat(shift)}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className={"pb-3 change-info"}>
              {user.firstName} {user.lastName} will be automatically notified about these changes.
            </div>
          </ModalBody>
          <ModalFooter className="actions">
            <button className="btn confirm-btn" onClick={this.toggle}>
              CANCEL
            </button>
            <button
              className={classNames('btn btn-success', {
                disabled: !this.formIsValid(),
              })}
              disabled={!this.formIsValid()}
              onClick={this.onConfirm}
            >
              SAVE
            </button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateJob: (job, location, newShift) => dispatch(updateJobV2({ job, location, newShift })),
});

const mapStateToProps = (state, ownProps) => {
  const events = Object.values(state.event.events.upcoming);
  const findEvent = events.find(({ shifts }) => shifts.ids.includes(ownProps.shift.id));
  const shifts = sortBy(Object.values(get(findEvent, 'shifts.byId', {}), 'id'));
  const shiftsGroupedByLocation = groupBy(shifts, 'eventLocation.id');
  const locations = sortBy(map(shiftsGroupedByLocation, '[0].eventLocation'), (l) =>
    get(shiftsGroupedByLocation[l.id], '[0].id')
  );

  return {
    rateTypes: state.enums.rateTypes,
    locations: locations,
    shifts,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditJobModal);
