import React, { Component } from 'react';
import classNames from 'classnames';
import ConfirmationModal from 'components/V2/ConfirmationModal';
import moment from 'moment-timezone';
import { getTimezone, isAmericanExpress } from 'helpers/post';
import { get } from 'lodash';
import { Collapse } from 'reactstrap';
import { formatTableDateTz, hour24ToMeridiem } from 'helpers/datetime';
import { formatCurrency } from 'helpers/dashboard';
import { getEstimatedFee } from 'helpers/getEstimatedFee';
import { rateTypeNameFinder } from 'helpers/rateTypeName';
import { optionsForEnum, JOB_CANCELLATION_REASONS } from 'helpers/enums';
import { isEmpty } from 'lodash';
import { isWeatherCancelReason } from 'helpers/dashboard';
import { computePenalty, totalHours } from 'helpers/cancellations';

class CancelJobModal extends Component {
  state = {
    error: {
      cancelReason: '',
      otherReason: '',
    },
    cancelReason: '',
    otherReason: '',
  };
  setError = (error) => {
    this.setState({ error });
  };
  setCancelReason = (cancelReason) => {
    this.setState({ cancelReason });
  };
  setOtherReason = (otherReason) => {
    this.setState({ otherReason });
  };

  onClick = (callback) => {
    const { error, cancelReason, otherReason } = this.state;
    const { job, onRemove } = this.props;
    if (isEmpty(cancelReason)) {
      this.setError({ ...error, cancelReason: 'This field is required' });
      return;
    }

    if (['Other'].includes(cancelReason) && isEmpty(otherReason)) {
      this.setError({ ...error, otherReason: 'This field is required' });
      return;
    }

    onRemove(job.id, [cancelReason, otherReason].filter(Boolean).join(` - `));
  };

  hasError = () => {
    const { cancelReason, otherReason } = this.state;
    return isEmpty(cancelReason) || (['Other'].includes(cancelReason) && isEmpty(otherReason));
  };

  diffInHours = () => {
    const { job } = this.props;
    return moment(job.startTime).diff(moment(), 'hours');
  };

  computeCancellationFee = () => {
    const { job, shift, currentUser, rateTypes } = this.props;
    const { startTime } = job;
    const tz = getTimezone(shift.eventLocation);
    const rateType = rateTypeNameFinder(rateTypes, job.rateTypeId);
    const cancellationPenalty = computePenalty(job, tz, rateType);
    const { penaltyAmount } = cancellationPenalty;
    const totalCancellationFee =
      rateType === 'hr' ? get(job, 'payRate', 0) * totalHours(job) : get(job, 'payRate', 0);
    const fee = getEstimatedFee(startTime, 0, isAmericanExpress(this.props.payment)) / 100;

    if (penaltyAmount === 0 && currentUser.tier !== 'Tier 1') {
      return parseFloat(fee * totalCancellationFee);
    }

    return parseFloat((fee * penaltyAmount + penaltyAmount).toFixed(2));
  };

  moreThan18HoursLeft = () => {
    const { job, shift } = this.props;
    const tz = getTimezone(shift.eventLocation);
    const diffInHours = moment.tz(job.startTime, tz).diff(moment(), 'hours');
    return diffInHours >= 18;
  };

  onChange = (e) => {
    this.setCancelReason(e.target.value);
    this.setOtherReason('');
    this.setError({
      cancelReason: '',
      otherReason: '',
    });
  };

  onChangeOtherReason = (e) => {
    this.setOtherReason(e.target.value);
    this.setError({
      cancelReason: '',
      otherReason: '',
    });
  };

  renderCancellationReason = () => {
    const { cancelReason, error, otherReason } = this.state;
    const cancellationReasons = this.moreThan18HoursLeft()
      ? JOB_CANCELLATION_REASONS.filter((reason) => !isWeatherCancelReason(reason.id))
      : JOB_CANCELLATION_REASONS;
    return (
      <React.Fragment>
        <div className="form-group pl-0 my-2">
          <label>
            Reason<span className="text-danger">*</span>
          </label>
          <select
            className={`form-control rounded-select custom-select ${cancelReason ? '' : 'text-secondary'
              }`}
            name="reason"
            value={cancelReason}
            onChange={this.onChange}
            required
          >
            {optionsForEnum(cancellationReasons, 'Select a reason')}
          </select>
          {error.cancelReason && <div className="text-danger mt-1 w-100">{error.cancelReason}</div>}
          {cancelReason === 'Other' && (
            <div className="form-group pl-0 mt-2 mb-0">
              <div className="mt-3">
                <input
                  type="text"
                  className="form-control"
                  name="otherReason"
                  value={otherReason}
                  onChange={this.onChangeOtherReason}
                  required
                />
              </div>

              {error.otherReason && (
                <div className="text-danger mt-1 w-100">{error.otherReason}</div>
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  render() {
    const {
      job,
      shift,
      currentUser,
      openCancelModal,
      toggleCancelModal,
      collapseEstimatedFee,
      toggleEstimatedFeeModal,
      rateTypes,
    } = this.props;
    const { user } = job;
    const { cancelReason } = this.state;
    const tz = getTimezone(shift.eventLocation);
    const showEstimatedChargeFee = job.currentState === 'confirmed' && job.showCancellationPolicy;
    const notTier1 = !(currentUser.tier === 'Tier 1' && this.diffInHours() >= 73);
    const rateType = rateTypeNameFinder(rateTypes, job.rateTypeId);
    const { penaltyReason } = computePenalty(job, tz, rateType)

    return (
      <ConfirmationModal
        hasError={this.hasError}
        isOpen={openCancelModal}
        toggle={toggleCancelModal}
        title="Confirm Cancellation"
        htmlBody={true}
        body={
          <React.Fragment>
            <p>{`Are you sure you want to cancel ${user.firstName} ${user.lastName} from working this shift?`}</p>
            {this.renderCancellationReason()}
            {notTier1 && showEstimatedChargeFee &&
              this.computeCancellationFee() !== 0 &&
              cancelReason && !isWeatherCancelReason(cancelReason) && (
                <React.Fragment>
                  <p style={{ marginTop: 10 }}>
                    <span style={{ fontWeight: 'bold', color: 'red' }}>Warning:</span>
                    {` If you remove this athletic trainer you will be charged per the `}
                    <a
                      href="https://www.go4.io/go4-marketplace-standards/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Go4 Cancellation Policy
                    </a>
                    .
                  </p>
                  <div className="estimated-fee">
                    <div onClick={toggleEstimatedFeeModal} className="estimated-jobs">
                      <p className={classNames(`m-0`)}>
                        {collapseEstimatedFee && <i className="material-icons">expand_more</i>}
                        {!collapseEstimatedFee && <i className="material-icons">chevron_right</i>}
                        View Estimated Fee
                      </p>
                    </div>
                    <Collapse
                      isOpen={collapseEstimatedFee}
                      style={{
                        width: '100%',
                        textAlign: 'left',
                      }}
                    >
                      <React.Fragment>
                        <div className="confirmed-at">
                          <p className="at-name">{job.user.name}</p>
                          <p className="job-date">
                            {`${formatTableDateTz(job.startTime, tz)} | ${hour24ToMeridiem(
                              job.start,
                              { space: false }
                            )} - ${hour24ToMeridiem(job.end, { space: false })}`}
                          </p>
                          <p className="cancellation-fee">
                            Cancellation Fee:{' '}
                            {formatCurrency({
                              currency: this.computeCancellationFee(),
                              dollarSign: true,
                            })}
                          </p>
                          <p className="cancellation-reason">{penaltyReason}</p>
                        </div>
                      </React.Fragment>
                    </Collapse>
                  </div>
                </React.Fragment>
              )}
            {notTier1 && !showEstimatedChargeFee && (
              <p style={{ marginTop: 10 }}>
                <span style={{ fontWeight: 'bold', color: 'red' }}>Notice:</span>
                {` Starting Jan. 1, 2025, canceling a confirmed athletic trainer will result in charges under Go4’s new Cancellation Policy. `}
                <a
                  href="https://www.go4.io/go4-marketplace-standards/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read more here.
                </a>
              </p>
            )}
          </React.Fragment>
        }
        onSave={(callback) => this.onClick(callback)}
        cancelText="Go Back"
        confirmText="Cancel Job"
        onCancel={toggleCancelModal}
      />
    );
  }
}

export default CancelJobModal;
