import React, { Component } from 'react';
import ActionBar from '../ActionBar';
import EventProfile from './EventProfile';
import { connect } from 'react-redux';
import { changeProfileField } from 'redux/ducks/Post/Profile';
import { saveAsDraft } from 'redux/ducks/Post/actions';
import { addMultipleLocations, changeLocationField } from 'redux/ducks/Post/Locations/actions';
import { validateForm } from 'redux/ducks/Post';
import { changeDetailsField } from 'redux/ducks/Post/Details';
import { Route } from 'react-router-dom';
import { profileValid, getBlobFromUrl } from 'helpers/post';
import { get } from 'lodash';
import { switchLocation } from 'redux/ducks/Post/Navigation';
import { getSettingDetailsName, getSettingName } from 'helpers/enums';

class ProfileScreen extends Component {
  constructor(props) {
    super(props);
    props.initialize('EventProfile');
  }

  componentDidMount() {
    // this is a corner case when dealing with duplicate documents
    const { locations } = this.props.post;
    for (const location of locations) {
      if (location.id && location.existingDocuments.some(({ id }) => !id)) {
        const newDocs = location.existingDocuments.filter(({ id }) => !id);
        newDocs.forEach((document) => {
          getBlobFromUrl(document.url).then((blob) => {
            const newFile = new File([blob], document.filename);
            this.props.changeLocationField(
              location.idx,
              'documents',
              location.documents.concat([newFile])
            );
          });
        });
      }
    }
  }

  pageIsValid() {
    const { post, user } = this.props;
    const { profile } = post;

    return profileValid(profile, user);
  }

  redirectTo = (path, props) => this.props.history.push(path, props);

  onSaveAndContinue = () => {
    const { baseUrl, locationIdx } = this.props;
    const { details, locations } = this.props.post;
    const locationId = get(locations, `[${locationIdx}].id`);

    const callback = () => {

      if (details.locationsNo > 1 && !locationId) {
        this.redirectTo(`${baseUrl}/location-setup`);
        return;
      }

      if (details.locationsNo > locations.length) {
        this.props.switchLocation(locations.length);
        this.redirectTo(`${baseUrl}/location-setup`);
        return;
      }

      if (locationIdx >= details.locationsNo) this.props.switchLocation(0);

      this.redirectTo(`${baseUrl}/location`);
    };

    if (!this.pageIsValid()) {
      this.props.validateForm();
      return;
    }

    if (locations.length > details.locationsNo) {
      this.props.onDetailsChange('locationsNo', locations.length);
      this.redirectTo(`${baseUrl}/all-locations`, { showModal: true });
      return;
    }

    if (!this.props.authenticated) {
      this.props.addMultipleLocations(details.locationsNo);
      callback();
      return;
    }

    this.props.saveProfile(this.props.post, callback);
  };

  getScreenTitle = () => {
    const { profile } = this.props.post;
    const setting = getSettingName(profile.eventSettingId);
    const settingDetail = getSettingDetailsName(profile.settingDetailId);

    if (setting !== 'Sports') return 'Profile';

    return `${settingDetail} Profile`;
  };

  render() {
    return (
      <div className="eventProfileWithActions">
        <div className="nav-placeholder" />
        <main>
          <header>
            <div className="screen-title">{this.getScreenTitle()}</div>
          </header>
          <Route
            path={this.props.match.url}
            render={() => (
              <EventProfile
                onChangeProfile={this.props.onChangeProfile}
                onDetailsChange={this.props.onDetailsChange}
                editMode={this.props.editMode}
              />
            )}
            exact
          />
          <ActionBar
            editMode={this.props.editMode}
            currentStep="profile"
            backTo={`${this.props.baseUrl}/setting-detail`}
            onSaveAndContinue={this.onSaveAndContinue}
            nextDisabled={() => !this.pageIsValid()}
          />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  post: state.post,
  enums: state.enums,
  authenticated: state.session.authenticated,
  locationIdx: state.post.navigation.locationIdx,
  user: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeProfile: (name, value) => dispatch(changeProfileField({ name, value })),
  onDetailsChange: (name, value) => dispatch(changeDetailsField({ name, value })),
  saveProfile: (post, callback) => dispatch(saveAsDraft({ post, callback, from: 'profile' })),
  validateForm: () => dispatch(validateForm('profile')),
  addMultipleLocations: (locationsNumber) => dispatch(addMultipleLocations({ locationsNumber })),
  changeLocationField: (idx, name, value) => dispatch(changeLocationField({ name, value, idx })),
  switchLocation: (idx) => dispatch(switchLocation({ locationIdx: idx })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);
