// Upcoming Events - after login
export const runPendingDeepLinks = (props) => {
  const urlSearchString = props.location.search;
  const params = new URLSearchParams(urlSearchString);
  const eventId = params.get('event');

  props.getEventIndexPage(eventId);
};

// App - at component update
export const preserveDeepLinks = (props) => {
  if (props.location.pathname === '/v2/upcoming-event') return;

  const params = new URLSearchParams(props.location.search);
  const eventId = params.get('event');
  const jobId = params.get('job');

  if (
    Number.isInteger(parseInt(jobId)) &&
    Number.isInteger(parseInt(eventId)) &&
    props.currentEventsList.includes(eventId) &&
    props.authenticated
  ) {
    props.history.push(`/v2/upcoming-event/${eventId}/locations/all?job=${jobId}`);
    localStorage.removeItem('event');
    localStorage.removeItem('job');
    return;
  }

  if (!params.has('job')) {
    if (!setParamsFromLocalStorage(params)) return;

    props.history.replace({ search: params.toString() });
  }
};

// App - at component mount
export const setDeepLinks = (props) => {
  const params = new URLSearchParams(props.location.search);
  if (params.get('job')) localStorage.setItem('job', params.get('job'));
  if (params.get('event')) localStorage.setItem('event', params.get('event'));
};

// Session - at login
export const createWhiteList = () => {
  const KEYS = ['job', 'event'];

  return KEYS.reduce((acc, key) => {
    const value = localStorage.getItem(key);
    if (value && value !== 'null') acc[key] = value;
    return acc;
  }, {});
};

const setParamsFromLocalStorage = (params) => {
  let flag = false;
  if (!params.has('job') && localStorage.getItem('job') && localStorage.getItem('job') !== 'null') {
    params.set('job', localStorage.getItem('job'));
    flag = true;
  }
  if (
    !params.has('event') &&
    localStorage.getItem('event') &&
    localStorage.getItem('event') !== 'null'
  ) {
    params.set('event', localStorage.getItem('event'));
    flag = true;
  }
  return flag;
};
