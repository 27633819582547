import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
const FeedbackBox = ({ index, user, onChangeScore }) => {
  return (
    <input
      value={index}
      onChange={onChangeScore}
      checked={user.NPSScore === index && user.NPSScore !== null}
      type="checkbox"
      className="score-box"
      id={`npsScore-${user.NPSScore}`}
    />
  );
};

const NetPromoterScoreModal = ({ isOpen, user, updateUser, ...props }) => {
  const scoreArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const [userCopy, setUserCopy] = useState(user);

  const onChangeScore = (e) => {
    setUserCopy({
      ...userCopy,
      NPSScore: Number(e.target.value),
    });
  };

  const onChangeAdditionalFeedback = (event) => {
    setUserCopy({
      ...userCopy,
      NPSAdditionalFeedback: event.target.value,
    });
  };

  const onSubmit = () => {
    updateUser(userCopy);
  };

  const onCloseModal = () => {
    updateUser({
      ...user,
      NPSAdditionalFeedback: 'NPS unanswered, popup closed',
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      className="netPromoterScoreModal"
      style={{ minWidth: '50vw' }}
      backdrop="static"
      toggle={onCloseModal}
    >
      <ModalHeader toggle={onCloseModal}></ModalHeader>
      <ModalBody className={'px-4 py-3 bg-white'}>
        <main>
          <div className="question1">
            <p className="question-title">
              How likely are you to recommend Go4 to a friend or coworker?
            </p>
            <div className="options">
              <div className="group-score">
                <p className="help-text">Extremely unlikely</p>
                <FeedbackBox index={0} onChangeScore={onChangeScore} user={userCopy} />
              </div>
              {scoreArray.map((item) => {
                return <FeedbackBox index={item} onChangeScore={onChangeScore} user={userCopy} />;
              })}
              <div className="group-score">
                <FeedbackBox index={10} onChangeScore={onChangeScore} user={userCopy} />
                <p className="help-text">Extremely likely</p>
              </div>
            </div>
          </div>

          <div className="question2">
            <label htmlFor="nps-additional-feedback" className="question-title">
              Do you have any other feedback for us?
            </label>
            <textarea
              className="form-control"
              style={{ width: '100%', minHeight: '60px' }}
              type="textarea"
              name="nps-additional-feedback"
              id="nps-additional-feedback"
              value={userCopy.NPSAdditionalFeedback}
              rows={3}
              onChange={onChangeAdditionalFeedback}
            />
          </div>
        </main>
      </ModalBody>
      <ModalFooter>
        <button
          className="btn submit-button"
          onClick={onSubmit}
          disabled={userCopy.NPSScore === null}
        >
          SUBMIT
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default NetPromoterScoreModal;
