import InputOrFlatpickr from 'components/InputOrFlatpickr';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import {
  computeEnd,
  computeEndDate,
  computeMultiDayEnd,
  computeMultiDayEndDate,
  computeMultiDayStart,
  computeMultiDayStartDate,
  computeStart,
  computeStartDate,
} from 'redux/ducks/Post/Shifts';
import { changeShift } from 'redux/ducks/Post/Shifts/actions';
import { isEmpty, values, get } from 'lodash';
import { rateTypeNameFinder } from 'helpers/rateTypeName';
import { DATE_PICKER_OPTIONS, TIME_PICKER_OPTIONS, shiftDuration, getTimezone } from 'helpers/post';
import classNames from 'classnames';
import { addErrorsToShift } from 'redux/ducks/Post/Shifts/validators';
import { hour24ToMeridiem, meridiemTo24 } from 'helpers/datetime';
import { AddButton } from 'components/V2/Buttons';
import ConfirmationModal from 'components/V2/ConfirmationModal';
import ToolTip from 'scenes/V2/ToolTip';
import CustomRadioCheckBox from 'components/V2/CustomInputs/CustomRadioCheckBox';
import CustomCheckBox from 'components/V2/CustomInputs/CustomCheckBox';
import LongShiftAlert from 'scenes/V2/LongShiftAlert';

class EditShiftModal extends Component {
  state = {
    open: false,
    shift: { idx: 0, errorMessages: {}, start: '', end: '', isMultiDay: false },
    warningModal: false,
    isRateIncreaseModalOpen: false,
  };

  onChangeShift = (name, value) => {
    let shiftCopy = { ...this.state.shift };
    const { isMultiDay } = shiftCopy;
    const processedValue = ['start', 'end'].includes(name) ? meridiemTo24(value) : value;

    const fieldComputations = {
      start: isMultiDay ? computeMultiDayStart : computeStart,
      end: isMultiDay ? computeMultiDayEnd : computeEnd,
      startDate: isMultiDay ? computeMultiDayStartDate : computeStartDate,
      endDate: isMultiDay ? computeMultiDayEndDate : computeEndDate,
    };

    if (fieldComputations[name]) {
      fieldComputations[name](shiftCopy, processedValue);
    }

    shiftCopy[name] = processedValue;

    if (name === 'isMultiDay') {
      shiftCopy.endDate = value ? (shiftCopy.rateTypeId = '1') : shiftCopy.startDate;
      shiftCopy.endTime = shiftCopy.startDate + ' ' + shiftCopy.end;
    }

    shiftCopy = addErrorsToShift({ shift: shiftCopy, validateRate: true });

    this.setState((prev) => ({
      ...prev,
      shift: {
        ...shiftCopy,
        startTime: shiftCopy.startDate + ' ' + shiftCopy.start,
        endTime: shiftCopy.endDate + ' ' + shiftCopy.end,
      },
    }));
  };

  formIsValid = () => {
    return Object.values(this.state.shift.errorMessages).every(isEmpty);
  };

  updateShift = () => {
    if (!this.formIsValid()) return;

    const { shift } = this.state;

    this.toggle();
    if (!!this.showRateIncreaseModal()) {
      this.toggleRateIncreaseModal();
      return;
    }
    this.props.onChangeShift(shift);
  };

  toggle = () => {
    const { shift } = this.props;
    if (!this.state.open) {
      const updatedShift = addErrorsToShift({ shift });
      this.setState({ ...this.state, shift: updatedShift, open: !this.state.open });
      return;
    }
    this.setState({ open: !this.state.open });
  };

  toggleRateIncreaseModal = () =>
    this.setState({ isRateIncreaseModalOpen: !this.state.isRateIncreaseModalOpen });

  showRateIncreaseModal = () => {
    const { shift } = this.state;
    const jobs = Array.isArray(shift.jobs)
      ? shift.jobs
      : Object.values(get(shift, 'jobs.byId', {}));
    const jobsWithApplicants = jobs?.filter((job) =>
      ['pending', 'waitlist', 'eo_accepted_at', 'confirmed', 'checked_in'].includes(
        job.currentState
      )
    );
    const hasApplicants = jobsWithApplicants.length > 0;
    const rateIncreased = !!shift.updateJobsPayRate
      ? shift.updateJobsPayRate < Number(shift.payRate)
      : jobsWithApplicants.every((job) => job.payRate < Number(shift.payRate));
    const rateTypeChanged = jobsWithApplicants.some(
      (job) => job.rateTypeId !== Number(shift.rateTypeId)
    );

    return (
      !!hasApplicants && !!rateIncreased && !rateTypeChanged && !this.state.isRateIncreaseModalOpen
    );
  };

  showError = (name) => {
    return !!this.state.shift.errorMessages[name];
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      warningModal: !prevState.warningModal,
    }));
  };

  onRedirectRemoveAT = () => {
    const { shift } = this.state;
    const { eventId, locations } = this.props;
    const location = locations.find((loc) => loc.id === shift.locationId);

    this.props.history.push({
      pathname: `/v2/upcoming-event/${eventId}/locations/${shift.locationId}`,
      state: {
        shiftId: shift.id,
        locationIdx: location.idx,
        from: 'reduceCapacity',
      },
    });
  };

  add = (num) => () => {
    const { shift } = this.state;
    const { capacity, jobs } = shift;
    const targetJobs = ['confirmed', 'checked_in', 'eo_accepted_at', 'paid'];
    const shiftJobs = Array.isArray(jobs) ? jobs : values(jobs?.byId);
    const filterJobs = shiftJobs.filter((job) => targetJobs.includes(job.currentState));
    const newCapacity = Number(capacity) + num;

    if (filterJobs.length > newCapacity && newCapacity >= 1) {
      this.toggleModal();
    } else {
      this.onChangeShift('capacity', newCapacity < 1 ? 1 : newCapacity);
    }
  };

  renderStartDate = () => {
    const { shift } = this.state;
    return (
      <div
        className={classNames('form-group', {
          'col-md-7': !shift.isMultiDay,
          'col-md-6': shift.isMultiDay,
        })}
      >
        <label htmlFor="startDate">{shift.isMultiDay ? 'Start Date' : 'Shift Date'}</label>
        <div className="input-group">
          <InputOrFlatpickr
            type="datetime"
            name="startDate"
            value={shift.startDate}
            options={DATE_PICKER_OPTIONS}
            onChange={(value) => this.onChangeShift('startDate', value)}
            className={classNames('form-control modal-input nimbus-regular-font', {
              'is-invalid': this.showError('startDate'),
            })}
          />
          <div className="input-group-append">
            <span
              className="input-group-text rbr cursor-pointer"
              onClick={() => {
                const input = document.querySelector(`input[name="startDate"]`);
                if (input) input.focus();
              }}
            >
              <i className="far fa-calendar-alt"></i>
            </span>
          </div>
          <div className="invalid-feedback">{shift.errorMessages['startDate']}</div>
        </div>
      </div>
    );
  };

  renderEndDate = () => {
    const { shift } = this.state;
    if (!shift.isMultiDay) return null;

    return (
      <div className="form-group col-md-6">
        <label htmlFor="endDate">End Date</label>
        <div className="input-group">
          <InputOrFlatpickr
            type="datetime"
            name="endDate"
            value={shift.endDate}
            options={DATE_PICKER_OPTIONS}
            onChange={(value) => this.onChangeShift('endDate', value)}
            className={classNames('form-control modal-input nimbus-regular-font', {
              'is-invalid': this.showError('endDate'),
            })}
          />
          <div className="input-group-append">
            <span
              className="input-group-text rbr cursor-pointer"
              onClick={() => {
                const input = document.querySelector(`input[name="endDate"]`);
                if (input) input.focus();
              }}
            >
              <i className="far fa-calendar-alt"></i>
            </span>
          </div>
          <div className="invalid-feedback">{shift.errorMessages['endDate']}</div>
        </div>
      </div>
    );
  };

  toggleIsMultiDay = () => {
    this.onChangeShift('isMultiDay', !this.state.shift.isMultiDay);
  };

  renderMultiDayToggler = () => {
    const { shift } = this.state;
    const { shiftCount } = this.props;
    if (shiftCount > 1) return null;

    return (
      <div className="row multi-day">
        <div className={classNames(`form-group col-md-12`)}>
          <CustomCheckBox
            id={`multi-day-shift-${shift.idx}`}
            name={`multi-day-shift-${shift.idx}`}
            checked={shift.isMultiDay}
            onClick={this.toggleIsMultiDay}
            className="gotham-regular-font"
          >
            Combine Multiple Days into One Shift
            <ToolTip
              id="multi-day-shift-icon"
              top="3px"
              bodyText="Select this option to combine several days into a single shift, requiring ATs to work all included days. Note that this option only allows for a fixed pay rate across all days and does not allow hourly rates."
            />
          </CustomCheckBox>
        </div>
      </div>
    );
  };

  render() {
    const { shift } = this.state;
    const { locations } = this.props;
    const location = locations.find((loc) => loc.id === shift.locationId);
    const timezone = getTimezone(location);

    return (
      <React.Fragment>
        <AddButton show={!!this.props.children} onClick={this.toggle}>
          {this.props.children}
        </AddButton>
        <Modal
          isOpen={this.state.open}
          toggle={this.toggle}
          className="edit-shift-modal"
          backdrop="static"
        >
          <ModalBody style={{ backgroundColor: 'white' }}>
            <div className="modalTitle">
              <div className="screen-title">Shift {shift.idx + 1}</div>
              <span className="text-secondary hrs">{shiftDuration(shift)}h</span>
            </div>
            <div
              className={classNames('inputs', {
                'bottom-padding': !this.props.editMode,
              })}
            >
              <div id="shiftModalForm">
                {this.renderMultiDayToggler()}
                <div className="row">
                  {this.renderStartDate()}
                  {this.renderEndDate()}
                </div>
                <div className="row">
                  <div className="form-group col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="start">Start Time</label>
                        <div className="input-group">
                          <InputOrFlatpickr
                            name="start"
                            value={hour24ToMeridiem(shift.start)}
                            options={TIME_PICKER_OPTIONS}
                            type="datetime"
                            onValueUpdate={(value) => this.onChangeShift('start', value)}
                            className={classNames('form-control modal-input nimbus-regular-font', {
                              'is-invalid': this.showError('start'),
                            })}
                            timezone={timezone}
                          />
                          <div className="invalid-feedback">{shift.errorMessages['start']}</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="end">End Time</label>
                        <div className="input-group">
                          <InputOrFlatpickr
                            name="end"
                            value={hour24ToMeridiem(shift.end)}
                            options={TIME_PICKER_OPTIONS}
                            type="datetime"
                            onValueUpdate={(value) => this.onChangeShift('end', value)}
                            className={classNames('form-control modal-input nimbus-regular-font', {
                              'is-invalid': this.showError('end'),
                            })}
                            timezone={timezone}
                          />
                          <div className="invalid-feedback">{shift.errorMessages['end']}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {Number(shiftDuration(shift)) > 8 && shift.start && shift.end && (
                  <div className="row">
                    <div className="form-group col-md-12 mt-1">
                      <LongShiftAlert />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="form-group col-md-12 ">
                    <label htmlFor="capacity">
                      How many Athletic Trainers do you need for this shift?
                    </label>
                    <div className="input-group col-md-6 p-0">
                      <div className="input-group-append">
                        <span
                          type="button"
                          onClick={this.add(-1)}
                          className="input-group-text lbr plus-minus remove-border-r"
                        >
                          <i className="fas fa-minus"></i>
                        </span>
                      </div>
                      <input
                        type="number"
                        name="capacity"
                        value={shift.capacity}
                        onChange={(e) => this.onChangeShift('capacity', e.target.value)}
                        className={classNames('form-control modal-input', {
                          'is-invalid': this.showError('capacity'),
                        })}
                        placeholder="1"
                        min={1}
                      />
                      <div className="input-group-append">
                        <span
                          type="button"
                          onClick={this.add(1)}
                          className="input-group-text rbr plus-minus"
                        >
                          <i className="fas fa-plus"></i>
                        </span>
                      </div>
                      <div className="invalid-feedback">{shift.errorMessages['capacity']}</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12">
                    <label htmlFor="rate_type">Rate Type</label>
                    <div className="d-flex flex-row">
                      <CustomRadioCheckBox
                        id="HourlyRateType"
                        name="rate_type"
                        value="0"
                        checked={shift.rateTypeId === '0'}
                        onChange={(e) => this.onChangeShift('rateTypeId', e.target.value)}
                        labelClassName="font-weight-bold"
                        disabled={shift.isMultiDay}
                        modalVerticalAlign
                      >
                        HOURLY RATE
                      </CustomRadioCheckBox>
                      <CustomRadioCheckBox
                        id="GameRateType"
                        name="rate_type"
                        value="1"
                        checked={shift.rateTypeId === '1'}
                        onChange={(e) => this.onChangeShift('rateTypeId', e.target.value)}
                        labelClassName="font-weight-bold"
                        modalVerticalAlign
                      >
                        FIXED RATE
                      </CustomRadioCheckBox>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7">
                    <label htmlFor="pay_rate">Rate</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                      </div>
                      <input
                        className={classNames('form-control', {
                          'is-invalid': this.showError('payRate'),
                        })}
                        type="number"
                        name="pay_rate"
                        min="1"
                        max="100000"
                        step="1"
                        aria-label="Amount (to the nearest dollar)"
                        value={shift.payRate}
                        placeholder="0"
                        onChange={(e) => this.onChangeShift('payRate', e.target.value)}
                        required
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {rateTypeNameFinder(this.props.rateTypes, shift.rateTypeId)}
                        </span>
                      </div>
                      <div className="invalid-feedback">{shift.errorMessages['payRate']}</div>
                    </div>
                  </div>
                </div>
                {this.props.editMode && (
                  <div className="note-text">
                    <p>
                      <span className="note">Note:</span> In order to save your changes you need to
                      update the job.
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="modalActions">
              <div className="actions">
                <Button
                  className={classNames('btn btn-success', {
                    disabled: !this.formIsValid(),
                  })}
                  block
                  disabled={!this.formIsValid()}
                  size="sm"
                  color="success"
                  onClick={this.updateShift}
                >
                  SAVE
                </Button>
                <button className="cancel-btn" onClick={this.toggle}>
                  CANCEL
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <ConfirmationModal
          isOpen={this.state.warningModal}
          toggle={this.toggleModal}
          title="Action Required"
          body="You must remove ATs before reducing the # of ATs needed. Your changes are not saved."
          confirmText="Remove ATs"
          onSave={this.onRedirectRemoveAT}
          canCancel
        />
        <ConfirmationModal
          isOpen={this.state.isRateIncreaseModalOpen}
          toggle={this.toggleRateIncreaseModal}
          title="Pay Rate Increase"
          body="Do you want to increase the pay for all shifts in this job, including those with applicants?"
          onSave={() => this.props.onChangeShift(shift, Number(this.state.shift.payRate))}
          cancelText={
            <React.Fragment>
              <div>No, future</div>
              <div>applicants only</div>
            </React.Fragment>
          }
          confirmText={
            <React.Fragment>
              <div>Yes,</div>
              <div>all applicants</div>
            </React.Fragment>
          }
          onCancel={() => this.props.onChangeShift(shift)}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  rateTypes: state.enums.rateTypes,
  eventId: state.post.details.id,
  locations: state.post.locations,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChangeShift: (shift, updateJobsPayRate) =>
    dispatch(changeShift({ index: shift.idx, shift, updateJobsPayRate })),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditShiftModal);
