import moment from 'moment-timezone';
import { get, isEmpty } from 'lodash';

export const isCancellationFee = (job) => {
  return moment(job.cancelledAt).add(72, 'hours').isBefore(moment(job.startTime));
};

export const isCancellationPayment = (job) => {
  return moment(job.startTime).isBetween(
    moment(job.cancelledAt),
    moment(job.cancelledAt).add(72, 'hours')
  );
};

export const computePenalty = (job, tz, rateType) => {
  const createdAt = new Date().toLocaleString('en-US', { timeZone: tz });
  const startTime = job.startTime;
  const totalPay =
    rateType === 'hr' ? get(job, 'payRate', 0) * totalHours(job) : get(job, 'payRate', 0);
  let penaltyReason = '';
  let penaltyAmount = 0;

  if (is50PercentPenalty(createdAt, startTime, tz)) {
    penaltyAmount = apply50percentagePerJob(totalPay);
    penaltyReason =
      'Canceling a confirmed AT within 24 hours of the start time incurs a 50% fee of the shift cost.';
  } else if (is25PercentPenalty(createdAt, startTime, tz)) {
    penaltyAmount = apply25percentagePerJob(totalPay);
    penaltyReason =
      'Canceling a confirmed AT 72-25 hours before the start time incurs a 25% fee of the shift cost.';
  } else if (noPercentPenalty(createdAt, startTime, tz)) {
    penaltyAmount = 0;
    penaltyReason =
      'Canceling a confirmed AT 73+ hours before the start time incurs the shift’s Service Fees.';
  }

  return {
    penaltyAmount,
    penaltyReason,
  };
};

export const apply50percentagePerJob = (payableAmount) => {
  if (!payableAmount) return 0;

  return payableAmount * 0.5;
};

export const apply25percentagePerJob = (payableAmount) => {
  if (!payableAmount) return 0;

  return payableAmount * 0.25;
};

export const totalHours = (job) => {
  if (isEmpty(job) || !job.startTime || !job.endTime) return 0;

  const startTime = moment(job.startTime);
  const endTime = moment(job.endTime);
  return moment.duration(endTime.diff(startTime)).as('hours');
};

export const is50PercentPenalty = (createdAt, startTime, tz) => {
  const josStarted = moment.tz(moment(), tz).isAfter(moment.tz(startTime, tz));
  const within24Hours = moment
    .tz(startTime, tz)
    .isBetween(moment.tz(createdAt, tz), moment.tz(createdAt, tz).add(24, 'hours'));

  return josStarted || within24Hours;
};

export const is25PercentPenalty = (createdAt, startTime, tz) => {
  return moment
    .tz(startTime, tz)
    .isBetween(
      moment.tz(createdAt, tz).add(24, 'hours'),
      moment.tz(createdAt, tz).add(72, 'hours')
    );
};

export const noPercentPenalty = (createdAt, startTime, tz) => {
  return moment.tz(createdAt, tz).add(72, 'hours').isBefore(moment.tz(startTime, tz));
};
