// @flow
// Types
// ========================================================
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';

import type { Action } from 'redux/ducks/helpers';

export type User = {
  id: number,
  email: string,
  firstName: string,
  lastName: string,
  name: string,
  url: string,
};

export type SessionStateType = {
  currentUser: ?User,
  accessToken: ?string,
  tokenType: ?string,
  expiry: ?string,
  client: ?string,
  uid: ?string,
  authenticated: boolean,
  error: any,
};

// Actions
// ========================================================
export const CREATE_SESSION = 'go4ellisMobile/session/CREATE_SESSION';
export const CREATE_SESSION_SUCCESS = 'go4ellisMobile/session/CREATE_SESSION_SUCCESS';
export const CREATE_SESSION_ERROR = 'go4ellisMobile/session/CREATE_SESSION_ERROR';
export const DESTROY_SESSION = 'go4ellisMobile/session/DESTROY_SESSION';
export const DESTROY_SESSION_SUCCESS = 'go4ellisMobile/session/DESTROY_SESSION_SUCCESS';
export const DESTROY_SESSION_ERROR = 'go4ellisMobile/session/DESTROY_SESSION_ERROR';
export const RELOAD_SESSION = 'go4ellisMobile/session/RELOAD_SESSION';
export const RELOAD_SESSION_SUCCESS = 'go4ellisMobile/session/RELOAD_SESSION_SUCCESS';
export const RELOAD_SESSION_ERROR = 'go4ellisMobile/session/RELOAD_SESSION_ERROR';
export const RELOAD_SESSION_FAILURE = 'go4ellisMobile/session/RELOAD_SESSION_FAILURE';
export const RELOAD_USER_SUCCESS = 'go4ellisMobile/session/RELOAD_USER_SUCCESS';
export const RELOAD_USER_ERROR = 'go4ellisMobile/session/RELOAD_USER_ERROR';
export const UPDATE_SESSION = 'go4ellisMobile/session/UPDATE_SESSION';
export const INVALID_SESSION = 'go4ellisMobile/session/INVALID_SESSION';

// Reducer Functions
// ========================================================
function updateSessionReducer(state: SessionStateType, action: Action): SessionStateType {
  identifyLogRocketUser(action.payload.session.currentUser);
  identifySentryUser(action.payload.session.currentUser);

  return {
    ...state,
    ...action.payload.session,
    error: null,
    sessionExpired: false,
  };
}

function destroySessionReducer(state: SessionStateType, payload) {
  if (payload?.inactive) return { ...defaultState(), sessionExpired: true, error: payload.message };

  return defaultState();
}

function errorReducer(state: SessionStateType, action) {
  return {
    ...state,
    error: action.payload,
  };
}

function reloadSessionReducer(state: SessionStateType, action) {
  return {
    ...state,
    ...action.payload,
    error: null,
    sessionExpired: false,
  };
}

function reloadUserReducer(state: SessionStateType, action) {
  return {
    ...state,
    currentUser: action.payload,
  };
}

function clearErrorsReducer(state: SessionStateType, action) {
  return {
    ...state,
    error: null,
    sessionExpired: false,
  };
}

// Action Creators
// ========================================================
export function createSession(credentials: any) {
  return {
    type: CREATE_SESSION,
    payload: credentials,
  };
}

export function destroySession(payload) {
  return {
    type: DESTROY_SESSION,
    payload,
  };
}

export function reloadSession() {
  return {
    type: RELOAD_SESSION,
  };
}

function identifyLogRocketUser({ id, name, email }) {
  LogRocket.identify(id, { name, email });
}

function identifySentryUser({ id, name, email }) {
  LogRocket.identify(id, { name, email });
  Sentry.configureScope((scope) => {
    scope.setUser({
      id,
      email,
    });
  });
}

// Reducer
// ========================================================
const defaultState = () => ({
  currentUser: {},
  accessToken: null,
  tokenType: null,
  expiry: null,
  client: null,
  uid: null,
  authenticated: false,
  error: null,
  sessionExpired: false,
});

export default function reducer(
  state: SessionStateType = defaultState(),
  action: Action
): SessionStateType {
  if (process.env.REACT_APP_LOG_ACTIONS) {
    console.info(action);
  }
  switch (action.type) {
    case CREATE_SESSION_SUCCESS:
    case UPDATE_SESSION:
      return updateSessionReducer(state, action);
    case DESTROY_SESSION_SUCCESS:
      return destroySessionReducer(state, action.payload);
    case RELOAD_SESSION_SUCCESS:
      return reloadSessionReducer(state, action);
    case CREATE_SESSION_ERROR:
    case RELOAD_SESSION_ERROR:
    case INVALID_SESSION:
      return errorReducer(state, action);
    case RELOAD_USER_SUCCESS:
      return reloadUserReducer(state, action);
    case CREATE_SESSION:
      return clearErrorsReducer(state, action);
    default:
      return state;
  }
}
