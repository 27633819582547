import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import classNames from 'classnames';

class ConfirmationModal extends Component {
  state = { open: false };

  onConfirm = () => {
    this.props.onSave();
    if (this.props.hasError) return;

    this.toggle();
  };

  onCancel = () => {
    if (this.props.onCancel) this.props.onCancel();
    this.toggle();
  };

  toggle = () => {
    if (this.props.toggle) {
      this.props.toggle();
      return;
    }

    this.setState({ open: !this.state.open });
  };

  displayButtons = () => {
    const { confirmText, canCancel, cancelText } = this.props;

    return (
      <React.Fragment>
        {(canCancel || !!cancelText) && (
          <button className="btn confirm-btn" onClick={this.onCancel}>
            {cancelText || 'Cancel'}
          </button>
        )}
        <button className="btn btn-success" onClick={this.onConfirm}>
          {confirmText || 'Confirm'}
        </button>
      </React.Fragment>
    );
  };

  render() {
    const isOpen = typeof this.props.isOpen === 'boolean' ? this.props.isOpen : this.state.open;

    return (
      <div>
        {this.props.children && (
          <button className={classNames('modal-button plain-button')} onClick={this.toggle}>
            {this.props.children}
          </button>
        )}
        <div className={classNames('v2-confirmation-modal')} style={{ position: 'absolute' }}>
          <Modal isOpen={isOpen} toggle={this.toggle} className="v2-confirmation-modal-content">
            <ModalHeader className="header" toggle={this.toggle}>
              {this.props.title || 'Are you sure?'}
            </ModalHeader>
            <ModalBody>
              {this.props.htmlBody ? this.props.body : <p>{this.props.body}</p>}
            </ModalBody>
            <ModalFooter className="actions">{this.displayButtons()}</ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default ConfirmationModal;
