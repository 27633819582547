import { Validator } from 'redux/ducks/helpers';

// Actions
// ========================================================
export const FIELD_UPDATE = 'go4ellis/Registration/FIELD_UPDATE';
export const FIELD_VALIDATE = 'go4ellis/Registration/FIELD_VALIDATE';
export const RESET_REGISTRATION = 'go4ellis/Registration/RESET_REGISTRATION';
export const SET_ROLE = 'go4ellis/Registration/SET_ROLE';
export const SUBMIT_PROFILE = 'go4ellis/Registration/SUBMIT_PROFILE';
export const SUBMIT_PROFILE_ERROR = 'go4ellis/Registration/SUBMIT_PROFILE_ERROR';
export const SUBMIT_PROFILE_SUCCESS = 'go4ellis/Registration/SUBMIT_PROFILE_SUCCESS';

// Post Event Registration
export const POST_EVENT_ACCOUNT_FIELD_VALIDATE =
  'go4ellis/Registration/POST_EVENT_ACCOUNT_FIELD_VALIDATE';
export const POST_EVENT_CONTACT_FIELD_VALIDATE =
  'go4ellis/Registration/POST_EVENT_CONTACT_FIELD_VALIDATE';
export const POST_EVENT_PROFILE_FIELD_VALIDATE =
  'go4ellis/Registration/POST_EVENT_PROFILE_FIELD_VALIDATE';
export const POST_EVENT_SHIFT_DETAILS_FIELD_VALIDATE =
  'go4ellis/Registration/POST_EVENT_SHIFT_DETAILS_FIELD_VALIDATE';
export const POST_EVENT_PASSWORD_FIELD_VALIDATE =
  'go4ellis/Registration/POST_EVENT_PASSWORD_FIELD_VALIDATE';
export const CONTRACT_INFO_FIELD_VALIDATE = 'go4ellis/Registration/CONTRACT_INFO_FIELD_VALIDATE';

// Creates an Account and starts event posting flow
export const REGISTRATION_POST_EVENT = 'go4ellis/Registration/REGISTRATION_POST_EVENT';
export const REGISTRATION_POST_EVENT_ERROR = 'go4ellis/Registration/REGISTRATION_POST_EVENT_ERROR';
export const REGISTRATION_POST_EVENT_SUCCESS =
  'go4ellis/Registration/REGISTRATION_POST_EVENT_SUCCESS';

// Sends an email to us
export const CONTRACT_SUBMIT = 'go4ellis/Registration/CONTRACT_SUBMIT';
export const CONTRACT_SUBMIT_ERROR = 'go4ellis/Registration/CONTRACT_SUBMIT_ERROR';
export const CONTRACT_SUBMIT_SUCCESS = 'go4ellis/Registration/CONTRACT_SUBMIT_SUCCESS';

// After creating an account, prefill the create event data
export const REGISTRATION_POST_EVENT_PREFILL =
  'go4ellis/Registration/REGISTRATION_POST_EVENT_PREFILL';

export const CHECK_ACCOUNT = 'go4ellis/Registration/CHECK_ACCOUNT';
export const CHECK_ACCOUNT_ERROR = 'go4ellis/Registration/CHECK_ACCOUNT_ERROR';
export const CHECK_ACCOUNT_SUCCESS = 'go4ellis/Registration/CHECK_ACCOUNT_SUCCESS';

export const PARENT_FIELD_VALIDATE = 'go4ellis/Registration/PARENT_FIELD_VALIDATE';
export const PATIENT_FIELD_VALIDATE = 'go4ellis/Registration/PATIENT_FIELD_VALIDATE';

export const VALIDATE_INVITATION_TOKEN = 'go4ellis/Registration/VALIDATE_INVITATION_TOKEN';
export const VALIDATE_INVITATION_TOKEN_SUCCESS =
  'go4ellis/Registration/VALIDATE_INVITATION_TOKEN_SUCCESS';
export const VALIDATE_INVITATION_TOKEN_ERROR =
  'go4ellis/Registration/VALIDATE_INVITATION_TOKEN_ERROR';

export const ACCEPT_INVITATION = 'go4ellis/Registration/ACCEPT_INVITATION';
export const ACCEPT_INVITATION_SUCCESS = 'go4ellis/Registration/ACCEPT_INVITATION_SUCCESS';
export const ACCEPT_INVITATION_ERROR = 'go4ellis/Registration/ACCEPT_INVITATION_ERROR';

// Reducer Functions
// ========================================================
function errorReducer(state, payload) {
  return {
    ...state,
    error: payload,
  };
}

function fieldUpdateReducer(state, payload) {
  let user = { ...state.user };
  user[payload.field] = payload.value;

  return {
    ...state,
    user: user,
  };
}

function fieldValidateReducer(state, payload) {
  const validation = { ...state.validation };
  validation['errors'] = validation.validator.validate(state.user, payload);
  validation['allValidated'] = validation.validator.allValidated();
  validation['validAccount'] =
    Object.keys(validation['errors']).length === 0 && validation.allValidated;

  return {
    ...state,
    validation: validation,
  };
}

export function postEventFieldValidateReducer(validation, state, payload) {
  validation['errors'] = validation.validator.validate(state.user, payload);
  validation['allValidated'] = validation.validator.allValidated();
  validation['valid'] = Object.keys(validation['errors']).length === 0 && validation.allValidated;
  return validation;
}

function postEventAccountFieldValidateReducer(state, payload) {
  const validation = { ...state.postEventAccountValidation };
  return {
    ...state,
    postEventAccountValidation: postEventFieldValidateReducer(validation, state, payload),
  };
}

function postEventContactFieldValidateReducer(state, payload) {
  const validation = { ...state.postEventContactValidation };
  return {
    ...state,
    postEventContactValidation: postEventFieldValidateReducer(validation, state, payload),
  };
}

function postEventProfileFieldValidateReducer(state, payload) {
  const validation = { ...state.postEventProfileValidation };
  return {
    ...state,
    postEventProfileValidation: postEventFieldValidateReducer(validation, state, payload),
  };
}

function postEventShiftDetailsFieldValidateReducer(state, payload) {
  const key =
    state.user.postEventMasterType === 'sports'
      ? 'postSportEventShiftDetailsValidation'
      : 'postCovidEventShiftDetailsValidation';
  const validation = { ...state[key] };
  return {
    ...state,
    [key]: postEventFieldValidateReducer(validation, state, payload),
  };
}

function postEventPasswordFieldValidateReducer(state, payload) {
  const validation = { ...state.postEventPasswordValidation };
  return {
    ...state,
    postEventPasswordValidation: postEventFieldValidateReducer(validation, state, payload),
  };
}

function contractInfoFieldValidateReducer(state, payload) {
  const validation = { ...state.contractInfoValidation };
  return {
    ...state,
    contractInfoValidation: postEventFieldValidateReducer(validation, state, payload),
  };
}

function parentFieldValidateReducer(state, payload) {
  const validation = { ...state.parentValidation };
  validation['errors'] = validation.validator.validate(state.user, payload);
  validation['allValidated'] = validation.validator.allValidated();
  validation['validAccount'] =
    state.validInvitationToken &&
    Object.keys(validation['errors']).length === 0 &&
    validation.allValidated;

  return {
    ...state,
    parentValidation: validation,
  };
}

function patientFieldValidateReducer(state, payload) {
  const validation = { ...state.patientValidation };
  validation['errors'] = validation.validator.validate(state.user, payload);
  validation['allValidated'] = validation.validator.allValidated();
  validation['validAccount'] =
    state.validInvitationToken &&
    Object.keys(validation['errors']).length === 0 &&
    validation.allValidated;

  return {
    ...state,
    patientValidation: validation,
  };
}

function resetRegistrationReducer(state) {
  return new DefaultState();
}

function setRoleReducer(state, payload) {
  return {
    ...state,
    role: payload,
    user: {
      ...state.user,
      type: payload,
    },
  };
}

function submitProfileReducer(state, payload) {
  return {
    ...state,
    user: { ...state.user, ...payload },
  };
}

function validateInvitationTokenReducer(state, user) {
  return {
    ...state,
    validInvitationToken: true,
    ...state.user,
    ...user,
  };
}

// Action Creators
// ========================================================
export function registrationFieldUpdate(field, value) {
  return {
    type: FIELD_UPDATE,
    payload: { field: field, value: value },
  };
}

export function contractSubmit(history) {
  return {
    type: CONTRACT_SUBMIT,
    payload: { history },
  };
}

export function registrationFieldValidate(field) {
  return {
    type: FIELD_VALIDATE,
    payload: field,
  };
}

export function parentRegistrationFieldValidate(field) {
  return {
    type: PARENT_FIELD_VALIDATE,
    payload: field,
  };
}

export function postEventAccountFieldValidate(field) {
  return {
    type: POST_EVENT_ACCOUNT_FIELD_VALIDATE,
    payload: field,
  };
}

export function postEventContactFieldValidate(field) {
  return {
    type: POST_EVENT_CONTACT_FIELD_VALIDATE,
    payload: field,
  };
}

export function postEventProfileFieldValidate(field) {
  return {
    type: POST_EVENT_PROFILE_FIELD_VALIDATE,
    payload: field,
  };
}

export function postEventShiftDetailsFieldValidate(field) {
  return {
    type: POST_EVENT_SHIFT_DETAILS_FIELD_VALIDATE,
    payload: field,
  };
}

export function postEventPasswordFieldValidate(field) {
  return {
    type: POST_EVENT_PASSWORD_FIELD_VALIDATE,
    payload: field,
  };
}

export function contractInfoFieldValidate(field) {
  return {
    type: CONTRACT_INFO_FIELD_VALIDATE,
    payload: field,
  };
}

export function checkAccountExists(payload) {
  return {
    type: CHECK_ACCOUNT,
    payload,
  };
}

export function patientRegistrationFieldValidate(field) {
  return {
    type: PATIENT_FIELD_VALIDATE,
    payload: field,
  };
}

export function resetRegistration() {
  return { type: RESET_REGISTRATION };
}

export function setRole(role) {
  return { type: SET_ROLE, payload: role };
}

export function submitProfile() {
  return { type: SUBMIT_PROFILE };
}

export function validateInvitationToken(token, location) {
  return { type: VALIDATE_INVITATION_TOKEN, payload: { token: token, location: location } };
}

export function acceptInvitation(token, history, location) {
  return {
    type: ACCEPT_INVITATION,
    payload: { token: token, history: history, location: location },
  };
}

export function registrationPostEvent({ history, location, step }) {
  return { type: REGISTRATION_POST_EVENT, payload: { history, location, step } };
}

export const HEAR_ABOUT_US_ADDITIONAL_OPTIONS = [
  'Conference',
  'Recommended by an Athletic Trainer',
  'Recommended by friend/colleague',
  'Other',
];
export const HEAR_ABOUT_US_OPTIONS = [
  'Mailer',
  'Email',
  'Google/Search Engines',
  'Twitter',
  'Instagram',
  'Facebook',
  'Blog or Publication',
  ...HEAR_ABOUT_US_ADDITIONAL_OPTIONS,
];

export const EXPECTED_HOURS_OPTIONS = ['Under 50', '50-100', '100-150', '150+'];


const DEFAULT_USER = {
  postEventMasterType: 'contract',
  email: `eotest+${Date.now()}@go4.io`,
  firstName: 'Event',
  lastName: 'Operator',
  phone: '+16692009568',
  password: 'password',
  passwordConfirmation: 'password',
  companyName: 'duty labs',
  city: 'san jose',
  state: 'ca',
  addressZip: '95122',
  eventName: '[test event]',
  sportId: '1',
  genderId: '1',
  eventTypeId: '1',
  eventParticipantNumbersId: '1',
  settingId: '1',
  terms: 'true',
  type: 'EventOperator',
};

// Reducer
// ========================================================
export class DefaultState {
  role = null;
  user =
    process.env.NODE_ENV === ''
      ? DEFAULT_USER
      : {
          postEventMasterType: '',
          email: '',
          firstName: '',
          lastName: '',
          phone: '',
          password: '',
          passwordConfirmation: '',
          companyName: '',
          city: '',
          state: '',
          addressZip: '',
          eventName: '',
          sportId: '',
          genderId: '',
          eventTypeId: '',
          eventParticipantNumbersId: '',
          settingId: '',
          hearAboutUsSource: '',
          expectedYearlyStaffedHours: '',
          hearAboutUsDetails: '',
          type: 'EventOperator',
          defaultEventSettingId: '',
        };
  validation = {
    valid: false,
    allValidated: false,
    errors: {},
    validator: new Validator({
      email: { required: true, email: true },
      password: { required: true, minLength: 8 },
      passwordConfirmation: { matches: 'password', required: true },
      terms: { hasValue: true },
    }),
  };
  postEventAccountValidation = {
    valid: false,
    allValidated: false,
    errors: {},
    validator: new Validator({
      firstName: { required: true },
      lastName: { required: true },
      email: { required: true, email: true },
      phone: { required: true, minLength: 10 },
      password: { required: true, minLength: 8 },
      passwordConfirmation: { matches: 'password', required: true },
      terms: { hasValue: true },
      hearAboutUsSource: { required: true, hasDetailsWhenOther: 'hearAboutUsDetails' },
    }),
  };
  postEventContactValidation = {
    valid: false,
    allValidated: false,
    errors: {},
    validator: new Validator({
      email: { required: true, email: true },
      firstName: { required: true },
      lastName: { required: true },
      phone: { required: true, minLength: 10 },
    }),
  };
  postEventProfileValidation = {
    valid: false,
    allValidated: false,
    errors: {},
    validator: new Validator({
      companyName: { required: true },
      city: { required: true },
      state: { required: true },
      addressZip: { required: true, zipcode: true },
      defaultEventSettingId: { required: true },
    }),
  };
  contractInfoValidation = {
    valid: false,
    allValidated: false,
    errors: {},
    validator: new Validator({
      startDate: { required: true },
      endDate: { required: true, dateGreaterThanOrEqual: 'startDate' },
    }),
  };
  postSportEventShiftDetailsValidation = {
    valid: false,
    allValidated: false,
    errors: {},
    validator: new Validator({
      eventName: { required: true },
      sportId: { required: true },
      genderId: { required: true },
      ageBracketIds: { required: true },
      eventTypeId: { required: true },
      eventParticipantNumbersId: { required: true },
    }),
  };
  postCovidEventShiftDetailsValidation = {
    valid: false,
    allValidated: false,
    errors: {},
    validator: new Validator({
      eventName: { required: true },
      settingTypeId: { required: true },
      eventParticipantNumbersId: { required: true },
    }),
  };
  postEventPasswordValidation = {
    valid: false,
    allValidated: false,
    errors: {},
    validator: new Validator({
      password: { required: true, minLength: 8 },
      passwordConfirmation: { matches: 'password', required: true },
      terms: { hasValue: true },
    }),
  };
  parentValidation = {
    validAccount: false,
    allValidated: false,
    errors: {},
    validator: new Validator({
      password: { required: true, minLength: 8 },
      passwordConfirmation: { matches: 'password', required: true },
      firstName: { required: true },
      lastName: { required: true },
      addressZip: { required: true },
      terms: { hasValue: true },
    }),
  };
  patientValidation = {
    validAccount: false,
    allValidated: false,
    errors: {},
    validator: new Validator({
      password: { required: true, minLength: 8 },
      passwordConfirmation: { matches: 'password', required: true },
      firstName: { required: true },
      lastName: { required: true },
      addressZip: { required: true },
      terms: { hasValue: true },
    }),
  };
}

export default function reducer(state = new DefaultState(), action = {}) {
  switch (action.type) {
    case FIELD_UPDATE:
      return fieldUpdateReducer(state, action.payload);
    case FIELD_VALIDATE:
      return fieldValidateReducer(state, action.payload);
    case POST_EVENT_ACCOUNT_FIELD_VALIDATE:
      return postEventAccountFieldValidateReducer(state, action.payload);
    case POST_EVENT_CONTACT_FIELD_VALIDATE:
      return postEventContactFieldValidateReducer(state, action.payload);
    case POST_EVENT_PROFILE_FIELD_VALIDATE:
      return postEventProfileFieldValidateReducer(state, action.payload);
    case POST_EVENT_SHIFT_DETAILS_FIELD_VALIDATE:
      return postEventShiftDetailsFieldValidateReducer(state, action.payload);
    case POST_EVENT_PASSWORD_FIELD_VALIDATE:
      return postEventPasswordFieldValidateReducer(state, action.payload);
    case CONTRACT_INFO_FIELD_VALIDATE:
      return contractInfoFieldValidateReducer(state, action.payload);
    case PARENT_FIELD_VALIDATE:
      return parentFieldValidateReducer(state, action.payload);
    case PATIENT_FIELD_VALIDATE:
      return patientFieldValidateReducer(state, action.payload);
    case RESET_REGISTRATION:
      return resetRegistrationReducer(state);
    case SET_ROLE:
      return setRoleReducer(state, action.payload);
    case SUBMIT_PROFILE_SUCCESS:
      return submitProfileReducer(state, action.payload);
    case SUBMIT_PROFILE_ERROR:
      return errorReducer(state, action.payload);
    case VALIDATE_INVITATION_TOKEN_SUCCESS:
      return validateInvitationTokenReducer(state, {
        user: action.payload.parent ? action.payload.parent : action.payload.patient,
      });
    default:
      return state;
  }
}
