import { SERVICE_URL } from 'services/ApiService';
import { isContractEo } from './contracts';
import { get } from 'lodash';
import moment from 'moment';
import { NPS_PRODUCTION_IDS } from './nps_feature_flags_ids';

const getEnvironment = () => {
  switch (SERVICE_URL) {
    case 'http://localhost:3000':
      return 'development';
    case 'https://staging.go4.io/api':
      return 'staging';
    default:
      return 'production';
  }
};

/*
  Example for email criteria.

  const STAGING_EMAILS = [
    'eotest@go4.io',
    'mihaelasimionas+eo2@gmail.com',
    'mihaelasimionas+eo@gmail.com',
  ];

  const PRODUCTION_DASHBOARD_EMAILS = [
    // production
    'mihaelasimionas+eo6prod@gmail.com',

    // staging
    ...STAGING_EMAILS,
  ];
*/

const STAGING_EMAILS = [
  'eotest@go4.io',
  'mihaelasimionas+eo2@gmail.com',
  'mihaelasimionas+eo@gmail.com',
];

const PRODUCTION_EMAILS = [
  // production
  'mihaelasimionas+eo6prod@gmail.com',

  // staging
  ...STAGING_EMAILS,
];

const DISABLED_EMAILS = [
  'mihaelasimionas+eo4mar@gmail.com',
  'mihaelasimionas+eo24nov@gmail.com',
  'sales@go4.io',
];

/*
  Example:
  export const features = {
    DASHBOARD: 'dashboard'
  };
*/
export const features = {
  NET_PROMOTER_SCORE: 'netPromoterScore',
};

/*
  Example: enable the feature flag for users that match the specific environment
    email or have an id greater that a specific id.
  This can be customize for any criteria.

  const FEATURE_FLAGS = [
    {
      featureFlag: features.DASHBOARD,
      isEnabled: function (user, env) {
          if (isContractEo(user.email)) return false;

          const emails = get(this, `${env}Emails`, []);
          const cutOffId = get(this, `${env}CutOffId`, 0);
          return emails.includes(user.email) || user.id > cutOffId;
      },
      developmentEmails: STAGING_EMAILS,
      productionEmails: PRODUCTION_DASHBOARD_EMAILS,
      developmentCutOffId: 1970,
      productionCutOffId: 2470,
    }
  ];
*/

/*
  Usage example:
  import isEnabledFeature, { feature } from 'helpers/feature_flags';
  const isUnderDashboardFlag = isEnabledFeature(features.DASHBOARD, this.props.user);
*/

const FEATURE_FLAGS = [
  {
    featureFlag: features.NET_PROMOTER_SCORE,
    isEnabled: function (user, env) {
      if (isContractEo(user.email)) return false;
      if (['development', 'staging'].includes(env)) return false;
      const releaseDate = '2025-03-04';

      const ids = get(this, `${env}Ids`, []);
      return (
        ids.has(user.id) &&
        moment()
          .utc()
          .isBetween(moment(releaseDate).utc(), moment(releaseDate).utc().add('days', 14))
      );
    },
    productionIds: NPS_PRODUCTION_IDS,
  },
];

export const isEnabledFeature = (feature, user) => {
  const env = getEnvironment();
  const flag = FEATURE_FLAGS.find((flag) => flag.featureFlag === feature);

  if (!flag) return false;

  return flag.isEnabled(user, env);
};

export default isEnabledFeature;
