// @flow
import { ShiftSerializer } from 'services/serializers';
import { isEmpty, uniq, minBy, maxBy } from 'lodash';
import type { EventFormType } from 'redux/ducks/CreateEvent';
import type { SerializedShift } from './ShiftSerializer';
import moment from 'moment-timezone';
import { get } from 'lodash';

export default class EventSerializer {
  static deserializeStatistics(event: any) {
    if (!event.statistics) return { shiftsFilled: 0, eventCapacity: 0, shiftRequests: 0 };

    return {
      shiftsFilled: event.statistics.shifts_filled,
      eventCapacity: event.statistics.event_capacity,
      shiftRequests: event.statistics.shift_requests,
    };
  }

  static deserialize(event: any, athleticTrainers: any[]) {
    const locationsNo = uniq(
      event.shifts
        .filter((shift) => get(shift, 'event_location.id'))
        .map((shift) => get(shift, 'event_location.id'))
    ).length;
    const tz = moment.tz.guess();

    const futureShifts = event.shifts.filter((shift) => moment(shift.start_time).isAfter(moment()));

    let nextStartTime;
    if (futureShifts.length > 0) {
      nextStartTime = minBy(futureShifts, (shift) => moment(shift.start_time))?.start_time;
    } else if (event.shifts.length > 0) {
      nextStartTime = maxBy(event.shifts, (shift) => moment(shift.start_time))?.start_time;
    }

    const result = {
      id: event.id,
      atsHired: event.ats_hired,
      createdAt: event.created_at,
      lastPaymentIssued: event.last_payment_issued ? moment.tz(event.last_payment_issued, tz) : '',
      description: event.event_description,
      isDraft: event.is_draft,
      genderId: event.gender_id,
      participantNumbersId: event.event_participant_numbers_id,
      payRate: event.pay_rate,
      maxPayRate: event.max_pay_rate,
      minTotalPay: event.min_total_pay,
      maxTotalPay: event.max_total_pay,
      rateTypeId: event.rate_type_id?.toString(),
      sportId: event.sport_id,
      sport: event.sport,
      title: event.title,
      ageBracketIds: event.age_bracket_ids?.split(','),
      eventTypeId: event.event_type_id,
      settingTypeId: event.setting_type_id,
      url: event.url,
      totalPaymentToDate: event.total_payment_to_date,
      instructions: event.instructions,
      shifts: {},
      eventOperatorId: event.event_operator_id,
      existingDocuments: event.documents,
      eventProfessionId: event.profession_id,
      eventSettingId: event.event_setting_id,
      settingDetailId: event.setting_detail_id,
      jobDescriptionIds: event.job_description_ids?.split(','),
      settingDetailOther: event.setting_detail_other,
      jobDescriptionOther: event.job_description_other,
      sportOther: event.sport_other,
      eventLogo: event.event_logo,
      eventCode: event.event_code,
      contractInfo: event.contract_info,
      version: event.version,
      requiredCredentials: event.required_credentials,
      nextStartTime: moment.tz(nextStartTime, tz),
      firstStartTime: moment.tz(event.first_start_time, tz),
      formattedFirstStartTime: event.first_start_time
        ? moment.tz(event.first_start_time, tz).format('MM/DD/YY')
        : '',
      statistics: EventSerializer.deserializeStatistics(event),
      locationsNo: locationsNo,
      location_documents: event.location_documents,
    };

    if (event.shifts) {
      result['shifts'] = {
        byId: {},
        ids: [],
      };

      for (const data of event.shifts) {
        const shift = ShiftSerializer.deserialize(data, athleticTrainers, event.pay_rate);
        result.shifts.byId[shift.id] = shift;
        result.shifts.ids.push(shift.id);
      }
    }

    return result;
  }

  static serialize(eventForm: EventFormType, eventOperatorId: number): FormData {
    let formData = new FormData();
    const setAttr = (attr, val) => {
      if (val !== undefined) {
        formData.set(`event[${attr}]`, (val: any));
      }
    };
    const setAttrStrict = (attr, val: ?string | ?File) => {
      // eslint-disable-next-line
      if (val != undefined) {
        formData.set(`event[${attr}]`, val);
      }
    };
    setAttr('id', eventForm.details.id);
    setAttr('title', eventForm.details.title);
    setAttr('instructions', eventForm.details.instructions);
    setAttrStrict('sport_id', eventForm.details.sportId);
    setAttrStrict('gender_id', eventForm.details.genderId);
    setAttrStrict('event_type_id', eventForm.details.eventTypeId);
    setAttrStrict('age_bracket_ids', eventForm.details.ageBracketIds?.join(','));
    setAttrStrict('setting_type_id', eventForm.details.settingTypeId);
    setAttrStrict('event_participant_numbers_id', eventForm.details.eventParticipantNumbersId);
    setAttrStrict('setting_detail_other', eventForm.details.settingDetailOther);
    setAttrStrict('job_description_other', eventForm.details.jobDescriptionOther);
    setAttrStrict('sport_other', eventForm.details.sportOther);
    setAttr('rate_type_id', eventForm.details.rateTypeId);
    setAttr('pay_rate', eventForm.details.payRate);
    setAttr('max_pay_rate', eventForm.details.maxPayRate);
    setAttr('min_total_pay', eventForm.details.minTotalPay);
    setAttr('max_total_pay', eventForm.details.maxTotalPay);
    setAttr('event_operator_id', eventForm.details.eventOperatorId || eventOperatorId);
    setAttr('is_draft', !!eventForm.details.isDraft);
    setAttr('event_setting_id', eventForm.details.eventSettingId);
    setAttr('setting_detail_id', eventForm.details.settingDetailId);
    setAttr('job_description_ids', eventForm.details.jobDescriptionIds?.join(','));
    setAttrStrict('event_logo', eventForm.details.eventLogo);
    setAttrStrict('payment_token', eventForm.details.paymentToken);
    setAttrStrict('event_code', eventForm.details.eventCode);
    setAttr('version', 'v1');

    if (eventForm.details.contractInfo) {
      Object.keys(eventForm.details.contractInfo).forEach((key) => {
        formData.set(`event[contract_info][${key}]`, eventForm.details.contractInfo[key]);
      });
    }

    if (eventForm.details.documents) {
      eventForm.details.documents.forEach((document) => {
        formData.append(`documents[]`, document);
      });
    }

    if (eventForm.shifts) {
      if (eventForm.shifts.reuseLocation && !isEmpty(eventForm.shifts.shifts)) {
        const { eventLocation } = eventForm.shifts.shifts[0];
        eventForm.shifts.shifts = eventForm.shifts.shifts.map((shift) => {
          shift.eventLocation = eventLocation;
          return shift;
        });
      }

      eventForm.shifts.shifts
        .filter((shift) => shift.start && shift.end && shift.eventLocation)
        .map(ShiftSerializer.serialize)
        .forEach((shift: SerializedShift, idx) => {
          Object.keys(shift)
            .filter(
              (key) =>
                ![
                  'event_location_attributes',
                  'shift_contacts_attributes',
                  'contract_info',
                ].includes(key)
            )
            .forEach((key) => {
              setAttrStrict(`shifts_attributes][${idx}][${key}`, shift[key]);
            });

          if (shift.contract_info) {
            Object.keys(shift.contract_info).forEach((key) => {
              setAttrStrict(
                `shifts_attributes][${idx}][contract_info][${key}`,
                shift.contract_info[key]
              );
            });
          }

          if (shift.shift_contacts_attributes) {
            shift.shift_contacts_attributes.forEach((key, contact_idx) => {
              Object.keys(key).forEach((contact_attr) => {
                setAttrStrict(
                  `shifts_attributes][${idx}][shift_contacts_attributes][${contact_idx}][${contact_attr}`,
                  shift.shift_contacts_attributes[contact_idx][contact_attr]
                );
              });
            });
          }

          if (shift.event_location_attributes) {
            Object.keys(shift.event_location_attributes)
              .filter((key) => key !== 'address_attributes')
              .forEach((key) => {
                setAttrStrict(
                  `shifts_attributes][${idx}][event_location_attributes[${key}`,
                  shift.event_location_attributes[key]
                );
              });
            let address = shift.event_location_attributes.address_attributes;
            if (address) {
              Object.keys(address).forEach((key) => {
                setAttrStrict(
                  `shifts_attributes][${idx}][event_location_attributes[address_attributes[${key}`,
                  address[key]
                );
              });
            }
          }
        });
    }

    return formData;
  }
}
