import React, { Component } from 'react';
import ConfirmationModal from 'components/V2/ConfirmationModal';
import moment from 'moment';
import EditShiftModal from '../EditShiftModal';
import { getDayName, shiftDuration, shiftRate } from 'helpers/post';
import { DeleteButton } from 'components/V2/Buttons';
import { rateTypeNameFinder } from 'helpers/rateTypeName';
import { capitalize } from 'lodash';
import { connect } from 'react-redux';
import DeleteModal from 'components/V2/ConfirmationModal/DeleteModal';
import { MILITARY_FORMAT } from 'helpers/datetime';

class ShiftDetailsMobile extends Component {
  state = { isOpenDeleteModal: false };
  toggleDeleteModal = () => this.setState({ isOpenDeleteModal: !this.state.isOpenDeleteModal });

  setPrecision = (amount) => Number(amount).toFixed(2);

  canEdit = (shift) => {
    return moment(shift.endTime, MILITARY_FORMAT).isAfter(moment());
  };

  canDelete = () => this.props.shifts.length > 1;

  render() {
    const { shift, shifts, idx, rateTypes, editMode } = this.props;
    const rateTypeName = rateTypeNameFinder(rateTypes, shift.rateTypeId);
    const startDateMoment = moment(shift.startDate, MILITARY_FORMAT);
    const endDateMoment = moment(shift.endDate, MILITARY_FORMAT);

    return (
      <div className="shiftDetailsMobile nimbus-regular-font">
        <div className="attribute">
          <div className="location-title pl-0 pb-0">SHIFT {idx + 1} </div>
        </div>
        <div className="attribute">
          {getDayName(shift).toUpperCase()}{' '}
          {startDateMoment.isSame(endDateMoment, 'day')
            ? startDateMoment.format('MM/DD/YYYY')
            : startDateMoment.format('MM/DD/YYYY') + ' - ' + endDateMoment.format('MM/DD/YYYY')}
        </div>
        <div className="attribute">
          <div className="location-title pl-0 pb-0">TIME:</div>
          {moment(shift.start, 'h:mm A').format('h:mm A')}
          {' - '}
          {moment(shift.end, 'h:mm A').format('h:mm A')}
        </div>
        <div className="attribute">
          <div className="location-title pl-0 pb-0">ATs NEEDED:</div>
          {shift.capacity}
        </div>
        {!shift.isMultiDay && (
          <div className="attribute">
            <div className="location-title pl-0 pb-0">HOURS:</div>
            {Number(shiftDuration(shift))}
          </div>
        )}
        <div className="attribute">
          <div className="location-title pl-0 pb-0">RATE:</div>${this.setPrecision(shift.payRate)}/
          {capitalize(rateTypeName)}
        </div>
        <div className="attribute">
          <div className="location-title pl-0 pb-0">SHIFT SUM:</div>$
          {this.setPrecision(shiftRate(shift))}
        </div>
        <div className="actions">
          {this.canEdit(shift) && (
            <EditShiftModal
              shift={shift}
              shiftCount={shifts.length}
              history={this.props.history}
              editMode={editMode}
            >
              Edit
            </EditShiftModal>
          )}{' '}
          <DeleteButton show={this.canDelete()} onClick={this.toggleDeleteModal}>
            {editMode ? 'CANCEL' : 'DELETE'}
          </DeleteButton>
          {!!editMode ? (
            <DeleteModal
              isOpen={this.state.isOpenDeleteModal}
              title="Cancel Shift"
              body="Canceling this shift will remove athletic trainers who applied or confirmed to work."
              toggle={this.toggleDeleteModal}
              cancelText="Cancel Shift"
              onDelete={this.props.onDeleteShift}
              item={shift}
              user={this.props.user}
              eventName={this.props.eventName}
              showNote={true}
            />
          ) : (
            <ConfirmationModal
              isOpen={this.state.isOpenDeleteModal}
              toggle={this.toggleDeleteModal}
              title="Delete Shift"
              body="Are you sure you want to delete this shift?"
              onSave={() => {
                this.props.onDeleteShift(shift.idx);
                this.toggleDeleteModal();
              }}
              confirmText="Delete"
              cancelText="Go Back"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rateTypes: state.enums.rateTypes,
  user: state.session.currentUser,
  eventName: state.post.profile.title,
});

export default connect(mapStateToProps)(ShiftDetailsMobile);
