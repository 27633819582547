export const DEFAULT_DETAILS = {
  jobExpectations: '',
  equipments: [''],
  requiredCredentials: null,
};

export default class DetailsSerializer {
  static deserialize(details) {
    if (!details) return DEFAULT_DETAILS;

    const credentials =
      details.required_credentials?.length === 1 && details.required_credentials[0] === ''
        ? []
        : details.required_credentials;

    return {
      jobExpectations: details.job_expectations,
      equipments: details.equipments || [''],
      scheduleDetails: details.schedule_details,
      requiredCredentials: credentials,
      dressCode: details.dress_code,
      meals: details.meals,
      parking: details.parking,
      checkInLocation: details.check_in_location,
      reportTo: details.report_to,
      setupLocation: details.setup_location,
      facilityInformation: details.facility_information,
      restrooms: details.restrooms,
      ambulanceAccessPoint: details.ambulance_access_point,
      weatherPlans: details.weather_plans,
      otherDetails: details.other_details,
      locationRateTypeId: details.location_rate_type_id,
      locationPayRate: details.location_pay_rate,
    };
  }

  static serialize(details) {
    if (!details) return {};

    return {
      job_expectations: details.jobExpectations,
      equipments: details.equipments || [''],
      required_credentials: details.requiredCredentials,
      schedule_details: details.scheduleDetails,
      dress_code: details.dressCode,
      meals: details.meals,
      parking: details.parking,
      check_in_location: details.checkInLocation,
      report_to: details.reportTo,
      setup_location: details.setupLocation,
      facility_information: details.facilityInformation,
      restrooms: details.restrooms,
      ambulance_access_point: details.ambulanceAccessPoint,
      weather_plans: details.weatherPlans,
      other_details: details.otherDetails,
    };
  }
}
